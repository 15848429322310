import { FirebaseAuthProvider } from 'react-admin-firebase';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { app, firebaseConfig } from '@/vars/firebase';
import messages from '@/vars/i18n';
import dataProvider from './DataProvider';

export { dataProvider };

export const authProvider = FirebaseAuthProvider(firebaseConfig, { app });

export const i18nProvider = polyglotI18nProvider(() => messages, 'en', {
  allowMissing: true,
  onMissingKey: key => key,
});
