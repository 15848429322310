import React from 'react';
import { Box, Typography, Card, CardContent, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SLOT } from '@/vars';
import SlotItem from './SlotItem';
import { isNumber } from 'lodash';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  rowLabel: {
    marginBottom: theme.spacing(1),
  },
  label: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'right',
  },
  slotStatus: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    marginBottom: 2,
    marginRight: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  occupied: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    border: '1px solid',
    borderRadius: 10,
    marginRight: 7,
    marginBottom: 2,
    padding: 6,
    backgroundColor: colors.green['A100'],
    textAlign: 'center',
    fontSize: 12,
  },
  error: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    marginRight: 7,
    marginBottom: 2,
    border: '1px solid',
    borderRadius: 10,
    padding: 6,
    backgroundColor: colors.red[200],
    textAlign: 'center',
    fontSize: 12,
  },
  ofs: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    marginRight: 7,
    marginBottom: 2,
    border: '1px solid',
    borderRadius: 10,
    padding: 6,
    backgroundColor: colors.orange[200],
    textAlign: 'center',
    fontSize: 12,
  },
  empty: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    border: '1px solid',
    borderRadius: 10,
    marginBottom: 2,
    marginRight: 7,
    padding: 6,
    backgroundColor: colors.grey[100],
    textAlign: 'center',
    fontSize: 12,
  },
}));

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const SlotGrid = ({ state, machineId, machineModel, numOfRows, numOfColumns }) => {
  const classes = useStyles();
  const { data, loaded } = state;

  const isCompatMachine = machineModel === 'VE-E1208N' || machineModel === 'VE-E1208C';
  let COL_NUM = SLOT.GRID_COL_NUM;
  let ROW_NUM = SLOT.GRID_ROW_NUM;

  if (isNumber(numOfColumns) && isNumber(numOfRows)) {
    COL_NUM = numOfColumns;
    ROW_NUM = numOfRows;
  }

  if (!loaded) return null;

  const Row = ({ rowIndex }) => {
    const rowNumber = ALPHABET[rowIndex];
    return (
      <div>
        <Typography className={classes.rowLabel}>Row {rowNumber}</Typography>
        <Box className={classes.row}>
          {[...Array(COL_NUM)].map((v, index) => {
            const slotIndex = rowIndex * COL_NUM + index;
            const slotNumber = slotIndex + 1;
            const itemData = data[slotNumber] || {};
            if (index >= 10) {
              // For some reason, we want to hide column 11 and 12 and display only 1-10
              return null;
            } else {
              return (
                <SlotItem
                  key={slotIndex}
                  data={itemData}
                  rowNumber={rowNumber}
                  colNumber={`${rowNumber}${index + 1}`}
                  slotNumber={slotNumber}
                  machineId={machineId}
                  diablse={false}
                  is12slots={false}
                />
              );
            }
          })}
        </Box>
      </div>
    );
  };

  const CompatRow = ({ rowIndex }) => {
    const rowNumber = ALPHABET[rowIndex];
    return (
      <div>
        <Typography className={classes.rowLabel}>Row {rowNumber}</Typography>
        <Box className={classes.row}>
          {[...Array(COL_NUM)].map((v, index) => {
            const slotIndex = rowIndex * COL_NUM + index;
            const slotNumber = slotIndex + 1;
            const itemData = data[slotNumber] || {};
            if (index >= 12) {
              return null;
            } else {
              if (rowIndex > 2 && rowIndex < 6 && index > 7 && index < 13) {
                return (
                  <SlotItem
                    key={slotIndex}
                    data={itemData}
                    rowNumber={rowNumber}
                    colNumber={`${rowNumber}${index + 1}`}
                    slotNumber={slotNumber}
                    machineId={machineId}
                    disable={true}
                    is12slots={true}
                  />
                );
              } else {
                return (
                  <SlotItem
                    key={slotIndex}
                    data={itemData}
                    rowNumber={rowNumber}
                    colNumber={`${rowNumber}${index + 1}`}
                    slotNumber={slotNumber}
                    machineId={machineId}
                    disable={false}
                    is12slots={true}
                  />
                );
              }
            }
          })}
        </Box>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.label}>
        <Box className={classes.slotStatus}>Legends:</Box>
        <Box className={classes.occupied}>Occupied</Box>
        <Box className={classes.error}>Error</Box>
        <Box className={classes.ofs}>Out of Stock</Box>
        <Box className={classes.empty}>Empty Slot</Box>
      </div>
      <Card
        style={{
          overflowY: 'hidden',
          overflowX: 'scroll',
        }}
      >
        <CardContent>
          {[...Array(ROW_NUM)].map((v, i) =>
            isCompatMachine ? <CompatRow rowIndex={i} key={i} /> : <Row rowIndex={i} key={i} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SlotGrid;
