import React from 'react';
import { CardContent, CardHeader, Grid } from '@material-ui/core';
import { BooleanInput } from 'react-admin';
import { TextInput } from '@/components/input';

const ApiSettingForm = ({ record, mode, ...rest }) => {
  console.log(record, mode);
  return (
    <>
      <CardHeader title="API setting" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Machine API App Key"
              source="client.api.appKey"
              disabled={mode === 'edit' && !record.isOwner}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Machine API App Secret"
              source="client.api.appSecret"
              disabled={mode === 'edit' && !record.isOwner}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              multiline
              label="API Clients"
              source="client.api.clients"
              format={v => (v ? v.join('\n') : '')}
              parse={v => (v.trim() ? v.trim().split('\n') : [])}
              disabled={mode === 'edit' && !record.isOwner}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BooleanInput label="Active" source="client.api.isActive" />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default ApiSettingForm;
