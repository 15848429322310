function e(e) {
  return e && "object" == typeof e && "default" in e ? e.default : e;
}

var r = e(require("path-browserify")),
    t = e(require("lodash/get")),
    n = require("firebase/app");

require("firebase/firestore"), require("firebase/auth"), require("firebase/storage");

var o = require("react-admin");

function i(e, r) {
  s && console.log("react-admin-firebase: ", e, r);
}

function a(e, r) {
  s && console.error("react-admin-firebase: ", e, r);
}

var s = !1;

function u(e, r) {
  (e && e.debug || r.logging) && (s = !0);
}

function c(e, r) {
  try {
    var t = e();
  } catch (e) {
    return r(e);
  }

  return t && t.then ? t.then(void 0, r) : t;
}

function l(e, t) {
  if (!e) return t;
  if (!t) throw new Error("Resource name must be a string of length greater than 0 characters");
  var n = r.join("/", e, "/", t, "/");
  if ((n.split("/").length - 1) % 2) throw new Error('The rootRef path must point to a "document" not a "collection"\ne.g. /collection/document/ or /collection/document/collection/document/');
  return n.slice(1, -1);
}

"undefined" != typeof Symbol && (Symbol.iterator || (Symbol.iterator = Symbol("Symbol.iterator"))), "undefined" != typeof Symbol && (Symbol.asyncIterator || (Symbol.asyncIterator = Symbol("Symbol.asyncIterator")));

var p = function p(e, r) {
  this.fireWrapper = e, this.options = r, this.resources = {}, this.db = e.db();
};

function f(e, r, t) {
  e.sort(function (e, n) {
    var o,
        i,
        a = e[r],
        s = n[r];
    return Number.isFinite(a) && Number.isFinite(s) ? (o = a, i = s) : (o = (e[r] || "").toString().toLowerCase(), i = (n[r] || "").toString().toLowerCase()), o > i ? "asc" === t ? 1 : -1 : o < i ? "asc" === t ? -1 : 1 : 0;
  });
}

p.prototype.GetResource = function (e) {
  var r = this.resources[e];
  if (!r) throw new Error('react-admin-firebase: Cant find resource: "' + e + '"');
  return r;
}, p.prototype.TryGetResourcePromise = function (e) {
  try {
    var r = this;
    return Promise.resolve(r.initPath(e)).then(function () {
      var t = r.resources[e];
      if (!t) throw new Error('react-admin-firebase: Cant find resource: "' + e + '"');
      return t;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.RefreshResource = function (e) {
  try {
    var r = this;
    return Promise.resolve(r.initPath(e)).then(function () {
      var t = r.resources[e];
      return i("resourceManager.RefreshResource", {
        relativePath: e
      }), Promise.resolve(t.collection.get()).then(function (e) {
        t.list = e.docs.map(function (e) {
          return r.parseFireStoreDocument(e);
        });
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.GetSingleDoc = function (e, r) {
  try {
    var t = this;
    return Promise.resolve(t.initPath(e)).then(function () {
      return Promise.resolve(t.resources[e].collection.doc(r).get()).then(function (e) {
        if (!e.exists) throw new Error("react-admin-firebase: No id found matching: " + r);
        return t.parseFireStoreDocument(e);
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.initPath = function (e) {
  try {
    var r = this,
        t = l(r.options.rootRef, e);
    return i("resourceManager.initPath:::", {
      absolutePath: t
    }), Promise.resolve(r.isCollectionAccessible(t)).then(function (n) {
      var o = r.resources[e];

      if (n) {
        if (!o) {
          var i = r.db.collection(t);
          r.resources[e] = {
            collection: i,
            list: [],
            path: e,
            pathAbsolute: t
          };
        }
      } else o && r.removeResource(e);
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.parseFireStoreDocument = function (e) {
  var r = e.data();
  return Object.keys(r).forEach(function (e) {
    var t = r[e];
    t && t.toDate && t.toDate instanceof Function && (r[e] = t.toDate());
  }), Object.assign({}, {
    id: e.id
  }, r);
}, p.prototype.getUserLogin = function () {
  try {
    var e = this;
    return new Promise(function (r, t) {
      e.fireWrapper.auth().onAuthStateChanged(function (e) {
        r(e);
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.isCollectionAccessible = function (e) {
  try {
    var r = !1,
        t = this,
        n = c(function () {
      return Promise.resolve(t.db.collection(e).doc("auth_test").get()).then(function () {});
    }, function () {
      return r = !0, !1;
    });
    return n && n.then ? n.then(function (e) {
      return !r || e;
    }) : !r || n;
  } catch (e) {
    return Promise.reject(e);
  }
}, p.prototype.removeResource = function (e) {
  delete this.resources[e];
};

var h = function h(e, r) {
  this.fireWrapper = e, this.options = r, this.db = e.db(), this.rm = new p(this.fireWrapper, this.options);
};

h.prototype.apiGetList = function (e, r) {
  try {
    return i("apiGetList", {
      resourceName: e,
      params: r
    }), Promise.resolve(this.tryGetResource(e, "REFRESH")).then(function (e) {
      var n = e.list;

      if (null != r.sort) {
        var o = r.sort;
        f(n, o.field, "ASC" === o.order ? "asc" : "desc");
      }

      var i = function (e, r) {
        if (!(n = r) || "{}" === JSON.stringify(n)) return e;
        var n,
            o = Object.keys(r);
        return e.filter(function (e) {
          return o.reduce(function (n, o) {
            var i = t(r, o);
            null != i && null != i || (i = "");
            var a = i.toString().toLowerCase(),
                s = t(e, o);
            if (null == s) return !1;
            var u = s.toString().toLowerCase().includes(a);
            return n || u;
          }, !1);
        });
      }(n, r.filter),
          a = (r.pagination.page - 1) * r.pagination.perPage;

      return {
        data: i.slice(a, a + r.pagination.perPage),
        total: e.list.length
      };
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiGetOne = function (e, r) {
  try {
    var t = this;
    return i("apiGetOne", {
      resourceName: e,
      params: r
    }), c(function () {
      return Promise.resolve(t.rm.GetSingleDoc(e, r.id)).then(function (e) {
        return {
          data: e
        };
      });
    }, function () {
      throw new Error("Error getting id: " + r.id + " from collection: " + e);
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiCreate = function (e, r) {
  try {
    var t = this;
    return Promise.resolve(t.tryGetResource(e)).then(function (n) {
      var o = !1;

      function a(e) {
        if (o) return e;
        var i = t.db.collection("collections").doc().id;
        return Promise.resolve(t.parseDataAndUpload(n, i, r.data)).then(function (e) {
          var r = Object.assign({}, e, {
            createdAt: t.fireWrapper.serverTimestamp(),
            updatedAt: t.fireWrapper.serverTimestamp()
          });
          return Promise.resolve(n.collection.doc(i).set(r, {
            merge: !1
          })).then(function () {
            return {
              data: Object.assign({}, e, {
                id: i
              })
            };
          });
        });
      }

      i("apiCreate", {
        resourceName: e,
        resource: n,
        params: r
      });

      var s = r.data && r.data.id,
          u = function () {
        if (s) {
          var e = r.data.id;
          return Promise.resolve(t.parseDataAndUpload(n, e, r.data)).then(function (r) {
            if (!e) throw new Error("id must be a valid string");
            var i = Object.assign({}, r, {
              createdAt: t.fireWrapper.serverTimestamp(),
              updatedAt: t.fireWrapper.serverTimestamp()
            });
            return Promise.resolve(n.collection.doc(e).set(i, {
              merge: !0
            })).then(function () {
              return o = !0, {
                data: Object.assign({}, r, {
                  id: e
                })
              };
            });
          });
        }
      }();

      return u && u.then ? u.then(a) : a(u);
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiUpdate = function (e, r) {
  try {
    var t = this,
        n = r.id;
    return delete r.data.id, Promise.resolve(t.tryGetResource(e)).then(function (o) {
      return i("apiUpdate", {
        resourceName: e,
        resource: o,
        params: r
      }), Promise.resolve(t.parseDataAndUpload(o, n, r.data)).then(function (e) {
        return o.collection.doc(n).update(Object.assign({}, e, {
          updatedAt: t.fireWrapper.serverTimestamp()
        })).catch(function (e) {
          a("apiUpdate error", {
            error: e
          });
        }), {
          data: Object.assign({}, e, {
            id: n
          })
        };
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiUpdateMany = function (e, r) {
  try {
    var t = this;
    return delete r.data.id, Promise.resolve(t.tryGetResource(e)).then(function (n) {
      return i("apiUpdateMany", {
        resourceName: e,
        resource: n,
        params: r
      }), Promise.resolve(Promise.all(r.ids.map(function (e) {
        try {
          return Promise.resolve(t.parseDataAndUpload(n, e, r.data)).then(function (r) {
            return n.collection.doc(e).update(Object.assign({}, r, {
              updatedAt: t.fireWrapper.serverTimestamp()
            })).catch(function (e) {
              a("apiUpdateMany error", {
                error: e
              });
            }), Object.assign({}, r, {
              id: e
            });
          });
        } catch (e) {
          return Promise.reject(e);
        }
      }))).then(function (e) {
        return {
          data: e
        };
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiDelete = function (e, r) {
  try {
    return Promise.resolve(this.tryGetResource(e)).then(function (t) {
      return i("apiDelete", {
        resourceName: e,
        resource: t,
        params: r
      }), t.collection.doc(r.id).delete().catch(function (e) {
        a("apiDelete error", {
          error: e
        });
      }), {
        data: r.previousData
      };
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiDeleteMany = function (e, r) {
  try {
    var t = this;
    return Promise.resolve(t.tryGetResource(e)).then(function (n) {
      i("apiDeleteMany", {
        resourceName: e,
        resource: n,
        params: r
      });

      for (var o = [], s = t.db.batch(), u = 0, c = r.ids; u < c.length; u += 1) {
        var l = c[u];
        s.delete(n.collection.doc(l)), o.push({
          id: l
        });
      }

      return s.commit().catch(function (e) {
        a("apiDeleteMany error", {
          error: e
        });
      }), {
        data: o
      };
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiGetMany = function (e, r) {
  try {
    return Promise.resolve(this.tryGetResource(e, "REFRESH")).then(function (t) {
      return i("apiGetMany", {
        resourceName: e,
        resource: t,
        params: r
      }), Promise.resolve(Promise.all(r.ids.map(function (e) {
        return t.collection.doc(e).get();
      }))).then(function (e) {
        return {
          data: e.map(function (e) {
            return Object.assign({}, e.data(), {
              id: e.id
            });
          })
        };
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.apiGetManyReference = function (e, r) {
  try {
    return Promise.resolve(this.tryGetResource(e, "REFRESH")).then(function (t) {
      i("apiGetManyReference", {
        resourceName: e,
        resource: t,
        params: r
      });
      var n = t.list,
          o = r.target,
          a = r.id,
          s = n.filter(function (e) {
        return e[o] === a;
      });

      if (null != r.sort) {
        var u = r.sort;
        f(n, u.field, "ASC" === u.order ? "asc" : "desc");
      }

      var c = (r.pagination.page - 1) * r.pagination.perPage;
      return {
        data: s.slice(c, c + r.pagination.perPage),
        total: s.length
      };
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.tryGetResource = function (e, r) {
  try {
    var _n = function _n() {
      return t.rm.TryGetResourcePromise(e);
    };

    var t = this;

    var o = function () {
      if (r) return Promise.resolve(t.rm.RefreshResource(e)).then(function () {});
    }();

    return o && o.then ? o.then(_n) : _n();
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.getCurrentUserEmail = function () {
  try {
    return Promise.resolve(this.rm.getUserLogin()).then(function (e) {
      return e ? e.email : "annonymous user";
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.parseDataAndUpload = function (e, r, t) {
  try {
    var n = this;
    if (!t) return t;
    var o = e.collection.doc(r).path;
    return Promise.resolve(Promise.all(Object.keys(t).map(function (e) {
      try {
        var _r = function _r() {
          return Promise.resolve(n.parseDataField(i, o, e)).then(function () {});
        };

        var i = t[e],
            a = Array.isArray(i),
            s = function () {
          if (a) return Promise.resolve(Promise.all(i.map(function (r, t) {
            return i[t] && i[t].hasOwnProperty("rawFile") ? Promise.all([n.parseDataField(i[t], o, e + t)]) : Promise.all(Object.keys(r).map(function (i) {
              return n.parseDataField(r[i], o, e + i + t);
            }));
          }))).then(function () {});
        }();

        return Promise.resolve(s && s.then ? s.then(_r) : _r());
      } catch (e) {
        return Promise.reject(e);
      }
    }))).then(function () {
      return t;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.parseDataField = function (e, r, t) {
  try {
    if (!e || !e.hasOwnProperty("rawFile")) return;
    return Promise.resolve(this.uploadAndGetLink(e.rawFile, r, t)).then(function (r) {
      e.src = r, delete e.rawFile;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.uploadAndGetLink = function (e, t, n) {
  try {
    var o = r.join(t, n);
    return Promise.resolve(this.saveFile(o, e));
  } catch (e) {
    return Promise.reject(e);
  }
}, h.prototype.saveFile = function (e, r) {
  try {
    i("saveFile() saving file...", {
      storagePath: e,
      rawFile: r
    });
    var t = this.fireWrapper.storage().ref(e).put(r);
    return c(function () {
      return Promise.resolve(new Promise(function (e, r) {
        return t.then(e).catch(r);
      })).then(function (r) {
        return Promise.resolve(r.ref.getDownloadURL()).then(function (t) {
          return i("saveFile() saved file", {
            storagePath: e,
            taskResult: r,
            getDownloadURL: t
          }), t;
        });
      });
    }, function (e) {
      a("storage/unknown" === e.code ? 'saveFile() error saving file, No bucket found! Try clicking "Get Started" in firebase -> storage' : "saveFile() error saving file", {
        storageError: e
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

var m,
    d = function d() {};

d.prototype.init = function (e, r) {
  this.app = function (e, r) {
    return r.app ? r.app : n.apps.length ? n.app() : n.initializeApp(e);
  }(e, r), this.firestore = this.app.firestore();
}, d.prototype.db = function () {
  return this.firestore;
}, d.prototype.serverTimestamp = function () {
  return n.firestore.FieldValue.serverTimestamp();
}, d.prototype.auth = function () {
  return this.app.auth();
}, d.prototype.storage = function () {
  return this.app.storage();
};

var v = function v(e, r) {
  i("Auth Client: initializing...", {
    firebaseConfig: e,
    options: r
  });
  var t = new d();
  t.init(e, r), this.auth = t.auth();
};

v.prototype.HandleAuthLogin = function (e) {
  try {
    var r = this,
        t = e.username,
        n = e.password;
    return c(function () {
      return Promise.resolve(r.auth.signInWithEmailAndPassword(t, n)).then(function (e) {
        i("HandleAuthLogin: user sucessfully logged in", {
          user: e
        });
      });
    }, function (r) {
      throw i("HandleAuthLogin: invalid credentials", {
        params: e
      }), r;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, v.prototype.HandleAuthLogout = function (e) {
  try {
    return Promise.resolve(this.auth.signOut()).then(function () {});
  } catch (e) {
    return Promise.reject(e);
  }
}, v.prototype.HandleAuthError = function (e) {}, v.prototype.HandleAuthCheck = function (e) {
  try {
    var r = this;
    return c(function () {
      return Promise.resolve(r.getUserLogin()).then(function (e) {
        i("HandleAuthCheck: user is still logged in", {
          user: e
        });
      });
    }, function (e) {
      throw i("HandleAuthCheck: ", {
        e: e
      }), new Error("Auth check error: " + e);
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, v.prototype.getUserLogin = function () {
  try {
    var e = this;
    return new Promise(function (r, t) {
      e.auth.onAuthStateChanged(function (e) {
        e ? r(e) : t("User not logged in");
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, v.prototype.HandleGetCurrent = function () {
  try {
    var e = this;
    return c(function () {
      return Promise.resolve(e.getUserLogin()).then(function (e) {
        return i("HandleGetCurrent: current user", {
          user: e
        }), e;
      });
    }, function (e) {
      return i("HandleGetCurrent: no user is logged in", {
        e: e
      }), null;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, v.prototype.HandleGetPermissions = function () {
  try {
    var e = this;
    return c(function () {
      return Promise.resolve(e.getUserLogin()).then(function (e) {
        return Promise.resolve(e.getIdTokenResult()).then(function (e) {
          return e.claims;
        });
      });
    }, function (e) {
      return i("HandleGetPermission: no user is logged in or tokenResult error", {
        e: e
      }), null;
    });
  } catch (e) {
    return Promise.reject(e);
  }
}, exports.FirebaseDataProvider = function (e, r) {
  var t = r || {};
  !function (e, r) {
    if (!(e || r && r.app)) throw new Error("Please pass the Firebase firebaseConfig object or options.app to the FirebaseAuthProvider");
    r.rootRef && l(r.rootRef, "test");
  }(e, t), u(e, t), i("react-admin-firebase:: Creating FirebaseDataProvider", {
    firebaseConfig: e,
    options: t
  });
  var n = new d();
  return n.init(e, r), m = new h(n, t), function (e, r, t) {
    try {
      switch (i("FirebaseDataProvider: event", {
        type: e,
        resourceName: r,
        params: t
      }), e) {
        case o.GET_MANY:
          return Promise.resolve(m.apiGetMany(r, t));

        case o.GET_MANY_REFERENCE:
          return Promise.resolve(m.apiGetManyReference(r, t));

        case o.GET_LIST:
          return Promise.resolve(m.apiGetList(r, t));

        case o.GET_ONE:
          return Promise.resolve(m.apiGetOne(r, t));

        case o.CREATE:
          return Promise.resolve(m.apiCreate(r, t));

        case o.UPDATE:
          return Promise.resolve(m.apiUpdate(r, t));

        case o.UPDATE_MANY:
          return Promise.resolve(m.apiUpdateMany(r, t));

        case o.DELETE:
          return Promise.resolve(m.apiDelete(r, t));

        case o.DELETE_MANY:
          return Promise.resolve(m.apiDeleteMany(r, t));

        default:
          return Promise.resolve({});
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };
}, exports.FirebaseAuthProvider = function (e, r) {
  !function (e, r) {
    if (!(e || r && r.app)) throw new Error("Please pass the Firebase firebaseConfig object or options.app to the FirebaseAuthProvider");
  }(e, r);
  var t = new v(e, r);
  return u(e, r), function (e, r) {
    try {
      switch (i("Auth Event: ", {
        type: e,
        params: r
      }), e) {
        case o.AUTH_LOGIN:
          return Promise.resolve(t.HandleAuthLogin(r));

        case o.AUTH_LOGOUT:
          return Promise.resolve(t.HandleAuthLogout(r));

        case o.AUTH_ERROR:
          return Promise.resolve(t.HandleAuthError(r));

        case o.AUTH_CHECK:
          return Promise.resolve(t.HandleAuthCheck(r));

        case "AUTH_GETCURRENT":
          return Promise.resolve(t.HandleGetCurrent());

        case o.AUTH_GET_PERMISSIONS:
          return Promise.resolve(t.HandleGetPermissions());

        default:
          throw new Error("Unhandled auth type:" + e);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };
};