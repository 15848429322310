import React from 'react';
import { Route } from 'react-router-dom';
import routes from './vars/routes';
import { ForgotPassword, ResetPassword } from './pages/auth';
import { Profile } from './pages/users';
import { MROTransactionList, MROTransactionByProductList, MROTransactionByEmployeeList } from './pages/transactions';
import { SlotEdit } from './pages/machines';
// import ComingSoon from './pages/ComingSoon';
import Topup from './pages/topup';
import LockerEdit from '@/pages/machines/LockerEdit';
import SlotEditBoard from '@/pages/machines/SlotEditBoard';
import Doc2UsLockerEdit from '@/pages/machines/LockerDoc2Us/doc2usLockerEdit';

export default [
  <Route exact path={routes.forgotPassword} component={ForgotPassword} noLayout />,
  <Route exact path={routes.resetPassword} component={ResetPassword} noLayout />,
  <Route exact path={routes.profile} component={Profile} />,
  <Route exact path={routes.mroTransactions} component={MROTransactionList} />,
  <Route exact path={routes.mroTransactionsByProduct} component={MROTransactionByProductList} />,
  <Route exact path={routes.mroTransactionsByEmployee} component={MROTransactionByEmployeeList} />,
  <Route exact path={routes.mroTopup} component={Topup} />,
  <Route exact path={routes.machineSlot} component={SlotEdit} />,
  <Route exact path={routes.machineSlotBoard} component={SlotEditBoard} />,
  <Route exact path={routes.lockerSlot} component={LockerEdit} />,
  <Route exact path={routes.doc2usLockerSlot} component={Doc2UsLockerEdit} />,
];
