var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
import InputHelperText from './InputHelperText';

var leftPad = function leftPad(nb) {
  if (nb === void 0) {
    nb = 2;
  }

  return function (value) {
    return ('0'.repeat(nb) + value).slice(-nb);
  };
};

var leftPad4 = leftPad(4);
var leftPad2 = leftPad(2);
/**
 * @param {Date} value value to convert
 * @returns {String} A standardized datetime (yyyy-MM-ddThh:mm), to be passed to an <input type="datetime-local" />
 */

var convertDateToString = function convertDateToString(value) {
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  var yyyy = leftPad4(value.getFullYear());
  var MM = leftPad2(value.getMonth() + 1);
  var dd = leftPad2(value.getDate());
  var hh = leftPad2(value.getHours());
  var mm = leftPad2(value.getMinutes());
  return yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm;
}; // yyyy-MM-ddThh:mm


var dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
/**
 * Converts a date from the Redux store, with timezone, to a date string
 * without timezone for use in an <input type="datetime-local" />.
 *
 * @param {Date | String} value date string or object
 */

var format = function format(value) {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  } // valid dates should not be converted


  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};
/**
 * Converts a datetime string without timezone to a date object
 * with timezone, using the browser timezone.
 *
 * @param {String} value Date string, formatted as yyyy-MM-ddThh:mm
 * @return {Date}
 */


var parse = function parse(value) {
  return new Date(value);
};
/**
 * Input component for entering a date and a time with timezone, using the browser locale
 */


export var DateTimeInput = function DateTimeInput(_a) {
  var label = _a.label,
      helperText = _a.helperText,
      _b = _a.margin,
      margin = _b === void 0 ? 'dense' : _b,
      onBlur = _a.onBlur,
      onChange = _a.onChange,
      onFocus = _a.onFocus,
      options = _a.options,
      source = _a.source,
      resource = _a.resource,
      validate = _a.validate,
      _c = _a.variant,
      variant = _c === void 0 ? 'filled' : _c,
      rest = __rest(_a, ["label", "helperText", "margin", "onBlur", "onChange", "onFocus", "options", "source", "resource", "validate", "variant"]);

  var _d = useInput(__assign({
    format: format,
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    parse: parse,
    resource: resource,
    source: source,
    type: 'datetime-local',
    validate: validate
  }, rest)),
      id = _d.id,
      input = _d.input,
      isRequired = _d.isRequired,
      _e = _d.meta,
      error = _e.error,
      touched = _e.touched;

  return React.createElement(TextField, __assign({
    id: id
  }, input, {
    variant: variant,
    margin: margin,
    error: !!(touched && error),
    helperText: touched && error || helperText ? React.createElement(InputHelperText, {
      touched: touched,
      error: error,
      helperText: helperText
    }) : null,
    label: React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    }),
    InputLabelProps: {
      shrink: true
    }
  }, options, sanitizeRestProps(rest)));
};
DateTimeInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string
};
DateTimeInput.defaultProps = {
  options: {}
};
export default DateTimeInput;