import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { get, isEmpty } from 'lodash';

/**
 * Use this along with ReferenceInput to add additional fields.
 */

const HiddenReferenceInput = ({ id, reference, source, referenceField }) => {
  const record = useSelector(state => get(state.admin.resources, `${reference}.data.${id}`));
  if (isEmpty(referenceField) || isEmpty(record)) return null;
  return <Field component="input" type="hidden" name={source} initialValue={get(record, referenceField)} />;
};

export default HiddenReferenceInput;
