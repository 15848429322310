import React, { useState, useEffect } from 'react';
import { Notification, Error, toggleSidebar } from 'react-admin';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Auth as AuthProvider } from '@/utils/auth';
import Topbar from './Topbar';
import Sidebar from './Sidebar';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.props.catch(error, errorInfo);
  }
  render() {
    return this.props.children;
  }
}

function AppLayout(props) {
  const { title, children } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [errorState, setErrorState] = useState({ hasError: false });
  const { hasError, error, errorInfo } = errorState;
  const handleCatch = (error, errorInfo) => setErrorState({ hasError: true, error, errorInfo });
  const sideOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const toggleSide = () => dispatch(toggleSidebar());

  // Reset the error state upon navigation
  useEffect(() => {
    return history.listen(() => {
      if (hasError) {
        setErrorState({ hasError: false });
      }
    });
  }, [history, hasError]);

  return (
    <AuthProvider>
      <ErrorBoundary catch={handleCatch}>
        <div className={classes.root}>
          <Topbar className={classes.topBar} toggleSide={toggleSide} />
          <div className={classes.container}>
            <Sidebar className={classes.sideBar} sideOpen={sideOpen} toggleSide={toggleSide} />
            <main className={classes.content}>
              {hasError ? <Error error={error} errorInfo={errorInfo} title={title} /> : children}
            </main>
          </div>
          <Notification />
        </div>
      </ErrorBoundary>
    </AuthProvider>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 3,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  sideBar: {
    zIndex: 2,
    width: 250,
    minWidth: 250,
    flex: '0 0 auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
}));

export default AppLayout;
