export default {
  create: function create() {
    return Promise.resolve(null);
  },
  delete: function _delete() {
    return Promise.resolve(null);
  },
  deleteMany: function deleteMany() {
    return Promise.resolve(null);
  },
  getList: function getList() {
    return Promise.resolve(null);
  },
  getMany: function getMany() {
    return Promise.resolve(null);
  },
  getManyReference: function getManyReference() {
    return Promise.resolve(null);
  },
  getOne: function getOne() {
    return Promise.resolve(null);
  },
  update: function update() {
    return Promise.resolve(null);
  },
  updateMany: function updateMany() {
    return Promise.resolve(null);
  }
};