import React from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);
  const notify = useNotify();
  const arr = props.value.split(';');
  arr.pop();

  const handleClose = () => {
    setOpen(false);
  };

  const elements = arr.map((value, index) => <li key={index}>{value}</li>);

  var documents = "";
  arr.map(value => {
      documents += value + '\n'
  }) 

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Upload Unsuccessful! '}
          <br />
          {'Please take note of the below errors!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul> {elements} </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CopyToClipboard text={documents} onCopy={() => notify('Copied to Clipboard!') }>
            <Button variant="outlined" color="primary" autoFocus> Copy</Button>
          </CopyToClipboard>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
