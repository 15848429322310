import React from 'react';
import { useGetList } from 'react-admin';
import { Typography, CircularProgress } from '@material-ui/core';
import { ACCESS, useAuth, checkAccess } from '@/utils/auth';
import { CheckboxGroupInput } from '@/components/input';

// NEEd to change the selctarray input something else
const MROAccessGroupInput = ({ client, ...rest }) => {
  const { permissions } = useAuth();
  const loginHasMROAccess = checkAccess(permissions, ACCESS.MRO);
  const { data, loading, ids } = useGetList('employeeTypes', { page: 1, perPage: 99999 }, null, { client });

  const options = ids.map(id => {
    const { name } = data[id];
    return { value: id, label: name };
  });

  return (
    <>
      <Typography gutterBottom variant="h6">
        MRO permissions
      </Typography>
      <Typography gutterBottom variant="body2">
        Select to enable access to employee group.
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <CheckboxGroupInput name="mroAccessGroup" options={options} disabled={!loginHasMROAccess} {...rest} />
      )}
    </>
  );
};

export default MROAccessGroupInput;
