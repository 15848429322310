import React from 'react';
import PropTypes from 'prop-types';
import { ResettableTextField, InputHelperText, useInput, FieldTitle } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
export const TextInput = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  resource,
  source,
  validate,
  ...rest
}) => {
  const { id, input, isRequired, meta } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });
  const { error, touched, submitError, submitFailed, dirtySinceLastSubmit } = meta;
  const hasValidateError = !!(touched && error);
  const hasSubmitError = submitFailed && submitError && !dirtySinceLastSubmit;

  return (
    <ResettableTextField
      variant="outlined"
      fullWidth
      margin="none"
      id={id}
      {...input}
      label={
        label !== '' &&
        label !== false && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      error={hasSubmitError || hasValidateError}
      helperText={
        <InputHelperText
          touched={hasSubmitError || hasValidateError}
          error={(hasSubmitError && submitError) || error}
          helperText={helperText}
        />
      }
      {...options}
      {...sanitizeRestProps(rest)}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

TextInput.defaultProps = {
  options: {},
};

export default TextInput;
