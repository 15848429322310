var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Children, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import { useTranslate, useInitializeFormWithRecord, sanitizeEmptyValues } from 'ra-core';
import getFormInitialValues from './getFormInitialValues';
import FormInput from './FormInput';
import Toolbar from './Toolbar';
import CardContentInner from '../layout/CardContentInner';

var SimpleForm = function SimpleForm(_a) {
  var initialValues = _a.initialValues,
      defaultValue = _a.defaultValue,
      saving = _a.saving,
      props = __rest(_a, ["initialValues", "defaultValue", "saving"]);

  var redirect = useRef(props.redirect); // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed

  var setRedirect = function setRedirect(newRedirect) {
    redirect.current = newRedirect;
  };

  var translate = useTranslate();
  var finalInitialValues = getFormInitialValues(initialValues, defaultValue, props.record);

  var submit = function submit(values) {
    var finalRedirect = typeof redirect.current === undefined ? props.redirect : redirect.current;
    var finalValues = sanitizeEmptyValues(finalInitialValues, values);
    props.save(finalValues, finalRedirect);
  };

  return React.createElement(Form, __assign({
    key: props.version,
    initialValues: finalInitialValues,
    onSubmit: submit,
    mutators: __assign({}, arrayMutators),
    keepDirtyOnReinitialize: true,
    subscription: defaultSubscription
  }, props, {
    render: function render(formProps) {
      return React.createElement(SimpleFormView, __assign({
        saving: formProps.submitting || saving,
        translate: translate,
        setRedirect: setRedirect
      }, props, formProps));
    }
  }));
};

var defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
export default SimpleForm;

var SimpleFormView = function SimpleFormView(_a) {
  var basePath = _a.basePath,
      children = _a.children,
      className = _a.className,
      invalid = _a.invalid,
      form = _a.form,
      pristine = _a.pristine,
      record = _a.record,
      defaultRedirect = _a.redirect,
      resource = _a.resource,
      saving = _a.saving,
      setRedirect = _a.setRedirect,
      submitOnEnter = _a.submitOnEnter,
      toolbar = _a.toolbar,
      undoable = _a.undoable,
      version = _a.version,
      handleSubmit = _a.handleSubmit,
      variant = _a.variant,
      margin = _a.margin,
      rest = __rest(_a, ["basePath", "children", "className", "invalid", "form", "pristine", "record", "redirect", "resource", "saving", "setRedirect", "submitOnEnter", "toolbar", "undoable", "version", "handleSubmit", "variant", "margin"]);

  useInitializeFormWithRecord(record);
  var handleSubmitWithRedirect = useCallback(function (redirect) {
    if (redirect === void 0) {
      redirect = defaultRedirect;
    }

    setRedirect(redirect);
    handleSubmit();
  }, [setRedirect, defaultRedirect, handleSubmit]);
  return React.createElement("form", __assign({
    className: classnames('simple-form', className)
  }, sanitizeRestProps(rest)), React.createElement(CardContentInner, {
    key: version
  }, Children.map(children, function (input) {
    return React.createElement(FormInput, {
      basePath: basePath,
      input: input,
      record: record,
      resource: resource,
      variant: variant,
      margin: margin
    });
  })), toolbar && React.cloneElement(toolbar, {
    basePath: basePath,
    handleSubmitWithRedirect: handleSubmitWithRedirect,
    handleSubmit: handleSubmit,
    invalid: invalid,
    pristine: pristine,
    record: record,
    redirect: defaultRedirect,
    resource: resource,
    saving: saving,
    submitOnEnter: submitOnEnter,
    undoable: undoable
  }));
};

SimpleFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  save: PropTypes.func,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number
};
SimpleFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: React.createElement(Toolbar, null)
};

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var anyTouched = _a.anyTouched,
      array = _a.array,
      asyncBlurFields = _a.asyncBlurFields,
      asyncValidate = _a.asyncValidate,
      asyncValidating = _a.asyncValidating,
      autofill = _a.autofill,
      blur = _a.blur,
      change = _a.change,
      clearAsyncError = _a.clearAsyncError,
      clearFields = _a.clearFields,
      clearSubmit = _a.clearSubmit,
      clearSubmitErrors = _a.clearSubmitErrors,
      destroy = _a.destroy,
      dirty = _a.dirty,
      dirtyFields = _a.dirtyFields,
      dirtyFieldsSinceLastSubmit = _a.dirtyFieldsSinceLastSubmit,
      dirtySinceLastSubmit = _a.dirtySinceLastSubmit,
      dispatch = _a.dispatch,
      form = _a.form,
      handleSubmit = _a.handleSubmit,
      hasSubmitErrors = _a.hasSubmitErrors,
      hasValidationErrors = _a.hasValidationErrors,
      initialize = _a.initialize,
      initialized = _a.initialized,
      initialValues = _a.initialValues,
      pristine = _a.pristine,
      pure = _a.pure,
      redirect = _a.redirect,
      reset = _a.reset,
      resetSection = _a.resetSection,
      save = _a.save,
      setRedirect = _a.setRedirect,
      submit = _a.submit,
      submitError = _a.submitError,
      submitErrors = _a.submitErrors,
      submitAsSideEffect = _a.submitAsSideEffect,
      submitFailed = _a.submitFailed,
      submitSucceeded = _a.submitSucceeded,
      submitting = _a.submitting,
      touch = _a.touch,
      translate = _a.translate,
      triggerSubmit = _a.triggerSubmit,
      undoable = _a.undoable,
      untouch = _a.untouch,
      valid = _a.valid,
      validate = _a.validate,
      validating = _a.validating,
      _reduxForm = _a._reduxForm,
      props = __rest(_a, ["anyTouched", "array", "asyncBlurFields", "asyncValidate", "asyncValidating", "autofill", "blur", "change", "clearAsyncError", "clearFields", "clearSubmit", "clearSubmitErrors", "destroy", "dirty", "dirtyFields", "dirtyFieldsSinceLastSubmit", "dirtySinceLastSubmit", "dispatch", "form", "handleSubmit", "hasSubmitErrors", "hasValidationErrors", "initialize", "initialized", "initialValues", "pristine", "pure", "redirect", "reset", "resetSection", "save", "setRedirect", "submit", "submitError", "submitErrors", "submitAsSideEffect", "submitFailed", "submitSucceeded", "submitting", "touch", "translate", "triggerSubmit", "undoable", "untouch", "valid", "validate", "validating", "_reduxForm"]);

  return props;
};