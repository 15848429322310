var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { isRequired, FieldTitle } from 'ra-core';
import { useFieldArray } from 'react-final-form-arrays';
import { InputLabel, FormControl } from '@material-ui/core';
import sanitizeRestProps from './sanitizeRestProps';
/**
 * To edit arrays of data embedded inside a record, <ArrayInput> creates a list of sub-forms.
 *
 *  @example
 *
 *      import { ArrayInput, SimpleFormIterator, DateInput, UrlInput } from 'react-admin';
 *
 *      <ArrayInput source="backlinks">
 *          <SimpleFormIterator>
 *              <DateInput source="date" />
 *              <UrlInput source="url" />
 *          </SimpleFormIterator>
 *      </ArrayInput>
 *
 * <ArrayInput> allows the edition of embedded arrays, like the backlinks field
 * in the following post record:
 *
 * {
 *   id: 123
 *   backlinks: [
 *         {
 *             date: '2012-08-10T00:00:00.000Z',
 *             url: 'http://example.com/foo/bar.html',
 *         },
 *         {
 *             date: '2012-08-14T00:00:00.000Z',
 *             url: 'https://blog.johndoe.com/2012/08/12/foobar.html',
 *         }
 *    ]
 * }
 *
 * <ArrayInput> expects a single child, which must be a *form iterator* component.
 * A form iterator is a component accepting a fields object as passed by
 * react-final-form-arrays's useFieldArray() hook, and defining a layout for
 * an array of fields. For instance, the <SimpleFormIterator> component
 * displays an array of fields in an unordered list (<ul>), one sub-form by
 * list item (<li>). It also provides controls for adding and removing
 * a sub-record (a backlink in this example).
 *
 * @see https://github.com/final-form/react-final-form-arrays
 */

export var ArrayInput = function ArrayInput(_a) {
  var className = _a.className,
      defaultValue = _a.defaultValue,
      label = _a.label,
      children = _a.children,
      record = _a.record,
      resource = _a.resource,
      source = _a.source,
      validate = _a.validate,
      variant = _a.variant,
      _b = _a.margin,
      margin = _b === void 0 ? 'dense' : _b,
      rest = __rest(_a, ["className", "defaultValue", "label", "children", "record", "resource", "source", "validate", "variant", "margin"]);

  var fieldProps = useFieldArray(source, __assign({
    initialValue: defaultValue,
    validate: validate
  }, rest));
  return React.createElement(FormControl, __assign({
    fullWidth: true,
    margin: "normal",
    className: className
  }, sanitizeRestProps(rest)), React.createElement(InputLabel, {
    htmlFor: source,
    shrink: true
  }, React.createElement(FieldTitle, {
    label: label,
    source: source,
    resource: resource,
    isRequired: isRequired(validate)
  })), cloneElement(Children.only(children), __assign(__assign({}, fieldProps), {
    record: record,
    resource: resource,
    source: source,
    variant: variant,
    margin: margin
  })));
};
ArrayInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  record: PropTypes.object,
  options: PropTypes.object,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)])
};
ArrayInput.defaultProps = {
  options: {},
  fullWidth: true
};
export default ArrayInput;