import { useState, useEffect, useCallback } from 'react';
import { CRUD_UPDATE, useNotify, useUpdate } from 'react-admin';
import { MACHINE, LockerStatus } from '@/vars';
import { get } from 'lodash';
import { db, realtimeDB } from '@/vars/firebase';
import { MACHINES } from '@/vars/resources';
import firebase from 'firebase/app';

export const useGetSlotsRealtime = machineId => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  useEffect(() => {
    const unsubscribe = db.collection(`machines/${machineId}/slots`).onSnapshot(querySnapshot => {
      const data = {};
      querySnapshot.forEach(doc => (data[doc.id] = doc.data()));
      setState({ data, loading: false, loaded: true });
    });
    return unsubscribe;
  }, [machineId]);
  return state;
};

export const useGetSlots = machineId => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  const getSlots = useCallback(async () => {
    const snapshots = await db.collection(`machines/${machineId}/slots`).get();
    const data = {};
    snapshots.docs.forEach(doc => (data[doc.id] = doc.data()));
    setState({ data, loading: false, loaded: true });
  }, [machineId]);

  useEffect(() => {
    getSlots();
  }, [getSlots]);

  return state;
};

export const useGetSlot = (machineId, machineSlot) => {
  const path = `machines/${machineId}/slots/${machineSlot}`;
  return useGetSlotInformation(path);
};

export const useGetBoardSlot = (machineID, boardID, machineSlot) => {
  const path = `machines/${machineID}/boards/${boardID}/slots/${machineSlot}`;
  return useGetSlotInformation(path);
};

const useGetSlotInformation = path => {
  const [state, setState] = useState({ data: null, loading: true, loaded: false });
  const getSlot = async path => {
    const doc = await db.doc(path).get();
    if (!doc.exists) return false;
    return doc.data();
  };
  useEffect(() => {
    getSlot(path)
      .then(data => setState({ data, loading: false, loaded: true }))
      .catch(() => setState({ data: null, loading: false, loaded: true }));
  }, [path]);
  return state;
};

export const useSaveSlot = () => {
  const [saving, setSaving] = useState(false);
  const save = async (machineId, data) => {
    setSaving(true);
    await db.doc(`machines/${machineId}/slots/${data.machineSlot}`).set(data, { merge: true });
    setSaving(false);
  };
  return { saving, save };
};

export const useRemoveSlot = (machineId, machineSlot) => {
  const path = `machines/${machineId}/slots/${machineSlot}`;
  return useRemoveSlotInformation(path);
};

export const useRemoveBoardSlot = (machineID, boardID, machineSlot) => {
  const path = `machines/${machineID}/boards/${boardID}/slots/${machineSlot}`;
  return useRemoveSlotInformation(path);
};

const useRemoveSlotInformation = path => {
  const [removing, setRemoving] = useState(false);
  const remove = async () => {
    setRemoving(true);
    await db.doc(path).delete();
    setRemoving(false);
  };
  return { removing, remove };
};

var locked = false;
const unlocked = () => {
  locked = false;
};
export const checkConnection = async id => {
  if (locked) return;
  locked = true;
  setTimeout(unlocked, 3000);
  const machineRef = db.collection('machines').doc(id);
  machineRef.set(
    {
      poke: firebase.firestore.FieldValue.delete(),
    },
    { merge: true }
  );
  await machineRef.set({ poke: true }, { merge: true });

  await timeout(1000);
  const getPokeAck = () => {
    return firebase
      .firestore()
      .collection('machines')
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const data = doc.data();
          return data.pokeAck;
        } else {
          return '';
        }
      });
  };

  getPokeAck().then(result => {
    if (result === true) {
      alert('Your machine connection is stable.');
    } else if (result === false) {
      alert('Your machine is disconnected !!');
    }
  });

  await timeout(1000);
  await machineRef.set({ pokeAck: false }, { merge: true });
};

export function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

export const useClearErrorLogs = (notification = true) => {
  const [clearingLogs, setClearingLogs] = useState(false);
  const undoable = false;
  const [update] = useUpdate(MACHINES);
  const notify = useNotify();

  const clearLogs = async id => {
    setClearingLogs(true);

    const machineRef = db.collection('machines').doc(id);
    machineRef.set(
      {
        status: { terminal_error: firebase.firestore.FieldValue.delete() },
      },
      { merge: true }
    );

    const machineData = (await machineRef.get()).data();

    const status = (await (machineData && machineData.status)) || {};

    update(
      {
        payload: {
          id,
          data: {
            id,
            status,
          },
        },
      },
      {
        action: CRUD_UPDATE,
        undoable,
        onSuccess: response => {
          if(response.data.status.terminal_error === undefined) {
            setClearingLogs(false);
            notification && notify('Successfully cleared machine logs', 'info', { smart_count: 1 }, undoable);
            console.log('success update');
          } else {
            setClearingLogs(false);
            notification && notify('Failed to clear machine logs', 'error');
            console.log('failed update');
          }
          
        },
        onFailure: error => {
          setClearingLogs(false);
          notification && notify('Failed to clear machine logs', 'error');
          console.log('failed update');
        },
      }
    );
  };
  return [clearingLogs, clearLogs];
};

export const useRefreshMachineStatus = (notification = true) => {
  const [refreshing, setRefreshing] = useState(false);
  const undoable = false;
  const [update] = useUpdate(MACHINES);
  const notify = useNotify();

  const refresh = async id => {
    setRefreshing(true);

    const machineRef = db.collection('machines').doc(id);
    machineRef.set(
      {
        status: { online: firebase.firestore.FieldValue.delete(), offline: firebase.firestore.FieldValue.delete() },
      },
      { merge: true }
    );

    const snapshot = await realtimeDB.ref('/machines/' + id).once('value');
    let isConnected = snapshot.val().isConnected;
    if (isConnected === true) {
      await machineRef.set({ status: { online: true } }, { merge: true });
    } else if (isConnected === false) {
      await machineRef.set({ status: { offline: true } }, { merge: true });
    }

    const machineData = (await machineRef.get()).data();

    const status = (await (machineData && machineData.status)) || {};

    update(
      {
        payload: {
          id,
          data: {
            id,
            status,
          },
        },
      },
      {
        action: CRUD_UPDATE,
        undoable,
        onSuccess: response => {
          setRefreshing(false);
          notification && notify('Successful refresh machine status', 'info', { smart_count: 1 }, undoable);
          console.log('success update');
        },
        onFailure: error => {
          setRefreshing(false);
          notification && notify('Failed to refresh machine status', 'error');
          console.log('failed update');
        },
      }
    );
  };
  return [refreshing, refresh];
};

export const useGetApkVersion = machineId => {
  const [version, setVersion] = useState(0);
  const getVersion = async machineId => {
    const doc = await db.doc(`machines/${machineId}`).get();
    if (!doc.exists) return false;
    return doc.data();
  };
  useEffect(() => {
    getVersion(machineId)
      .then(data => setVersion(data.versionCode))
      .catch(() => setVersion(null));
  }, [machineId]);
  return version;
};

export const useGetMachineData = machineId => {
  const [machineModel, setMachineModel] = useState({ data: null, loaded: false });
  const getMachineData = async machineId => {
    const doc = await db.doc(`machines/${machineId}`).get();
    if (!doc.exists) return false;
    return doc.data();
  };
  useEffect(() => {
    getMachineData(machineId)
      .then(data => setMachineModel({ data: data, loaded: true }))
      .catch(() => setMachineModel({}));
  }, [machineId]);
  return machineModel;
};

export const useGetBoardDataFromMachine = machineID => {
  const [boardData, setBoardData] = useState({ boards: [], loading: true, loaded: false });

  useEffect(() => {
    const unsubscribe = db
      .collection(`machines`)
      .doc(machineID)
      .collection(`boards`)
      .onSnapshot(querySnapshot => {
        let boards = [];
        querySnapshot.forEach(doc => {
          let boardDataToPush = doc.data();
          boardDataToPush.id = doc.id;
          boards.push(boardDataToPush);
        });
        setBoardData({ boards, loading: false, loaded: true });
      });
    return unsubscribe;
  }, [machineID]);

  return boardData;
};

export const useGetItemsFromMachines = (machineID, boardID) => {
  const [itemInSlots, setItemInSlots] = useState({ items: {}, loading: true, loaded: false });
  useEffect(() => {
    const unsubscribe = db
      .collection(`machines`)
      .doc(machineID)
      .collection(`boards`)
      .doc(boardID)
      .collection(`slots`)
      .onSnapshot(querySnapshot => {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.id] = { id: doc.id, data: doc.data() };
        });
        setItemInSlots({ items, loading: false, loaded: true });
      });
    return unsubscribe;
  }, [machineID, boardID]);

  return itemInSlots;
};

export const useSaveBoardSlot = () => {
  const [saving, setSaving] = useState(false);
  const save = async (machineID, boardID, data) => {
    setSaving(true);
    await db.doc(`machines/${machineID}/boards/${boardID}/slots/${data.machineSlot}`).set(data, { merge: true });
    setSaving(false);
  };
  return { saving, save };
};

export const useGetDoc2UsLockerItems = machineID => {
  //TODO: Confirm design of firebase, might need to add in boardID and look into individual board's dispense_tasks

  const [doc2usLockerItemList, setDoc2usLockerItemList] = useState({ items: {}, loaded: false });
  useEffect(() => {
    const unsubscribe = db
      .collection(`machines`)
      .doc(machineID)
      .collection(`dispense_tasks`)
      .onSnapshot(querySnapshot => {
        const items = {};
        querySnapshot.forEach(doc => {
          items[doc.data().slotId] = { id: doc.id, data: doc.data() };
        });
        setDoc2usLockerItemList({ items, loaded: true });
      });
    return unsubscribe;
  }, [machineID]);

  return doc2usLockerItemList;
};

export const useGetDoc2UsLockerSlot = (machineID, machineSlot) => {
  const [state, setState] = useState({ data: null, id: null, loaded: false });

  useEffect(() => {
    const unsubscribe = db
      .collection(`machines`)
      .doc(machineID)
      .collection(`dispense_tasks`)
      .where(`slotId`, `==`, machineSlot)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          setState({ data: doc.data(), id: doc.id, loaded: true });
        });
      });
    return unsubscribe;
  }, [machineID, machineSlot]);

  return state;
};

export const useGetDoc2UsLockerExpiredList = machineID => {
  const [state, setState] = useState({ expiredIDArray: null, loaded: false });

  useEffect(() => {
    const unsubscribe = db
      .collection(`machines`)
      .doc(machineID)
      .collection(`dispense_tasks`)
      .where(`status`, `in`, [LockerStatus.ExpiredBeforeCollection, LockerStatus.ExpiredBeforeDeposit])
      .onSnapshot(querySnapshot => {
        const id = [];
        querySnapshot.forEach(doc => {
          id.push(doc.id);
        });
        setState({ expiredIDArray: id, loaded: true });
      });
    return unsubscribe;
  }, [machineID]);

  return state;
};

export const useSetAdminCodeOnMultipleLockerDoc2Us = () => {
  const [saving, setSaving] = useState(false);
  const save = async (machineId, listOfExpiredTaskID, adminCode) => {
    setSaving(true);
    listOfExpiredTaskID.forEach(async expiredTaskID => {
      await db.doc(`machines/${machineId}/dispense_tasks/${expiredTaskID}`).set(adminCode, { merge: true });
    });
    setSaving(false);
  };
  return { saving, save };
};

export const useSaveSlotDoc2Us = () => {
  const [saving, setSaving] = useState(false);
  const save = async (machineId, data, taskID) => {
    setSaving(true);
    await db.doc(`machines/${machineId}/dispense_tasks/${taskID}`).set(data, { merge: true });
    setSaving(false);
  };
  return { saving, save };
};

export const useRemoveLockerSlotInformationDoc2Us = (machineId, taskID) => {
  const [removing, setRemoving] = useState(false);
  const remove = async () => {
    setRemoving(true);
    await db.doc(`machines/${machineId}/dispense_tasks/${taskID}`).delete();
    setRemoving(false);
  };
  return { removing, remove };
};

export const useGetProductThroughSKU = skuList => {
  const [productList, setProductList] = useState({ productList: null, loaded: false });

  useEffect(() => {
    const unsubscribe = db
      .collection(`products`)
      .where(`sku`, `in`, skuList)
      .onSnapshot(querySnapshot => {
        const productList = [];
        querySnapshot.forEach(doc => {
          const product = {};
          product.id = doc.id;
          product.data = doc.data();
          productList.push(product);
        });
        setProductList({ productList: productList, loaded: true });
      });
    return unsubscribe;
  }, [skuList]);

  return productList;
};
