var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Avatar, createMuiTheme, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import defaultTheme from '../defaultTheme';
import Notification from '../layout/Notification';
import DefaultLoginForm from './LoginForm';
var useStyles = makeStyles(function (theme) {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    card: {
      minWidth: 300,
      marginTop: '6em'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    }
  };
});
/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */

var Login = function Login(_a) {
  var theme = _a.theme,
      className = _a.className,
      children = _a.children,
      staticContext = _a.staticContext,
      backgroundImage = _a.backgroundImage,
      rest = __rest(_a, ["theme", "className", "children", "staticContext", "backgroundImage"]);

  var containerRef = useRef();
  var styles = useStyles({});
  var muiTheme = useMemo(function () {
    return createMuiTheme(theme);
  }, [theme]);
  var backgroundImageLoaded = false;
  var checkAuth = useCheckAuth();
  var history = useHistory();
  useEffect(function () {
    checkAuth({}, false).then(function () {
      // already authenticated, redirect to the home page
      history.push('/');
    }).catch(function () {// not authenticated, stay on the login page
    });
  }, [checkAuth, history]);

  var updateBackgroundImage = function updateBackgroundImage() {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = "url(" + backgroundImage + ")";
      backgroundImageLoaded = true;
    }
  }; // Load background image asynchronously to speed up time to interactive


  var lazyLoadBackgroundImage = function lazyLoadBackgroundImage() {
    if (backgroundImage) {
      var img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(function () {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });
  return React.createElement(ThemeProvider, {
    theme: muiTheme
  }, React.createElement("div", __assign({
    className: classnames(styles.main, className)
  }, rest, {
    ref: containerRef
  }), React.createElement(Card, {
    className: styles.card
  }, React.createElement("div", {
    className: styles.avatar
  }, React.createElement(Avatar, {
    className: styles.icon
  }, React.createElement(LockIcon, null))), children), React.createElement(Notification, null)));
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.object,
  staticContext: PropTypes.object
};
Login.defaultProps = {
  backgroundImage: 'https://source.unsplash.com/random/1600x900/daily',
  theme: defaultTheme,
  children: React.createElement(DefaultLoginForm, null)
};
export default Login;