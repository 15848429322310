import React from 'react';
import { Create, Edit } from 'react-admin';
import { Can, ACCESS } from '@/utils/auth';
import { Page } from '@/components';
import ProductList from './ProductList';
import ProductForm from './ProductForm';

const ProductCreate = props => (
  <Can module={ACCESS.INVENTORY}>
    <Page sectionTitle="Inventory" title="Create product">
      <Create {...props}>
        <ProductForm />
      </Create>
    </Page>
  </Can>
);

const ProductEdit = props => (
  <Can module={ACCESS.INVENTORY}>
    <Page sectionTitle="Inventory" title="Edit product">
      <Edit {...props}>
        <ProductForm />
      </Edit>
    </Page>
  </Can>
);

export { ProductList, ProductCreate, ProductEdit };
