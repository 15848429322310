import get from 'lodash/get';
import { linkToRecord } from '../../util';
/**
 * @typedef ReferenceProps
 * @type {Object}
 * @property {boolean} loading: boolean indicating if the reference is loading
 * @property {boolean} loaded: boolean indicating if the reference has loaded
 * @property {Object} referenceRecord: the referenced record.
 * @property {string | false} resourceLinkPath link to the page of the related record (depends on link) (false is no link)
 */

/**
 * Get the link toward the referenced resource
 *
 * @example
 *
 * const linkPath = getResourceLinkPath({
 *      basePath: '/comments',
 *      link: 'edit',
 *      reference: 'users',
 *      record: {
 *          userId: 7
 *      },
 *      resource: 'comments',
 *      source: 'userId',
 * });
 *
 * @param {Object} option
 * @param {string} option.basePath basepath to current resource
 * @param {string | false | LinkToFunctionType} option.link="edit" The link toward the referenced record. 'edit', 'show' or false for no link (default to edit). Alternatively a function that returns a string
 * @param {string | false | LinkToFunctionType} [option.linkType] DEPRECATED : old name for link
 * @param {string} option.reference The linked resource name
 * @param {Object} option.record The The current resource record
 * @param {string} option.resource The current resource name
 * @param {string} option.source The key of the linked resource identifier
 *
 * @returns {ReferenceProps} The reference props
 */

var getResourceLinkPath = function getResourceLinkPath(_a) {
  var basePath = _a.basePath,
      _b = _a.link,
      link = _b === void 0 ? 'edit' : _b,
      linkType = _a.linkType,
      reference = _a.reference,
      _c = _a.record,
      record = _c === void 0 ? {
    id: ''
  } : _c,
      resource = _a.resource,
      source = _a.source;
  var sourceId = get(record, source);
  var rootPath = basePath.replace(resource, reference); // Backward compatibility: keep linkType but with warning

  var getResourceLinkPath = function getResourceLinkPath(linkTo) {
    return !linkTo ? false : typeof linkTo === 'function' ? linkTo(record, reference) : linkToRecord(rootPath, sourceId, linkTo);
  };

  if (linkType !== undefined) {
    console.warn("The 'linkType' prop is deprecated and should be named to 'link' in <ReferenceField />");
  }

  var resourceLinkPath = getResourceLinkPath(linkType !== undefined ? linkType : link);
  return resourceLinkPath;
};

export default getResourceLinkPath;