import { useCallback } from 'react';
import useAuthProvider, { defaultAuthParams } from './useAuthProvider';
import { useLocation, useHistory } from 'react-router-dom';
/**
 * Get a callback for calling the authProvider.login() method
 * and redirect to the previous authenticated page (or the home page) on success.
 *
 * @see useAuthProvider
 *
 * @returns {Function} login callback
 *
 * @example
 *
 * import { useLogin } from 'react-admin';
 *
 * const LoginButton = () => {
 *     const [loading, setLoading] = useState(false);
 *     const login = useLogin();
 *     const handleClick = {
 *         setLoading(true);
 *         login({ username: 'john', password: 'p@ssw0rd' }, '/posts')
 *             .then(() => setLoading(false));
 *     }
 *     return <button onClick={handleClick}>Login</button>;
 * }
 */

var useLogin = function useLogin() {
  var authProvider = useAuthProvider();
  var location = useLocation();
  var history = useHistory();
  var nextPathName = location.state && location.state.nextPathname;
  var login = useCallback(function (params, pathName) {
    if (params === void 0) {
      params = {};
    }

    if (pathName === void 0) {
      pathName = defaultAuthParams.afterLoginUrl;
    }

    return authProvider.login(params).then(function (ret) {
      history.push(nextPathName || pathName);
      return ret;
    });
  }, [authProvider, history, nextPathName]);
  var loginWithoutProvider = useCallback(function (_, __) {
    history.push(defaultAuthParams.afterLoginUrl);
    return Promise.resolve();
  }, [history]);
  return authProvider ? login : loginWithoutProvider;
};

export default useLogin;