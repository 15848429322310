import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ReferenceField } from 'react-admin';
import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { GroupField, Filter } from '@/ra';
import { Page } from '@/components';

const CategoryList = props => {
  const { permissions } = useAuth();
  const filter = useDefaultFilter('client');
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const searchFilter = <Filter searchKeys={['name']} />;

  return (
    <Can module={ACCESS.INVENTORY}>
      <Page sectionTitle="inventory" title="List category">
        <List {...props} filter={filter} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
          <Datagrid>
            <TextField source="name" textAlign="left" />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <GroupField textAlign="right">
              <EditButton />
              <DeleteButton />
            </GroupField>
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default CategoryList;
