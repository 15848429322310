import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  DeleteButton,
  Button,
  useUpdate,
  useNotify,
  CRUD_UPDATE,
} from 'react-admin';
import { colors } from '@material-ui/core';

import { USER } from '@/vars';
import { functions } from '@/vars/firebase';
import { Can, ACCESS } from '@/utils/auth';
import useDefaultFilter from '@/utils/useDefaultFilter';
import { LabelField, ActionMenu, Filter } from '@/ra';
import { Page } from '@/components';

const statusColor = {
  [USER.STATUS.ACTIVE]: colors.green[500],
  [USER.STATUS.DISABLED]: colors.grey[500],
};

const ToggleStatusButton = ({ basePath, record = {}, resource, ...rest }) => {
  const { status, id } = record;
  const undoable = true;
  const [update] = useUpdate(resource, id);
  const notify = useNotify();

  const toggleStatus = () => {
    update(
      {
        payload: {
          id: id,
          data: { id, status: status === USER.STATUS.ACTIVE ? USER.STATUS.DISABLED : USER.STATUS.ACTIVE },
        },
      },
      {
        action: CRUD_UPDATE,
        undoable,
        onSuccess: response => notify('user.notification.status_update_done', 'info', { smart_count: 1 }, undoable),
        onFailure: error => notify('user.notification.status_update_fail', 'error'),
      }
    );
  };

  return <Button onClick={toggleStatus} label={status === USER.STATUS.ACTIVE ? 'Disable' : 'Enable'} {...rest} />;
};

const ResetPasswordButton = ({ basePath, record = {}, resource, ...rest }) => {
  const { email } = record;
  const notify = useNotify();
  const sendPasswordReset = () => {
    notify('user.notification.reset_password_start', 'info');
    functions
      .httpsCallable('auth-resetPassword')({ email })
      .then(() => notify('user.notification.reset_password_done', 'info'))
      .catch(() => notify('user.notification.reset_password_fail', 'error'));
  };
  return <Button onClick={sendPasswordReset} label="Reset password" {...rest} />;
};

const UserList = props => {
  const fitler = useDefaultFilter();
  const searchFilter = <Filter searchKeys={['firstName', 'lastName', 'email', 'client.companyName']} />;

  return (
    <Can module={ACCESS.USER}>
      <Page sectionTitle="users" title="List user">
        <List {...props} filter={fitler} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
          <Datagrid>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="client.companyName" label="Company name" />
            <LabelField source="status" color={record => statusColor[record.status]} />
            <ActionMenu label="Actions">
              <EditButton icon={null} />
              <ToggleStatusButton />
              <ResetPasswordButton />
              <DeleteButton icon={null} />
            </ActionMenu>
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default UserList;
