import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import { mainDataColors } from '../../vars/__theme';
import { customTooltip, customTooltipLabelContent, LegendSummary } from '../../utils/charts';

const chartColor1 = mainDataColors[0];
const chartColor2 = mainDataColors[2];

function TodaysSalesChart({ data, loading }) {
  const classes = useStyles();
  const chartRef = useRef(null);

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    options: {
      legend: {
        display: false,
      },
      layout: {
        padding: 8,
      },
      // legendCallback: legendCallback.bind(this),
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            maxBarThickness: 30,
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
      tooltips: {
        enabled: false,
        custom: customTooltip,
        callbacks: {
          label: customTooltipLabelContent.bind(this, ' (Credit)'),
        },
      },
    },
    data: {
      labels: [],
      datasets: [{ data: [{}], backgroundColor: chartColor1 }],
    },
  });

  useEffect(() => {
    if (loading) return;
    let chartDataCash = [];
    let chartDataCredit = [];
    let chartLabels = [];

    // Translate server's data into chartjs's options
    if (!isEmpty(data) && !isEmpty(data.values)) {
      const { values, access } = data;

      values.forEach(({ label, cash, credit }) => {
        chartDataCash.push(cash.toFixed(2));
        chartDataCredit.push(credit.toFixed(2));
        chartLabels.push(label);
      });

      const datasets = [];
      if (access.sales) {
        datasets.push({ data: chartDataCash, backgroundColor: chartColor1, label: 'Cash' });
      }
      if (access.mro) {
        datasets.push({ data: chartDataCredit, backgroundColor: chartColor2, label: 'Credit' });
      }

      setChartOptions(prevChartOptions => ({
        ...prevChartOptions,
        data: {
          labels: chartLabels,
          datasets,
        },
      }));
      chartRef.current.chartInstance.update();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), loading]);

  if (loading) {
    return <CircularProgress />;
  }

  const { access, summary } = data;
  const totalCash = summary.totalCash;
  const totalCredit = summary.totalCredit;

  return (
    <div className={classes.root}>
      <div className={classes.summary}>
        {access.sales && <LegendSummary color={chartColor1} label="Total (RM)" value={totalCash} />}
        {access.mro && <LegendSummary color={chartColor2} label="Total (Credit)" value={totalCredit} />}
      </div>
      <Bar {...chartOptions} ref={chartRef} height={270} />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
  },
  summary: {
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default TodaysSalesChart;
