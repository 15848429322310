import React, { useEffect } from 'react';
import { Edit, useShowController, LoadingIndicator } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { colors, Tabs, Tab, Divider } from '@material-ui/core';
import { Can, ACCESS } from '@/utils/auth';
import { Page, Button } from '@/components';
import MachineInfo from './MachineInfo';
import MachineForm from './MachineForm';
import SlotList from './SlotList';
import SlotGrid from './SlotGrid';
import BoardTabs from './BoardTabs';
import {
  useGetSlotsRealtime,
  useRefreshMachineStatus,
  checkConnection,
  useGetApkVersion,
  useGetMachineData,
  useGetBoardDataFromMachine,
} from '../hooks';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const MachineEdit = props => {
  const { id } = props;
  const classes = useStyles();
  const showController = useShowController(props);
  const { record } = showController;
  const [tab, setTab] = React.useState(null);
  const handleTabsChange = (event, value) => setTab(value);
  const getSlotsState = useGetSlotsRealtime(id);
  const [refreshing, refresh] = useRefreshMachineStatus(false);
  const machineData = useGetMachineData(id);
  const version = useGetApkVersion(id);
  const showPoke = version >= 100;
  const boardData = useGetBoardDataFromMachine(id);

  useEffect(() => {
    if (boardData.loaded === true) {
      setTab(boardData.boards.length === 0 ? 'slots' : 'boards');
    }
  }, [boardData]);

  if (!machineData.loaded) {
    return null;
  }

  const model = machineData.data.machineModel ? machineData.data.machineModel : null;
  const clientType = machineData.data.clientType ? machineData.data.clientType : 1;

  const SetTabsContainer = tab => {
    if (tab === null) {
      return null;
    }

    return (
      <Tabs value={tab} onChange={handleTabsChange} className={classes.tabs}>
        {SetSlotOrBoard(boardData.boards)}
        <Tab label="Products" value="products" />
        <Tab label="Settings" value="settings" />
      </Tabs>
    );
  };

  const SetSlotOrBoard = boardIDArray => {
    if (boardIDArray.length === 0) {
      return <Tab label="Slots" value="slots" />;
    }

    return <Tab label="Boards" value="boards" />;
  };

  const SetSlotOrBoardContent = boardIDArray => {
    if (boardIDArray.length === 0) {
      return tab === 'slots' && <SlotGrid machineId={id} state={getSlotsState} machineModel={model} />;
    }
    return (
      tab === 'boards' && <BoardTabs machineId={id} state={getSlotsState} machineModel={model} boardData={boardData} clientType={clientType} />
    );
  };

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page
        sectionTitle="Vending Machine"
        title="Edit machine"
        action1={
          <Button label="Refresh status" loading={refreshing} onClick={() => refresh(id, Button)} color="primary" />
        }
      >
        <MachineInfo showController={showController} {...props} />
        {SetTabsContainer(tab)}
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {SetSlotOrBoardContent(boardData.boards)}
          {tab === 'products' && <SlotList machineId={id} state={getSlotsState} machineRecord={record} />}
          {tab === 'settings' && (
            <Edit {...props} undoable={false}>
              <MachineForm />
            </Edit>
          )}
        </div>
      </Page>
    </Can>
  );
};

export default MachineEdit;
