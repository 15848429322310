// TODO: Combine this with resources.js?

export default {
  // Auth
  signIn: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',

  // App
  dashboard: '/',
  profile: '/profile',
  users: '/users',
  products: '/products',
  categories: '/categories',
  machines: '/machines',
  machineSlot: '/machines/:machineId/slots/:slotNumber',
  machineSlotBoard: '/machines/:machineId/:boardID/slots/:slotID',
  lockerSlot: '/machines/:machineId/:boardID/lockers/:slotID',
  doc2usLockerSlot: '/machines/:machineId/:boardID/lockers-doc2us/:slotID',
  transactions: '/transactions',
  mroTransactions: '/mro-transactions',
  mroTransactionsByProduct: '/mro-transactions-by-product',
  mroTransactionsByEmployee: '/mro-transactions-by-employee',
  // remoteControl: '/remote-control',
  // digitalSignage: '/digital-signage',
  employees: '/employees',
  employeeTypes: '/employeeTypes',
  mroTopup: '/employee-topup',

  // userCreate: '/users/create',
  // userEdit: '/users/edit/:userId',

  // mroReportByMonth: '/mro-report-by-month',
  // mroReportByDateRange: '/mro-report-by-date-range',
  // mroReportByProduct: '/mro-report-by-product',
  // mroReportByCategory: '/mro-report-by-category',
  // mroReportByEmployee: '/mro-report-by-employee',
  // salesReport: '/sales-report',

  // vendingMachineStatus: '/vending-machine-status',
  // vendingMachineStock: '/vending-machine-stock',

  // mroSupplier: '/mro-supplier',
  // mroEmployee: '/mro-employee',
  // mroTopUp: '/mro-topup',

  // paymentGatewayIpay88Settings: '/payment-ipay88',
  // paymentGatewayCreditSettings: '/payment-credit',
};
