var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import { useMemo, useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { useGetMany } from '../../dataProvider';
import useGetMatching from '../../dataProvider/useGetMatching';
import { useTranslate } from '../../i18n';
import { getStatusForArrayInput as getDataStatus } from './referenceDataStatus';
/**
 * Prepare data for the ReferenceArrayInput components
 *
 * @example
 *
 * const { choices, error, loaded, loading } = useReferenceArrayInputController({
 *      basePath: 'resource';
 *      record: { referenceIds: ['id1', 'id2']};
 *      reference: 'reference';
 *      resource: 'resource';
 *      source: 'referenceIds';
 * });
 *
 * @param {Object} option
 * @param {boolean} option.allowEmpty do we allow for no referenced record (default to false)
 * @param {string} option.basePath basepath to current resource
 * @param {string | false} option.linkType The type of the link toward the referenced record. edit, show of false for no link (default to edit)
 * @param {Object} option.record The The current resource record
 * @param {string} option.reference The linked resource name
 * @param {string} option.resource The current resource name
 * @param {string} option.source The key of the linked resource identifier
 *
 * @return {Object} controllerProps Fetched data and callbacks for the ReferenceArrayInput components
 */

var useReferenceArrayInputController = function useReferenceArrayInputController(_a) {
  var defaultFilter = _a.filter,
      _b = _a.filterToQuery,
      filterToQuery = _b === void 0 ? defaultFilterToQuery : _b,
      input = _a.input,
      _c = _a.perPage,
      perPage = _c === void 0 ? 25 : _c,
      _d = _a.sort,
      defaultSort = _d === void 0 ? {
    field: 'id',
    order: 'DESC'
  } : _d,
      options = _a.options,
      reference = _a.reference,
      resource = _a.resource,
      source = _a.source;
  var translate = useTranslate(); // We store the current input value in a ref so that we are able to fetch
  // only the missing references when the input value changes

  var inputValue = useRef(input.value);

  var _e = useState(input.value),
      idsToFetch = _e[0],
      setIdsToFetch = _e[1];

  useEffect(function () {
    var newIdsToFetch = difference(input.value, inputValue.current);

    if (newIdsToFetch.length > 0) {
      setIdsToFetch(newIdsToFetch);
    }

    inputValue.current = input.value;
  }, [input.value, setIdsToFetch]);

  var _f = useState({
    page: 1,
    perPage: perPage
  }),
      pagination = _f[0],
      setPagination = _f[1];

  var _g = useState(defaultSort),
      sort = _g[0],
      setSort = _g[1];

  var _h = useState(''),
      filter = _h[0],
      setFilter = _h[1]; // Ensure sort can be updated through props too, not just by using the setSort function


  useEffect(function () {
    if (!isEqual(defaultSort, sort)) {
      setSort(defaultSort);
    }
  }, [setSort, defaultSort, sort]); // Ensure pagination can be updated through props too, not just by using the setPagination function

  useEffect(function () {
    var newPagination = {
      page: 1,
      perPage: perPage
    };

    if (!isEqual(newPagination, pagination)) {
      setPagination(newPagination);
    }
  }, [setPagination, perPage, pagination]); // Merge the user filters with the default ones

  var finalFilter = useMemo(function () {
    return __assign(__assign({}, defaultFilter), filterToQuery(filter));
  }, [defaultFilter, filter, filterToQuery]);

  var _j = useGetMany(reference, idsToFetch || []),
      referenceRecords = _j.data,
      loaded = _j.loaded;

  var matchingReferences = useGetMatching(reference, pagination, sort, finalFilter, source, resource, options).data; // filter out not found references - happens when the dataProvider doesn't guarantee referential integrity

  var finalReferenceRecords = referenceRecords ? referenceRecords.filter(Boolean) : []; // We merge the currently selected records with the matching ones, otherwise
  // the component displaying the currently selected records may fail

  var finalMatchingReferences = matchingReferences && matchingReferences.length > 0 ? mergeReferences(matchingReferences, finalReferenceRecords) : finalReferenceRecords.length > 0 ? finalReferenceRecords : matchingReferences;
  var dataStatus = getDataStatus({
    input: input,
    matchingReferences: finalMatchingReferences,
    referenceRecords: finalReferenceRecords,
    translate: translate
  });
  return {
    choices: dataStatus.choices,
    error: dataStatus.error,
    loaded: loaded,
    loading: dataStatus.waiting,
    setFilter: setFilter,
    setPagination: setPagination,
    setSort: setSort,
    warning: dataStatus.warning
  };
}; // concatenate and deduplicate two lists of records


var mergeReferences = function mergeReferences(ref1, ref2) {
  var res = __spreadArrays(ref1);

  var ids = ref1.map(function (ref) {
    return ref.id;
  });
  ref2.forEach(function (ref) {
    if (!ids.includes(ref.id)) {
      ids.push(ref.id);
      res.push(ref);
    }
  });
  return res;
};

export default useReferenceArrayInputController;

var defaultFilterToQuery = function defaultFilterToQuery(searchText) {
  return {
    q: searchText
  };
};