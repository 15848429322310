import React from 'react';
import { useGetProductThroughSKU } from '@/pages/machines/hooks';

const ProductList = ({ skuList }) => {
  const productListRetrieved = useGetProductThroughSKU(skuList);

  if (!productListRetrieved.loaded) {
    return null;
  }

  const formatToString = () => {
    let productsNameArray = [];

    for (let i = 0; i < productListRetrieved.productList.length; i++) {
      productsNameArray.push(productListRetrieved.productList[i].data.name);
    }
    return productsNameArray.join(',');
  };

  return <div>{formatToString()}</div>;
};

export default ProductList;
