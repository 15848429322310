import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { get, isEmpty } from 'lodash';
import { TRANSACTION } from '@/vars';

const useStyles = makeStyles({
  dialog: {
    maxWidth: '100%',
    marginStart: 'auto',
    marginEnd: 'auto'
  },
  table: {
    maxWidth: '100%'
  },
  keyCell: {
    minWidth: '200px',
    maxWidth: '200px'
  },
  valueCell: {
    minWidth: '250px',
    maxWidth: '250px'
  }
});

const styles = theme => ({
  dialog: {
    maxWidth: '100%',
    marginStart: 'auto',
    marginEnd: 'auto'
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4" className={classes.root}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

// check the status
const checkStatus = v => {
  const { status } = v;
  switch (status) {
    case -3:
      return (
        <TableCell align="right" style={{ color: '#FF6562' }} colSpan="3">
          Void
        </TableCell>
      );
    case -2:
      return (
        <TableCell align="right" style={{ color: '#FF6562' }} colSpan="3">
          Dropped
        </TableCell>
      );
    case -1:
      return (
        <TableCell align="right" style={{ color: '#C0C0C0' }} colSpan="3">
          Canceled
        </TableCell>
      );
    case 0:
      return (
        <TableCell align="right" style={{ color: '#FF6562' }} colSpan="3">
          Failed
        </TableCell>
      );
    case 1:
      return (
        <TableCell align="right" style={{ color: '#00C07F' }} colSpan="3">
          Successful
        </TableCell>
      );
  }
};

const checkPaymentType = record => {
  if (get(record, 'payment.gatewayID')) {
    return TRANSACTION.GATEWAY[`${get(record, 'payment.gatewayID')}`];
  }
  if (get(record, 'payment.terminal')) {
    return 'Paywave';
  }
  if (get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.productCode')) {
    return get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.productCode');
  }
  return null;
};

const getTransactionID = record => {
  if (get(record, 'payment.transactionId')) {
    return get(record, 'payment.transactionId');
  }

  if (get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.txnRef')) {
    return get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.txnRef');
  }
  return null;
};

const getReferenceNo = record => {
  if (get(record, 'payment.refNo')) {
    return get(record, 'payment.refNo');
  }

  if (get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.retTxnRef')) {
    return get(record, 'payment.ghlPaymentDetail.paymentResponse.msg.retTxnRef');
  }
  return null;
};

const getErrorCategoryMessage = record => {
  const error_list = [
    {
      category: 'Hardware',
      key: ['sensor got blocked'],
      message: 'sensor got blocked',
    },
    {
      category: 'Hardware',
      key: ['driver board went wrong'],
      message: 'Item Not Detect Dropped',
    },
    {
      category: 'Hardware',
      key: ['data does noe have 30 count'],
      message: 'Driver board communication went wrong',
    },
    {
      category: 'System',
      key: ['signature not match'],
      message: 'Signature not match',
    },
    {
      category: 'Customer',
      key: [
        'balance is not enough',
        'insufficient fund',
        'insufficient balance',
        'balance not sufficient',
        'USER NOT ENOUGH BALANCE',
      ],
      message: "Customer's e-wallet insufficient balance",
    },
    {
      category: 'Customer',
      key: [
        '40913:consumer presented code is expired',
        '40912:consumer presented code is invalid',
        'Payment code not found/expired',
        'Incorrect Barcode',
        'CODE IS SCANNED BEFORE',
        'Invalid / Expired Customer Token',
        'Payment code used/expired',
        'Invalid Barcode/QR',
        'auth code already used',
        'auth code illegal',
        'Awaiting user to pay'
      ],
      message: "Customer's presented code was invalid",
    },
    {
      category: 'Customer',
      key: ['Customer account is disabled by Alipay'],
      message: 'Customer account is diabled by Alipay ',
    },
    {
      category: 'Merchant / Ipay',
      key: [
        'Payment not allowed',
        'Payment Fail',
        'Payment Fail (PAYMENT_FAIL)',
        'Payment fail (00000000)',
        'Payment fail ()',
        'Payment Fail (INVALID_QR_CODE)',
        'Payment Fail (REQUEST_FORMAT_ERROR)',
        'Payment Fail (No Response - Payment)',
        'Payment Fail (PENDING_CUST_ACTION)',
        'Payment fail (20)',
        'Payment fail (57)',
        'Payment Fail (QRC_EXPIRED)',
        'The barcode or QR code scanned by cashiers is not the one',
        'Payment Fail (KEY_EXPIRED)',
        'Payment fail (NOT_SUPPORT_PAYMENT_INST)',
      ],
      message: 'Payment was not allowed by payment gateway',
    },
    {
      category: 'System',
      key: [
        'UnknownHostException',
        'SocketTimeoutException',
        "Element 'head' does not have a match in class com.vechnology.ve.models.payment.data.ResponseEnvelope",
        'unexpected end of stream on Connectunexpected end of stream on Connection',
        'ConnectException',
        'SSLHandshakeException',
        'Internal Connection Timeout'
      ],
      message: 'Network connection issue',
    },
    {
      category: 'Merchant / Ipay',
      key: [
        '5001:server_error',
        'Response result is null',
        'Product toggle is disabled for merchant',
        'there is risk for payment request',
        'System error',
        'A server error occurred',
        'Payment method not supported',
        'System interruption',
        '40983:amount exceeds periodically daily limit of MYR',
        'channel not enable'
      ],
      message: "Payment's gateway error",
    },
  ];
  var errorDescription = get(record, 'error.description');

  if (errorDescription == null) {
    return [null, null];
  }
  for (const error of error_list) {
    for (const key of error.key) {
      if (errorDescription.toLowerCase().includes(key.toLowerCase())) {
        if (key.toLowerCase() == 'driver board went wrong') {
          try {
            const driverBoardResponse = get(record, 'responseStatus');
            const { globalDroppedSensor, itemDroppedSensor, motorTurned, itemDropped } = driverBoardResponse;
            if (!globalDroppedSensor && !itemDroppedSensor) {
              return [error.category, `${error.message}--sensor off`];
            }
            if (!motorTurned) {
              return [error.category, `${error.message}--motor didn't spin`];
            }
            if (!itemDropped) {
              return [error.category, `${error.message}--sensor didn't detect drop`];
            }
          } catch (ex) {
            return [null, null];
          }
        }
        return [error.category, error.message];
      }
    }
  }
  return [null, null];
};

const getErrorDetails = value => {
  var errorDescription = get(value, 'error.description');
  errorDescription = `${errorDescription.replaceAll('com.vechnology.ve.models.payment.', '*').replaceAll('com.vechnology.ve.models.serial.driverBoard.', '*').replaceAll('com.vechnology.ve', '*').substring(0, 200)}...`
  return errorDescription
}

const getTime = (dateTime, type) => {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Jun',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  if (dateTime != null) {
    if (type == 'date') {
      const date = dateTime.getDate() + ' ' + months[dateTime.getMonth()] + ' ' + dateTime.getFullYear();
      return date;
    }
    if (type == 'time') {
      const time = dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds();
      return time;
    }
  } else return 'N/A';
};

const getCashType = (cashTypeObject, transType) => {
  const receivedArray = ['0.1', '0.2', '0.5', '1.0', '5.0', '10.0'];
  const returnedArray = ['0.1', '0.2', '0.5'];

  var resultArray = [];

  if (cashTypeObject == null) {
    return;
  }

  switch (transType) {
    case 'received':
      for (var i = 0; i < receivedArray.length; i++) {
        const cashTypeValue = get(cashTypeObject, receivedArray[i]);

        if (cashTypeValue != null && cashTypeValue != 0) {
          resultArray.push(`${cashTypeValue}`);
        } else {
          resultArray.push(`0`);
        }
      }
      return resultArray;
    case 'returned':
      for (var i = 0; i < returnedArray.length; i++) {
        const cashTypeValue = get(cashTypeObject, returnedArray[i]);

        if (cashTypeValue != null && cashTypeValue != 0) {
          resultArray.push(`${cashTypeValue}`);
        } else {
          resultArray.push(`0`);
        }
      }
      return resultArray;
  }
};

const getProductSlot = record => {
  var rowArray = ['A', 'B', 'C', 'D', 'E', 'F'];
  if (record == null) return 'N/A';
  var row = Math.floor(record / 12);
  var column = record % 12;
  return `${rowArray[row]}${column}`;
};

const checkNull = value => {
  if (value == null) return 'N/A';
  return value;
};

const OpenCash = value => {
  const cashTypeArray = ['10 cent', '20 cent', '50 cent', '1 ringgit', '5 ringgit', '10 ringgit'];
  var totalCashReceived = get(value, 'cashInDetails.cashInTotal') ? get(value, 'cashInDetails.cashInTotal') : 0;
  var totalCashReturned = get(value, 'cashOutDetails.cashOutTotal') ? get(value, 'cashOutDetails.cashOutTotal') : 0;
  var balance = (totalCashReceived - totalCashReturned).toFixed(1);
  var totalCashTypeReceived = getCashType(get(value, 'cashInDetails.cashInTypeAndCount'), 'received');
  var totalCashTypeReturned = getCashType(get(value, 'cashOutDetails.cashOutTypeAndCount'), 'returned');

  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan="1">
          <Typography variant="h5">Payment Details</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Payment Type</TableCell>
        <TableCell align="right" colSpan="3">
          Cash
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          align="left"
          style={{ verticalAlign: 'top' }}
          rowSpan={totalCashTypeReceived ? totalCashTypeReceived.length + 1 : 2}
        >
          Coin Type Received:
        </TableCell>
      </TableRow>
      {totalCashTypeReceived ? (
        totalCashTypeReceived.map((eachType, index) => (
          <TableRow>
            <TableCell align="right">{cashTypeArray[index]}</TableCell>
            <TableCell align="right">=</TableCell>
            <TableCell align="right">{eachType}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan="3" align="right">
            N/A
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {/* <TableCell align="right" style={{ fontWeight: 600 }}>
          Total:{' '}
        </TableCell> */}
        <TableCell></TableCell>
        <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
          Total:
        </TableCell>
        {totalCashTypeReceived ? (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            RM {totalCashReceived}
          </TableCell>
        ) : (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            N/A
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell
          align="left"
          style={{ verticalAlign: 'top' }}
          rowSpan={totalCashTypeReturned ? totalCashTypeReturned.length + 1 : 2}
        >
          Coin Type Returned:
        </TableCell>
      </TableRow>
      {totalCashTypeReturned ? (
        totalCashTypeReturned.map((eachType, index) => (
          <TableRow>
            <TableCell align="right">{cashTypeArray[index]}</TableCell>
            <TableCell align="right">=</TableCell>
            <TableCell align="right">{eachType}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan="3" align="right">
            N/A
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell></TableCell>
        <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
          Total:
        </TableCell>
        {totalCashTypeReturned ? (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            RM {totalCashReturned}
          </TableCell>
        ) : (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            N/A
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell></TableCell>
        <TableCell align="right" style={{ fontWeight: 600 }} colSpan="2">
          Balance:
        </TableCell>
        {totalCashTypeReceived && totalCashTypeReturned ? (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            RM {balance}
          </TableCell>
        ) : (
          <TableCell align="right" style={{ fontWeight: 600 }}>
            N/A
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

const OpenCashless = value => {
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan="1">
          <Typography variant="h5">Payment Details</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Payment Type:</TableCell>
        <TableCell align="right">{checkNull(checkPaymentType(value))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Transaction ID:</TableCell>
        <TableCell align="right">{checkNull(getTransactionID(value))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Ref.No:</TableCell>
        <TableCell align="right">{checkNull(getReferenceNo(value))}</TableCell>
      </TableRow>
    </>
  );
};

const OpenFailDetails = (value, classes) => {
  const [category, message] = getErrorCategoryMessage(value);

  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan="1">
          <Typography variant="h5">Error Detail</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Category:</TableCell>
        <TableCell align="right" colSpan="3">
          {checkNull(category)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left">Message:</TableCell>
        <TableCell align="right" colSpan="3">
          {checkNull(message)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" className={classes.keyCell}>Description:</TableCell>
        <TableCell align="right" className={classes.valueCell} colSpan="3">
          {checkNull(getErrorDetails(value))}
        </TableCell>
      </TableRow>
    </>
  );
};

export default function CustomizedDialogs(record) {
  const value = record;
  const [open, setOpen] = React.useState(false);

  const transID = get(value, 'value.payment.transactionId');
  const isCash = get(value, 'value.payment.cash');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        &gt;
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={classes.root}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Id: {get(value, 'id')}
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table}>
            <TableBody colSpan="2">
              <TableRow>
                <TableCell align="left" className={classes.keyCell}colSpan="1">
                  <Typography variant="h5">Transaction Details</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Machine UUID: </TableCell>
                <TableCell align="right" colSpan="3">
                  {checkNull(get(value, 'machine.machineUUID'))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Date: </TableCell>
                <TableCell align="right" colSpan="3">
                  {checkNull(getTime(get(value, 'date'), 'date'))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Time: </TableCell>
                <TableCell align="right" colSpan="3">
                  {checkNull(getTime(get(value, 'date'), 'time'))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Product Name: </TableCell>
                <TableCell align="right" colSpan="3">
                  {checkNull(get(value, 'product.name'))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Product Slot: </TableCell>
                <TableCell align="right" colSpan="3">
                  {checkNull(getProductSlot(get(value, 'product.slot')))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Product Price: </TableCell>
                <TableCell align="right" colSpan="3">
                  RM {checkNull(get(value, 'amount'))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Status: </TableCell>
                {checkStatus(value)}
              </TableRow>
              {get(value, 'status') == 1 ? <></> : <OpenFailDetails classes={classes} {...value} />}
              {get(value, 'payment.cash') ? <OpenCash {...value} /> : <OpenCashless {...value} />}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
}
