import React, { useState, useRef } from 'react';
import { Datagrid, TextField, FunctionField, NumberField, Button, useNotify } from 'react-admin';
import { Card, TextField as MuiTextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { get } from 'lodash';
import { GroupField } from '@/ra';
import { MACHINE } from '@/vars';
import { db } from '@/vars/firebase';
import SlotStatusField from '../SlotStatusField';
import { useSaveSlot } from '../hooks';

const useStyles = makeStyles(theme => ({
  input: {
    padding: '5px 8px',
    width: 50,
  },
  topbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
}));

const getSlotNum = machineSlot => {
  const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const GRID_COL_NUM = 12;
  if (machineSlot % GRID_COL_NUM == 0) return `${ALPHABET[(machineSlot / GRID_COL_NUM) - 1]}12`
  return `${ALPHABET[parseInt(machineSlot / GRID_COL_NUM)]}${machineSlot % GRID_COL_NUM}`;
};

const useQuantityUpdate = (machineId, machineSlot) => {
  const slotNum = getSlotNum(machineSlot);
  const { save, saving } = useSaveSlot();
  const notify = useNotify();

  const update = value => {
    if (/\d/.test(value)) {
      save(machineId, {
        machineSlot,
        totalQuantity: parseInt(value),
      }).catch(error => {
        notify(`Unable to update ${slotNum}'s quantity`, 'error');
      });
    }
  };

  return {
    update,
    updating: saving,
  };
};

const UpdateQtyButton = ({ record = {}, machineId }) => {
  const { maxCapacity, machineSlot } = record;
  const classes = useStyles();
  const inputEl = useRef(null);
  const { update, updating } = useQuantityUpdate(machineId, machineSlot);
  const [showInput, setShowInput] = useState(false);
  const [inputError, setInputError] = useState('');
  const toggleInput = () => {
    setShowInput(bool => !bool);
    setInputError('');
  };
  const onSave = e => {
    e.preventDefault();
    const inputVal = inputEl.current.value;
    if (inputVal < 0 || inputVal > maxCapacity) {
      setInputError('Invalid value');
      return;
    }

    toggleInput();
    update(inputVal);
  };

  if (showInput) {
    return (
      <form onSubmit={onSave}>
        <MuiTextField
          type="number"
          variant="outlined"
          InputProps={{ classes: { input: classes.input } }}
          inputProps={{
            min: 0,
            max: maxCapacity,
          }}
          inputRef={inputEl}
          error={!!inputError.length}
          helperText={inputError}
        />{' '}
        <IconButton type="submit" variant="outlined" size="small">
          <DoneIcon style={{ color: green[600] }} />
        </IconButton>
        <IconButton onClick={toggleInput} variant="outlined" size="small">
          <CloseIcon color="error" />
        </IconButton>
      </form>
    );
  } else {
    return <Button label="Update QTY" onClick={toggleInput} disabled={updating} />;
  }
};

const RefillAllButton = ({ record = {}, machineId }) => {
  const { maxCapacity, machineSlot } = record;
  const { update, updating } = useQuantityUpdate(machineId, machineSlot);
  return <Button onClick={() => update(maxCapacity)} label="Refill all" disabled={updating} />;
};

const RefillAllSlotsButton = ({ data = [], machineId }) => {
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const refillAll = async () => {
    try {
      const batch = db.batch();
      Object.entries(data).forEach(([slotId, slotData]) => {
        const { totalQuantity, maxCapacity } = slotData;
        if (totalQuantity < maxCapacity) {
          const slotRef = db.doc(`machines/${machineId}/slots/${slotId}`);
          batch.update(slotRef, { totalQuantity: maxCapacity });
        }
      });
      setSaving(true);
      await batch.commit();
      setSaving(false);
    } catch (error) {
      notify('Refill error, please try again later');
    }
  };
  return <Button onClick={refillAll} label="Refill all slots" disabled={saving} />;
};

const SlotList = ({ state, machineId, machineRecord }) => {
  const { data, loaded } = state;
  const classes = useStyles();

  const machineMode = get(machineRecord, 'machineMode', MACHINE.MODE.COMMERCIAL);

  return (
    <>
      <div className={classes.topbar}>
        <span />
        <RefillAllSlotsButton data={data} machineId={machineId} />
      </div>
      <Card>
        <Datagrid
          data={data || {}}
          loaded={loaded}
          hasBulkActions={false}
          ids={data ? Object.keys(data) : []}
          currentSort={{ field: 'id' }}
        >
          <FunctionField label="Slot no." render={record => getSlotNum(record.machineSlot)} />
          <TextField label="Item no." source="itemNumber" sortable={false} />
          <TextField label="Product name" source="product.name" sortable={false} />
          {machineMode === MACHINE.MODE.MRO ? (
            <NumberField source="product.priceCredit" label="Price (Credit)" sortable={false} />
          ) : (
            <NumberField
              source="product.priceCash"
              label="Price (RM)"
              locales="en-MY"
              options={{ style: 'currency', currency: 'MYR' }}
              sortable={false}
            />
          )}
          <FunctionField label="QTY" render={record => `${record.totalQuantity}/${record.maxCapacity}`} />
          <SlotStatusField label="Status" />
          <GroupField textAlign="right">
            <UpdateQtyButton machineId={machineId} />
            <RefillAllButton machineId={machineId} />
          </GroupField>
        </Datagrid>
      </Card>
    </>
  );
};

export default SlotList;
