import React from 'react';
import { Link, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '@/assets/img/logo_vechnology.png';

function Copyright() {
  const classes = useStyles();

  return (
    <Box color="text.secondary" textAlign="center" p={2} className={classes.root}>
      <div>Powered By</div>
      <Link href="https://vechnology.com.my/" target="_blank" className={classes.logoLink}>
        <img className={classes.logo} src={logo} alt="Vechnology" draggable={false} />
      </Link>
      <Grid container justify="center" spacing={3} className={classes.links}>
        <Grid item>
          <Link color="inherit" href="#">
            Privacy Policy
          </Link>
        </Grid>
        <Grid item>
          <Link color="inherit" href="#">
            Terms of Services
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '.75rem',
  },
  logoLink: {
    display: 'inline-block',
    margin: theme.spacing(1, 0),
  },
  logo: {
    width: 126,
  },
  links: {
    '& a': {
      color: '#666',
      fontSize: '1.1em',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: 'auto',
      right: theme.spacing(3),
      bottom: theme.spacing(3),
    },
  },
}));

export default Copyright;
