import React from 'react';
import { useHistory } from 'react-router';
import Button from './Button';

const BackButton = props => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <Button onClick={goBack} {...props}>
      Back
    </Button>
  );
};

export default BackButton;
