import React, { useState } from 'react';
import { TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useAuthProvider } from 'react-admin';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import routes from '@/vars/routes';
import AuthLayout from '@/layouts/AuthLayout';
import Button from '@/components/Button';
import AuthAlertDialog from '@/dialogs/AuthErrorDialog'; // Adjust the import path as necessary
import { updateRetryAttempt, userAuthCheck, removeAllRetryAttempt } from '../../utils/auth';

const SUSPENDED_MESSAGE =
  'Your account is currently blocked due to overdue payment(s). Kindly reach out to us once the payment is settled. Thank you.';
const REACH_MAX_LOGIN_ATTEMPTS_MESSAGE =
  'You current account has been suspended, please reset your password to unlock.';

const SignIn = ({ location }) => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setError } = useForm();
  const { login } = useAuthProvider();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const authentication = async values => {
    const email = values.email;
    // check through the retry attempts]
    const [id, attempts, isSuspended] = await userAuthCheck(email);
    const noAttemptsLeft = attempts >= 5;
    if (noAttemptsLeft) {
      setErrorDialogOpen(true);
      setAuthErrorMessage(REACH_MAX_LOGIN_ATTEMPTS_MESSAGE);
      return;
    }

    if (isSuspended) {
      setErrorDialogOpen(true);
      setAuthErrorMessage(SUSPENDED_MESSAGE);
      return;
    }

    login({ username: email, ...values })
      .then(result => { 
        setLoading(false);
        removeAllRetryAttempt(id);
        history.push('/');
      })
      .catch(exception => {
        setLoading(false);
        const { code, message } = exception;
        switch (code) {
          case 'auth/invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            return setError('email', code, message);
          case 'auth/wrong-password':
            // handle the retry attempts logic here.
            updateRetryAttempt(id, attempts);
            const retryAttemptsLeft = 5 - (attempts + 1);
            return setError('password', code, `Wrong Password. You only have ${retryAttemptsLeft} attempt(s) left.`);
          default:
            setError('email', code, message);
        }
      });
    return;
  };
  
  const onSubmit = async values => {
    try {
      setLoading(true);
      await authentication(values);
    } catch (error) {
      setLoading(false);
      setError('email', null, error.message);
    }
  };

  const params = new URLSearchParams(location.search);
  const subHeading =
    params.get('src') === 'resetPassword'
      ? 'Please sign in again with your new password.'
      : 'Please sign in to your account.';
  const defaultEmail = params.get('e');

  return (
    <AuthLayout heading="Welcome Back!" subHeading={subHeading}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate method="POST">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Email Address"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          inputRef={register({
            required: 'Please enter a valid email.',
          })}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email.message}
          defaultValue={defaultEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Password"
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          inputRef={register({
            required: 'Please enter the password.',
          })}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loading={loading}
        >
          Sign In
        </Button>
        <Box display="flex" flexDirection="row-reverse">
          <Link to={routes.forgotPassword}>Forgot password?</Link>
        </Box>
      </form>
      <AuthAlertDialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
          setLoading(false)
        }}
        message={authErrorMessage}
      />
    </AuthLayout>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default SignIn;
