var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { forwardRef, cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
var NavLinkRef = React.forwardRef(function (props, ref) {
  return React.createElement(NavLink, __assign({
    innerRef: ref
  }, props));
});
var useStyles = makeStyles(function (theme) {
  return {
    root: {
      color: theme.palette.text.secondary
    },
    active: {
      color: theme.palette.text.primary
    },
    icon: {
      minWidth: theme.spacing(5)
    }
  };
});
var MenuItemLink = forwardRef(function (_a, ref) {
  var classesOverride = _a.classes,
      className = _a.className,
      primaryText = _a.primaryText,
      leftIcon = _a.leftIcon,
      onClick = _a.onClick,
      sidebarIsOpen = _a.sidebarIsOpen,
      props = __rest(_a, ["classes", "className", "primaryText", "leftIcon", "onClick", "sidebarIsOpen"]);

  var classes = useStyles({
    classes: classesOverride
  });
  var handleMenuTap = useCallback(function (e) {
    onClick && onClick(e);
  }, [onClick]);

  var renderMenuItem = function renderMenuItem() {
    return React.createElement(MenuItem, __assign({
      className: classnames(classes.root, className),
      activeClassName: classes.active,
      component: NavLinkRef,
      ref: ref
    }, props, {
      onClick: handleMenuTap
    }), leftIcon && React.createElement(ListItemIcon, {
      className: classes.icon
    }, cloneElement(leftIcon, {
      titleAccess: primaryText
    })), primaryText);
  };

  if (sidebarIsOpen) {
    return renderMenuItem();
  }

  return React.createElement(Tooltip, {
    title: primaryText,
    placement: "right"
  }, renderMenuItem());
});
MenuItemLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  staticContext: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};
export default MenuItemLink;