import React from 'react';
import firebase from 'firebase/app';
import { downloadCSV, useNotify } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import { isEmpty } from 'lodash';
import { db } from '@/vars/firebase';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import jsonExport from 'jsonexport/dist';
import ErrorDialog from './ErrorDialog';
import { useAuth } from '@/utils/auth';
import { checkField, randomIdMaker, convert2string, csv2json, getCompanyId } from '@/utils/validateCSVFunctions';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    maxWidth: '500',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const styleDropzone = {
  height: '300px',
  width: '500px',
  'background-color': '#e0e0e0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ classes }) {
  const [op, setOpen] = React.useState(false);
  var [alertMessage, setMessage] = React.useState('');
  const { permissions } = useAuth();
  const clientId = permissions.user_id;
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    acceptedFiles.length = 0;
    files.length = 0;
    setOpen(false);
    setMessage((alertMessage = ''));
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  function handleUploadEvent() {
    if (acceptedFiles.length > 1) {
      alert('only can upload one document at one time');
      return;
    } else if (acceptedFiles.length === 0) {
      alert('please upload your employee csv file first');
      return;
    } else {
      let tempField = [];
      let tempResult = [];

      const array = acceptedFiles[0].name.split('.');
      if (array[array.length - 1] !== 'csv') {
        alert('Sorry! We only accept csv format.');
        return;
      }

      //get the data from the file
      Papa.parse(acceptedFiles[0], {
        complete: async function(results) {
          //seperate the data field and the data
          for (let i = 0; i < results.data.length; i++) {
            if (i === 0) {
              tempField.push(results.data[i]);
            } else {
              tempResult.push(results.data[i]);
            }
          }

          let consoleError = [];
          const tempErrorList = checkField(tempField, consoleError);

          //check for the card number duplicated problem.
          // ** maybe later can state out which row got duplicated **
          for (let i = 0; i < tempResult.length; i++) {
            for (let j = i + 1; j < tempResult.length; j++) {
              if (tempResult[i][2] === tempResult[j][2]) {
                tempErrorList.push('row ' + (i + 2) + ' and row ' + (j + 2) + ': cardNumber duplicated');
              }
            }
          }

          const [jsonObjectId, jsonObjectArray, errorList] = await csv2json(clientId, tempResult, tempErrorList);

          // if the consoleError no error then run update to firestore
          if (isEmpty(errorList)) {
            Object.keys(jsonObjectId, jsonObjectArray).forEach(async (id, index) => {
              const employeeId = jsonObjectId[id];
              const employee = jsonObjectArray[index];
              if (employeeId) {
                try {
                  //if the id had been altered, then update fail.
                  db.collection('employees')
                    .doc(employeeId)
                    .update(employee);
                  console.log('Update successful.');
                } catch (error) {
                  notify('update unsuccessfully. Please refresh. ');
                }
              } else {
                try {
                  const employeeIdGenerated = randomIdMaker();
                  employee.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                  db.collection('employees')
                    .doc(employeeIdGenerated)
                    .set(employee);
                  console.log('add successful.');
                } catch (error) {
                  notify('Add unsuccessfully. Please refresh. ');
                }
              }
            });

            setMessage((alertMessage = convert2string(consoleError)));
            acceptedFiles.length = 0;
            files.length = 0;
            notify('Add/Update successfully. Please refresh. ');
          } else {
            setMessage((alertMessage = convert2string(errorList)));
            acceptedFiles.length = 0;
            files.length = 0;
          }
        },
      });
    }
    handleClose();
  }

  const exporter = () => {
    const data = [
      {
        id: '',
        fullName: '',
        cardNumber: '',
        employeeNumber: '',
        credit: '',
        recurringCredit: '',
        'type.id': '',
        'type.name': '',
        email: '',
        mobile: '',
        isActive: '',
        createdAt: '',
        updatedAt: '',
        recurringCreditType: '',
        costCenter: ''
      },
    ];
    jsonExport(data, {}, (err, csv) => {
      downloadCSV(csv, 'employees');
    });
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} startIcon={<PublishIcon />}>
        IMPORT
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={op}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant={'h4'}>
            Please Upload Your Excel File Here. <br />
          </Typography>
          <Typography variant={'body1'}>
            Rules: <br />
            1. Follow the format to <b>add</b> new employees. &nbsp;
            <u>
              <a onClick={exporter} style={{ color: 'blue' }}>
                Click here to get the excel format
              </a>
            </u>
            <br />
            2. Press the <b>export</b> button to get latest employees information for <b>update</b>
            <br />
            3. <b>type.id, type.name</b> can refer to Type Lists <br />
            4. Required Field: <b>cardNumber</b>, <b>fullName</b>, <b>credit</b>, <b>type.id</b>, and <b>type.name</b>
            <br />
            5. Auto Generated Field: <b>id</b>, <b>isActive</b>, <b>createdAt</b> and <b>updatedAt</b>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            <div className={`p-6 my-2 mx-auto max-w-md border-2`}>
              <div {...getRootProps({ className: 'dropzone' })} style={styleDropzone}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a csv file here </p>
                <br />
                <br />
                <button type="button" onClick={open}>
                  Open File Dialog
                </button>
              </div>
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleUploadEvent} autoFocus color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage !== '' ? <ErrorDialog value={alertMessage} /> : null}
    </div>
  );
}
