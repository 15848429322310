import React from 'react';
import { Create, Edit } from 'react-admin';
import { Can, ACCESS } from '@/utils/auth';
import { colors, Tabs, Tab, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from '@/components';
import EmployeeList from './EmployeeList';
import EmployeeForm from './EmployeeForm';
import EmployeeTransactionList from './EmployeeTransactionsList';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
}));

const EmployeeCreate = props => (
  <Can module={ACCESS.MRO}>
    <Page sectionTitle="MRO" title="Create employee">
      <Create {...props} redirect="list">
        <EmployeeForm />
      </Create>
    </Page>
  </Can>
);

const EmployeeEdit = props => {
  const classes = useStyles();
  const [tab, setTab] = React.useState('details');
  const handleTabsChange = (event, value) => setTab(value);

  return (
    <Can module={ACCESS.MRO}>
      <Page sectionTitle="MRO" title="Edit employee">
        <Tabs value={tab} onChange={handleTabsChange} className={classes.tabs}>
          <Tab label="Details" value="details" />
          <Tab label="History" value="history" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === 'details' && (
            <Edit {...props} redirect="list">
              <EmployeeForm />
            </Edit>
          )}
          {tab === 'history' && <EmployeeTransactionList {...props} />}
        </div>
      </Page>
    </Can>
  );
};

export { EmployeeList, EmployeeCreate, EmployeeEdit };
