import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Page = ({ title, sectionTitle, action1, action2, children }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          {sectionTitle && <Typography component="h2" gutterBottom variant="overline" children={sectionTitle} />}
          {title ? (
            <Typography component="h1" variant="h1" children={title} className={classes.title} />
          ) : (
            <Typography component="h1" variant="h1" id="react-admin-title" className={classes.title} />
          )}
        </div>
        {action1 && <div className={classes.hearRight}>{action1}</div>}
        {action2 && <div className={classes.borderOnly}>{action2}</div>}
      </div>
      {children}
    </div>
  );
};

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  hearRight: {
    marginLeft: 'auto',
    margin: '0 5px',
    border: '1px solid rgba(50, 115, 220)',
    borderRadius: '4px 4px 4px 4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 1px 1px #9E9E9E',
  },
  borderOnly:{
    border: '1px solid rgba(50, 115, 220)',
    borderRadius: '4px 4px 4px 4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 1px 1px #9E9E9E',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

export default Page;
