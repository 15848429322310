import React from 'react';
import { Grid, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import { ReferenceInput, useGetOne, BooleanInput, useRedirect, required } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { get } from 'lodash';

import { Can, ACCESS } from '@/utils/auth';
import { MACHINES } from '@/vars/resources';
import routes from '@/vars/routes';
import { Page, Button, BackButton, DeleteButton } from '@/components';
import { NumberInput, AutocompleteInput, TextInput } from '@/ra/input';
import ProductForm from '@/pages/machines/SlotEdit/ProductForm';
import ProductList from '@/pages/machines/LockerDoc2Us/doc2usProductList';
import { useGetSlot, useGetDoc2UsLockerSlot, useSaveSlotDoc2Us, useRemoveLockerSlotInformationDoc2Us } from '../hooks';

const Doc2UsLockerEdit = props => {
  const { match } = props;
  const { machineId, boardID, slotID } = match.params;
  const { data: machineRecord, loaded: machineLoaded } = useGetOne(MACHINES, machineId);
  const { data: slotRecord, loaded: slotLoaded } = useGetSlot(machineId, slotID);
  const { saving, save } = useSaveSlotDoc2Us();
  const { removing, remove } = useRemoveLockerSlotInformationDoc2Us(machineId, slotID);
  const redirect = useRedirect();
  const isCreate = slotRecord ? false : true;
  const { data: lockerData, id: lockerID, loaded: lockerSlotLoaded } = useGetDoc2UsLockerSlot(machineId, slotID);

  const onSubmit = async formData => {
    let dataToSave = null;

    if (isCreate) {
      let formDataKeys = Object.keys(formData);
      dataToSave = lockerData;

      formDataKeys.forEach(key => {
        dataToSave[key] = formData[key];
      });

      dataToSave['machineSlot'] = parseInt(slotID);
    } else {
      dataToSave = formData;
    }

    await save(machineId, dataToSave, lockerID);
    redirect(routes.machines + '/' + machineId);
  };

  const onRemove = async () => {
    await remove();
    redirect(routes.machines + '/' + machineId);
  };

  if (!machineLoaded || !slotLoaded || !lockerSlotLoaded) {
    return null;
  }

  const machineSlot = lockerData.machineSlot || '';

  let dataToShow = null;

  if (isCreate) {
    dataToShow = slotRecord;
  } else {
    dataToShow = lockerData;
  }

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page sectionTitle="Vending Machine" title={`Edit locker ` + machineSlot}>
        {/* <Typography gutterBottom>Status: </Typography> */}
        <Card>
          <Form
            onSubmit={onSubmit}
            initialValues={{ dropSensor: true, ...dataToShow }}
            keepDirtyOnReinitialize
            validateOnBlur
          >
            {({ handleSubmit, values, form, dirtyFields, modified }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <CardHeader title="Client details" />
                  <CardContent>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12} md={6}>
                        <NumberInput source="machineSlot" defaultValue={parseInt(slotNumber)} disabled />
                      </Grid> */}

                      <Grid item xs={12} md={6}>
                        <TextInput disabled source="receiverName" label="Receiver Name"></TextInput>
                        <Field component="input" type="hidden" name="machineSlot" initialValue={parseInt(slotID)} />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextInput disabled source="receiverID" label="Receiver ID"></TextInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextInput disabled source="referenceNumber" label="Reference Number"></TextInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextInput disabled source="phoneNumber" label="Phone Number"></TextInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextInput disabled source="retrieveCode" label="Retrieve Code"></TextInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BooleanInput source="error" label="Locker error" />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardHeader title="Generate code" />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextInput source="adminCode" label="Admin Code"></TextInput>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardHeader title="Product list" />
                  <CardContent>
                    {dataToShow.skus ? <ProductList skuList={dataToShow.skus} /> : 'No product(s) in locker.'}
                  </CardContent>

                  <Divider />
                  <CardActions>
                    <Button type="submit" variant="contained" color="primary" loading={saving}>
                      Save
                    </Button>
                    <BackButton />
                    <DeleteButton onClick={onRemove} label="Remove" loading={removing} disabled={saving} />
                  </CardActions>
                </form>
              );
            }}
          </Form>
        </Card>
      </Page>
    </Can>
  );
};

export default Doc2UsLockerEdit;
