import React from 'react';
import { Box, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { useHistory, generatePath } from 'react-router';
import routes from '@/vars/routes';
import { LockerStatus } from '@/vars';
import moment from 'moment';

const SLOT_STATUS = LockerStatus;

const useStyles = makeStyles(theme => ({
  root: props => {
    const { status } = props;
    let backgroundColor;
    let cursor = 'pointer';
    if (status === SLOT_STATUS.DISABLE) {
      backgroundColor = colors.green['A100'];
      cursor = '';
    }
    if (status === SLOT_STATUS.Available) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.PendingDeposit) backgroundColor = colors.blue[100];
    if (status === SLOT_STATUS.PendingCollection) backgroundColor = colors.orange[100];
    if (status === SLOT_STATUS.Collecting) backgroundColor = colors.blue[200];
    if (status === SLOT_STATUS.Reserved) backgroundColor = colors.yellow[100];
    if (status === SLOT_STATUS.Completed) backgroundColor = colors.green[100];
    if (status === SLOT_STATUS.ExpiredBeforeDeposit) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.ExpiredBeforeCollection) backgroundColor = colors.red[300];

    return {
      position: 'relative',
      minWidth: '110px',
      minHeight: '110px',
      border: `1px solid #808080`,
      marginRight: -1,
      padding: 10,
      fontSize: 12,
      textAlign: 'center',
      cursor,
      backgroundColor,
    };
  },
  text: {},
  textBold: {
    fontWeight: 'bold',
  },
  quantity: {},
  statusIndicator: props => {
    const { status } = props;
    let backgroundColor = colors.green[500];
    if (status === SLOT_STATUS.Available) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.PendingDeposit) backgroundColor = colors.blue[100];
    if (status === SLOT_STATUS.PendingCollection) backgroundColor = colors.orange[100];
    if (status === SLOT_STATUS.Collecting) backgroundColor = colors.blue[200];
    if (status === SLOT_STATUS.AdminCheck) backgroundColor = colors.grey[300];
    if (status === SLOT_STATUS.Reserved) backgroundColor = colors.yellow[100];
    if (status === SLOT_STATUS.ExpiredBeforeDeposit) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.ExpiredBeforeCollection) backgroundColor = colors.red[300];

    return {
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: 10,
      top: 5,
      right: 5,
      display: 'block',
      backgroundColor,
    };
  },
}));

const Doc2UsLockerItem = ({ slotData, slotInformation, machineId, boardID, disable, is12slots }) => {
  const slotID = slotInformation.id || '';
  const slotName = slotInformation.data.name || '';
  const { expiryDate, skus, status, slotId, receiverName } = slotData;

  const classes = useStyles({ status: status, is12slot: is12slots });
  const history = useHistory();

  let localTimeOfExpiry = null;

  if(expiryDate) {
    var utcOffset = moment.utc(expiryDate).toDate();
    localTimeOfExpiry = moment(utcOffset).local().format('DD/MM/YYYY HH:mm');
  }

  const renderProduct = () => {
    return '';
  };

  const truncate = input => {
    if (input.length > 13) {
      return input.substring(0, 13) + '...';
    }
    return input;
  };

  if (disable) {
    return <div className={classes.root}></div>;
  }

  return (
    <div
      className={classes.root}
      onClick={() => {
        const path = generatePath(routes.doc2usLockerSlot, { machineId, boardID, slotID });
        history.push(path);
      }}
    >
      <Box>
        <Box className={classes.text}>{slotName}</Box>
        <Box className={classes.textBold}>{receiverName ? receiverName : ''}</Box>
        <Box className={classes.textBold} mt={1}>
          {truncate(renderProduct())}
        </Box>
        <Box className={classes.text}>{localTimeOfExpiry ? localTimeOfExpiry : ''}</Box>
      </Box>
    </div>
  );
};

export default Doc2UsLockerItem;
