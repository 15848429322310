import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  thumbnailWrapper: {
    width: 50,
    height: 50,
    padding: theme.spacing(0.2),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    border: `1px solid ${grey[300]}`,
    borderRadius: 4,
    overflow: 'hidden',
  },
  image: {
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 0,
  },
}));

function ImageField({ record, source }) {
  const classes = useStyles();
  const imageUrl = get(record, source) || '';

  if (!imageUrl) return null;

  return (
    <div className={classes.thumbnailWrapper}>
      <img className={classes.image} src={imageUrl} alt="" />
    </div>
  );
}

export default ImageField;
