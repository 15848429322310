import React from 'react';
import { List, Datagrid, TextField, EditButton, Button, CRUD_UPDATE, useNotify, useUpdate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Card, CardContent, colors } from '@material-ui/core';
import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { Page } from '@/components';
import { Filter, ActionMenu } from '@/ra';
import { StatusField } from './StatusField';
import CompanyField from './CompanyField';
import { useRefreshMachineStatus, useClearErrorLogs } from './hooks';

const RefreshStatusButton = ({ basePath, record = {}, resource, ...rest }) => {
  const [, refresh] = useRefreshMachineStatus();
  return <Button onClick={() => refresh(record.id)} label="Refresh" {...rest} />;
};

const ClearErrorLogsButton = ({ basePath, record = {}, resource, ...rest }) => {
  const [, clearLogs] = useClearErrorLogs();
  return <Button onClick={() => clearLogs(record.id)} label="Clear Terminal Errors" {...rest} />;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  data: props => {
    const { isAdmin } = props;
    let minWidth = '200px';
    if (isAdmin) minWidth = '150px';
    return {
      display: 'flex',
      minWidth,
    };
  },
});

const MachineList = props => {
  const { permissions } = useAuth();
  const filter = {
    ...useDefaultFilter('client.id'),
    isDeleted: false,
  };
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const searchFilter = <Filter searchKeys={['machineUUID', 'location', 'versionName']} />;
  const classes = useStyles({ isAdmin: isAdmin });

  return (
    <Can module={ACCESS.VENDING_MACHINE}>
      <Page sectionTitle="Vending Machine" title="List machines">
        <Box className={classes.root}>
          <List
            {...props}
            filter={filter}
            bulkActionButtons={false}
            sort={{ field: 'createdAt' }}
            filters={searchFilter}
            perPage={25}
          >
            <Datagrid>
              <TextField className={classes.data} source="machineUUID" label="UUID"/>
              <TextField className={classes.data} source="location"  />
              {isAdmin && <CompanyField className={classes.data} label="Company" />}
              <StatusField className={classes.data} label="Status"  />
              <TextField className={classes.data} source="versionName"  />
              <ActionMenu className={classes.data} label="Actions">
                <EditButton icon={null} />
                <RefreshStatusButton />
              </ActionMenu>
              {/* {isAdmin && <ClearErrorLogsButton/>} */}
            </Datagrid>
          </List>
        </Box>
      </Page>
    </Can>
  );
};

export default MachineList;
