import { CREATE, DELETE, DELETE_MANY, GET_ONE, UPDATE } from 'react-admin';
import { functions, db } from '@/vars/firebase';

const usersDataProvider = async (type, resource, params) => {
  switch (type) {
    case GET_ONE: {
      const { id } = params;
      const userSnapshot = await db
        .collection('users')
        .doc(id)
        .get();

      if (userSnapshot.exists) {
        let data = userSnapshot.data();

        const { client } = data;
        if (client) {
          const clientSnapshot = await db
            .collection('clients')
            .doc(client.id)
            .get();

          if (clientSnapshot.exists) {
            data = {
              ...data,
              client: {
                ...client,
                ...clientSnapshot.data(),
              },
            };
          }
        }

        return { data: { id, ...data } };
      } else {
        throw new Error('Id not found');
      }
    }

    case CREATE: {
      const data = await functions.httpsCallable('auth-createUser')(params.data);
      return data;
    }

    case UPDATE: {
      const { id } = params;
      const data = await functions.httpsCallable('auth-updateUser')({ id, ...params.data });
      return data;
    }

    case DELETE: {
      const { id } = params;
      await functions.httpsCallable('auth-deleteUser')({ id });
      return { data: id };
    }

    case DELETE_MANY: {
      // @TODO @BUG @LOW_PRIORITY
      // There is a issue after deletions, the items are showing in the list
      // page after the refresh. My assumption is our firestore client is not
      // sync up with this action yet.
      //
      // The temporary solution is to disable multi delete for now.
      const { ids } = params;
      await ids.map(id => functions.httpsCallable('auth-deleteUser')({ id }));
      return { data: ids };
    }

    default:
      throw Error(`Unhandled method: ${resource} ${type}`);
  }
};

export default usersDataProvider;
