import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#544AA2',
      light: '#635AAA',
      dark: '#4D4494',
      alternate: '#827BBB',
    },
    secondary: {
      main: '#E26685',
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'PingFang SC',
      'Hiragino Sans GB',
      'Microsoft YaHei',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '.9rem 1rem',
        fontSize: '1rem',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '.85em',
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: 'rgba(150, 150, 150, .08) !important',
        },
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: grey[100],
        color: grey[900],
        fontSize: '.8rem',
        fontWeight: 'bold',
      },
      body: {
        fontSize: '.8rem',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'none',
        top: -2,
      },
    },
  },
});

theme.overrides.MuiButton = {
  root: {
    boxShadow: 'none',
    padding: theme.spacing(1, 1.6),
    fontSize: '1rem',
    '&$focusVisible': {
      boxShadow: 'none',
    },
  },
  sizeSmall: {
    fontSize: '.9rem',
    padding: theme.spacing(0.5, 1.2),
  },
  contained: {
    boxShadow: 'none',
  },
  containedPrimary: {
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:active, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
  },
  label: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
};

theme.overrides.MuiFormLabel = {
  root: {
    margin: theme.spacing(0, 0, 0.8, 0.5),
    fontSize: '.85rem',
    display: 'block',
    '&.ra-form-label': {
      fontSize: '.95rem',
      margin: theme.spacing(0, 0, -1, 0.5),
    },
  },
};

theme.overrides.MuiFormControl = {
  root: {
    marginBottom: theme.spacing(0.5),
    display: 'block',
  },
};

// For page titles
theme.typography.h2 = {
  fontSize: '1.15rem',
  fontWeight: '600',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
};

// For card titles
theme.typography.h3 = {
  fontSize: '1.3rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    fontSize: '1.35rem',
  },
};

// For smaller card titles
theme.typography.h4 = {
  fontSize: '1rem',
  marginBottom: theme.spacing(1),
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.02rem',
  },
};

// For card subtitles
theme.typography.h5 = {
  fontSize: '.85rem',
  fontWeight: 'normal',
  marginBottom: theme.spacing(1),
};

export default theme;

export const mainDataColors = ['#635AAA', '#ff8aa2', '#b1f7ba', '#ff9778', '#b0003e'];
