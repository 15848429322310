import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useChoices } from 'ra-core';

var RadioButtonGroupInputItem = function RadioButtonGroupInputItem(_a) {
  var choice = _a.choice,
      optionText = _a.optionText,
      optionValue = _a.optionValue,
      source = _a.source,
      translateChoice = _a.translateChoice;

  var _b = useChoices({
    optionText: optionText,
    optionValue: optionValue,
    translateChoice: translateChoice
  }),
      getChoiceText = _b.getChoiceText,
      getChoiceValue = _b.getChoiceValue;

  var choiceName = getChoiceText(choice);
  var nodeId = source + "_" + getChoiceValue(choice);
  return React.createElement(FormControlLabel, {
    htmlFor: nodeId,
    value: getChoiceValue(choice),
    control: React.createElement(Radio, {
      id: nodeId,
      color: "primary"
    }),
    label: choiceName
  });
};

export default RadioButtonGroupInputItem;