import React from 'react';
import { Create, Edit } from 'react-admin';

import { Can, ACCESS } from '@/utils/auth';
import { Page } from '@/components';
import UserList from './UserList';
import UserForm from './UserForm';
import Profile from './Profile';

const UserCreate = props => {
  return (
    <Can module={ACCESS.USER}>
      <Page sectionTitle="users" title="Create user">
        <Create {...props}>
          <UserForm mode="create" />
        </Create>
      </Page>
    </Can>
  );
};

const UserEdit = props => {
  return (
    <Can module={ACCESS.USER}>
      <Page sectionTitle="users" title="Edit user">
        <Edit {...props}>
          <UserForm mode="edit" />
        </Edit>
      </Page>
    </Can>
  );
};

export { UserList, UserCreate, UserEdit, Profile };
