import React from 'react';
import { useShowController } from 'react-admin';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { StatusField, StatusFieldInMachineInfo } from '../StatusField';
import CompanyField from '../CompanyField';

const useStyles = makeStyles(theme => {
  return {
    label: {
      marginRight: theme.spacing(0.5),
    },
    value: {
      ...theme.typography.body1,
    },
  };
});

const MachineInfo = props => {
  const { basePath, showController } = props;
  const classes = useStyles();
  const { record, loaded } = showController;
  if (!loaded) return null;

  const Field = ({ label, source }) => {
    return (
      <Box mb={0.5} display="flex">
        <Box className={classes.label}>
          <Typography style={{ fontWeight: 'bold' }}>{label}:</Typography>
        </Box>
        <Box>
          {typeof source === 'function' ? (
            source()
          ) : (
            <Typography className={classes.value}>{record[source]}</Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Field label="Device ID" source="deviceID" />
          <Field label="Machine UUID" source="machineUUID" />
          <Field label="Machine name" source="machineName" />
          <Field label="Machine mode" source="machineMode" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            label="Machine Status"
            source={() => <StatusFieldInMachineInfo record={record} variant="outlined" />}
          />
          <Field
            label="Company name"
            source={() => <CompanyField record={record} basePath={basePath} className={classes.value} />}
          />
          <Field label="Location" source="location" />
        </Grid>
      </Grid>
    </>
  );
};

export default MachineInfo;
