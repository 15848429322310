import React, { useState, createContext, useContext } from 'react';
import firebase from 'firebase/app';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TextField, Box } from '@material-ui/core';
import { ArrowBackIosOutlined as GoBackIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import AuthLayout from '@/layouts/AuthLayout';
import routes from '@/vars/routes';
import Button from '@/components/Button';

function ForgotPassword() {
  const [state, setState] = useState({ email: null, isSubmit: false });

  function handleRequestComplete(email) {
    setState(s => ({ ...s, email: email, isSubmit: true }));
  }

  return (
    <ForgotPasswordContext.Provider value={{ ...state, handleRequestComplete }}>
      {state.isSubmit ? <InstructionSent /> : <ForgotPasswordForm />}
    </ForgotPasswordContext.Provider>
  );
}

function ForgotPasswordForm() {
  const classes = useStyles();
  const { handleSubmit, register, errors, setError } = useForm();
  const { handleRequestComplete } = useContext(ForgotPasswordContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async values => {
    try {
      setLoading(true);
      const { email } = values;
      await firebase.auth().sendPasswordResetEmail(email);
      handleRequestComplete(email);
    } catch (error) {
      setLoading(false);
      const { code, message } = error;
      return setError('email', code, message);
    }
  };

  return (
    <AuthLayout
      heading="Forgot your password?"
      subHeading="Enter your email address to receive the password reset instruction."
    >
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate method="POST">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Email Address"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          inputRef={register({ required: 'Please enter a valid email.' })}
          error={Boolean(errors.email)}
          helperText={errors.email && errors.email.message}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loading={loading}
        >
          Reset Password
        </Button>
        <Box display="flex" flexDirection="row" className={classes.actionWrapper}>
          <Link to={routes.signIn} className={classes.goBackLink}>
            <GoBackIcon className={classes.goBackLinkIcon} />
            <span>Go back to login</span>
          </Link>
        </Box>
      </form>
    </AuthLayout>
  );
}

function InstructionSent() {
  const classes = useStyles();
  const { email } = useContext(ForgotPasswordContext);
  return (
    <AuthLayout heading="Please check your email">
      <span className={classes.instructionSentText}>
        We've sent the password reset instruction to <i>{email}</i>.
      </span>
    </AuthLayout>
  );
}

const ForgotPasswordContext = createContext();

export default ForgotPassword;

/* Styling */
const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  actionWrapper: {
    marginTop: theme.spacing(3),
  },
  goBackLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    opacity: '.7',
    '&:hover': {
      opacity: 1,
    },
  },
  goBackLinkIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5),
  },
  instructionSentText: {
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    lineHeight: 1.5,
  },
}));
