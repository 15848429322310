import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, colors } from '@material-ui/core';

function ContentCard({ children }) {
  const classes = useStyles();
  return <Paper className={classes.root}>{children}</Paper>;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3.5, 3, 3.5),
    boxShadow: 'none',
    borderRadius: 4,
    border: '1px solid #eaeaea',
    borderBottom: `2px solid ${theme.palette.primary.alternate}`,
    // Styling for React-Admin's Create
    '& .MuiCard-root': {
      boxShadow: 'none',
      '& form': {
        '& > .MuiCardContent-root': {
          padding: 0,
        },
        '& .MuiToolbar-root': {
          background: 'none',
          marginTop: theme.spacing(4),
          padding: theme.spacing(3, 2, 0),
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${colors.grey[300]}`,
          '& > div': {
            flex: 'inherit',
          },
        },
      },
    },
  },
}));

ContentCard.propTypes = {
  title: PropTypes.string,
};

export default ContentCard;
