var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { useTranslate, useDelete, useRefresh, useNotify, useRedirect, CRUD_DELETE } from 'ra-core';
import Confirm from '../layout/Confirm';
import Button from './Button';

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var basePath = _a.basePath,
      classes = _a.classes,
      filterValues = _a.filterValues,
      handleSubmit = _a.handleSubmit,
      handleSubmitWithRedirect = _a.handleSubmitWithRedirect,
      invalid = _a.invalid,
      label = _a.label,
      pristine = _a.pristine,
      resource = _a.resource,
      saving = _a.saving,
      selectedIds = _a.selectedIds,
      submitOnEnter = _a.submitOnEnter,
      redirect = _a.redirect,
      rest = __rest(_a, ["basePath", "classes", "filterValues", "handleSubmit", "handleSubmitWithRedirect", "invalid", "label", "pristine", "resource", "saving", "selectedIds", "submitOnEnter", "redirect"]);

  return rest;
};

var useStyles = makeStyles(function (theme) {
  return {
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  };
});

var DeleteWithConfirmButton = function DeleteWithConfirmButton(_a) {
  var basePath = _a.basePath,
      classesOverride = _a.classes,
      className = _a.className,
      icon = _a.icon,
      _b = _a.label,
      label = _b === void 0 ? 'ra.action.delete' : _b,
      onClick = _a.onClick,
      record = _a.record,
      resource = _a.resource,
      redirectTo = _a.redirect,
      rest = __rest(_a, ["basePath", "classes", "className", "icon", "label", "onClick", "record", "resource", "redirect"]);

  var _c = useState(false),
      open = _c[0],
      setOpen = _c[1];

  var translate = useTranslate();
  var notify = useNotify();
  var redirect = useRedirect();
  var refresh = useRefresh();
  var classes = useStyles({
    classes: classesOverride
  });

  var _d = useDelete(resource, record && record.id, record, {
    action: CRUD_DELETE,
    onSuccess: function onSuccess() {
      notify('ra.notification.deleted', 'info', {
        smart_count: 1
      });
      redirect(redirectTo, basePath);
      refresh();
    },
    onFailure: function onFailure(error) {
      return notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    },
    undoable: false
  }),
      deleteOne = _d[0],
      loading = _d[1].loading;

  var handleClick = function handleClick(e) {
    setOpen(true);
    e.stopPropagation();
  };

  var handleDialogClose = function handleDialogClose(e) {
    setOpen(false);
    e.stopPropagation();
  };

  var handleDelete = useCallback(function () {
    deleteOne();

    if (typeof onClick === 'function') {
      onClick();
    }
  }, [deleteOne, onClick]);
  return React.createElement(Fragment, null, React.createElement(Button, __assign({
    onClick: handleClick,
    label: label,
    className: classnames('ra-delete-button', classes.deleteButton, className),
    key: "button"
  }, sanitizeRestProps(rest)), icon), React.createElement(Confirm, {
    isOpen: open,
    loading: loading,
    title: "ra.message.delete_title",
    content: "ra.message.delete_content",
    translateOptions: {
      name: inflection.humanize(translate("resources." + resource + ".name", {
        smart_count: 1,
        _: inflection.singularize(resource)
      }), true),
      id: record.id
    },
    onConfirm: handleDelete,
    onClose: handleDialogClose
  }));
};

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  icon: PropTypes.element
};
DeleteWithConfirmButton.defaultProps = {
  redirect: 'list',
  icon: React.createElement(ActionDelete, null)
};
export default DeleteWithConfirmButton;