import englishMessages from 'ra-language-english';

// Syntax to insert variable in string. E.g.
// 'This is %{name}'

const messages = {
  ...englishMessages,
  user: {
    notification: {
      status_update_done: 'Updated user status',
      status_update_fail: 'Fail to update user status',
      reset_password_start: 'Sending password reset email...',
      reset_password_done: 'Sent password reset email',
      reset_password_fail: 'Fail to send password reset email',
    },
  },
};

export default messages;
