import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 420,
    maxWidth: '100%',
  },
}));

const FilterDrawer = props => {
  const classes = useStyles();
  return <Drawer anchor="right" classes={{ paper: classes.drawer }} variant="temporary" {...props} />;
};

export default FilterDrawer;
