var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
import InputHelperText from './InputHelperText';
/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */

var convertDateToString = function convertDateToString(value) {
  if (!(value instanceof Date) || isNaN(value.getDate())) return;
  var pad = '00';
  var yyyy = value.getFullYear().toString();
  var MM = (value.getMonth() + 1).toString();
  var dd = value.getDate().toString();
  return yyyy + "-" + (pad + MM).slice(-2) + "-" + (pad + dd).slice(-2);
};

var dateRegex = /^\d{4}-\d{2}-\d{2}$/;

var getStringFromDate = function getStringFromDate(value) {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  } // valid dates should not be converted


  if (dateRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};

export var DateInput = function DateInput(_a) {
  var _b = _a.format,
      format = _b === void 0 ? getStringFromDate : _b,
      label = _a.label,
      options = _a.options,
      source = _a.source,
      resource = _a.resource,
      helperText = _a.helperText,
      _c = _a.margin,
      margin = _c === void 0 ? 'dense' : _c,
      onBlur = _a.onBlur,
      onChange = _a.onChange,
      onFocus = _a.onFocus,
      parse = _a.parse,
      validate = _a.validate,
      _d = _a.variant,
      variant = _d === void 0 ? 'filled' : _d,
      rest = __rest(_a, ["format", "label", "options", "source", "resource", "helperText", "margin", "onBlur", "onChange", "onFocus", "parse", "validate", "variant"]);

  var _e = useInput(__assign({
    format: format,
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    parse: parse,
    resource: resource,
    source: source,
    validate: validate
  }, rest)),
      id = _e.id,
      input = _e.input,
      isRequired = _e.isRequired,
      _f = _e.meta,
      error = _f.error,
      touched = _f.touched;

  return React.createElement(TextField, __assign({
    id: id
  }, input, {
    variant: variant,
    margin: margin,
    type: "date",
    error: !!(touched && error),
    helperText: touched && error || helperText ? React.createElement(InputHelperText, {
      touched: touched,
      error: error,
      helperText: helperText
    }) : null,
    label: React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    }),
    InputLabelProps: {
      shrink: true
    }
  }, options, sanitizeRestProps(rest)));
};
DateInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string
};
DateInput.defaultProps = {
  options: {}
};
export default DateInput;