import React from 'react';
import { Box, Card, CardContent, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SlotItemBoard from '@/pages/machines/MachineEdit/SlotGridBoard/SlotItemBoard';
import { useGetItemsFromMachines, useGetSlotsRealtime } from '@/pages/machines/hooks';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  rowLabel: {
    marginBottom: theme.spacing(1),
  },
  label: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'right',
  },
  legendLabel: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    marginBottom: 2,
    marginRight: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  legend: {
    minWidth: 80,
    minHeight: 30,
    display: 'inline-block',
    border: '1px solid',
    borderRadius: 10,
    marginRight: 7,
    marginBottom: 2,
    padding: 6,
    textAlign: 'center',
    fontSize: 12,

    '&.occupied': {
      backgroundColor: colors.green['A100'],
    },

    '&.empty': {
      backgroundColor: colors.grey[100],
    },

    '&.error': {
      backgroundColor: colors.red[200],
    },

    '&.ofs': {
      backgroundColor: colors.orange[200],
    },
  },
}));

const reformatData = (numOfRow, slotList) => {
  let dataToReturn = [];
  let slotIDList = Object.keys(slotList);

  for (let i = 0; i < numOfRow; i++) {
    dataToReturn[i] = [];
  }

  slotIDList.forEach(idKey => {
    let dataToPass = slotList[idKey];
    dataToReturn[dataToPass.data.yPhysical - 1][dataToPass.data.xPhysical - 1] = dataToPass;
  });

  return dataToReturn;
};

const SlotGridBoard = ({ machineId, machineModel, boardData }) => {
  const classes = useStyles();
  const isCompatMachine = machineModel === 'VE-E1208N' || machineModel === 'VE-E1208C';
  const itemsOnBoard = useGetItemsFromMachines(machineId, boardData.id);
  const realTimeSlots = useGetSlotsRealtime(machineId);

  let COL_NUM = boardData.xColumn;
  let ROW_NUM = boardData.yRow;

  if (!itemsOnBoard.loaded || !realTimeSlots.loaded) return null;

  const formattedData = reformatData(ROW_NUM, itemsOnBoard.items);

  const Row = ({ rowIndex }) => {
    return (
      <div>
        <Box className={classes.row}>
          {formattedData[rowIndex].map((slotInformation, index) => {
            const slotIndex = rowIndex * COL_NUM + index;
            const slotNumber = slotIndex + 1;
            const slotData = realTimeSlots.data[slotInformation.id] || {};
            if (index >= 10) {
              return null;
            } else {
              return (
                <SlotItemBoard
                  key={slotIndex}
                  slotData={slotData}
                  slotNumber={slotNumber}
                  slotInformation={slotInformation}
                  machineId={machineId}
                  boardID={boardData.id}
                  diablse={false}
                  is12slots={false}
                />
              );
            }
          })}
        </Box>
      </div>
    );
  };

  const CompatRow = ({ rowIndex }) => {
    return (
      <div>
        <Box className={classes.row}>
          {formattedData[rowIndex].map((slotInformation, index) => {
            const slotIndex = rowIndex * COL_NUM + index;
            const slotNumber = slotIndex + 1;
            const slotData = realTimeSlots.data[slotInformation.id] || {};
            if (index >= 12) {
              return null;
            } else {
              if (rowIndex > 2 && rowIndex < 6 && index > 7 && index < 13) {
                return (
                  <SlotItemBoard
                    key={slotIndex}
                    slotData={slotData}
                    slotNumber={slotNumber}
                    slotInformation={slotInformation}
                    machineId={machineId}
                    boardID={boardData.id}
                    disable={true}
                    is12slots={true}
                  />
                );
              } else {
                return (
                  <SlotItemBoard
                    key={slotIndex}
                    slotData={slotData}
                    slotNumber={slotNumber}
                    slotInformation={slotInformation}
                    machineId={machineId}
                    boardID={boardData.id}
                    disable={false}
                    is12slots={true}
                  />
                );
              }
            }
          })}
        </Box>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.label}>
        <Box className={classes.legendLabel}>Legends:</Box>
        <Box className={`${classes.legend} occupied`}>Occupied</Box>
        <Box className={`${classes.legend} error`}>Error</Box>
        <Box className={`${classes.legend} ofs`}>Out of Stock</Box>
        <Box className={`${classes.legend} empty`}>Empty Slot</Box>
      </div>

      <Card
        style={{
          overflowY: 'hidden',
          overflowX: 'scroll',
        }}
      >
        <CardContent>
          {[...Array(ROW_NUM)].map((v, i) =>
            isCompatMachine ? <CompatRow rowIndex={i} key={i} /> : <Row rowIndex={i} key={i} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SlotGridBoard;
