import { useAuth } from '@/utils/auth';
import { USER } from '@/vars';

const useDefaultFilter = (clientField = 'client.id') => {
  const { permissions } = useAuth();
  let filter = {};
  if (permissions.role === USER.ROLE.CLIENT) {
    filter = {
      [clientField]: permissions.client_id,
    };
  }
  return filter;
};

export default useDefaultFilter;
