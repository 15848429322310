var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslate } from 'ra-core';
var useStyles = makeStyles({
  clearIcon: {
    height: 16,
    width: 0
  },
  visibleClearIcon: {
    width: 16
  },
  clearButton: {
    height: 24,
    padding: 0,
    width: 0
  },
  visibleClearButton: {
    width: 24
  },
  selectAdornment: {
    position: 'absolute',
    right: 24
  },
  inputAdornedEnd: {
    paddingRight: 0
  }
});

var handleMouseDownClearButton = function handleMouseDownClearButton(event) {
  event.preventDefault();
};
/**
 * An override of the default Material-UI TextField which is resettable
 */


function ResettableTextField(_a) {
  var _b, _c;

  var classesOverride = _a.classes,
      clearAlwaysVisible = _a.clearAlwaysVisible,
      InputProps = _a.InputProps,
      value = _a.value,
      resettable = _a.resettable,
      disabled = _a.disabled,
      _d = _a.variant,
      variant = _d === void 0 ? 'filled' : _d,
      _e = _a.margin,
      margin = _e === void 0 ? 'dense' : _e,
      props = __rest(_a, ["classes", "clearAlwaysVisible", "InputProps", "value", "resettable", "disabled", "variant", "margin"]);

  var _f = useState(false),
      showClear = _f[0],
      setShowClear = _f[1];

  var classes = useStyles({
    classes: classesOverride
  });
  var translate = useTranslate();
  var onChange = props.onChange,
      onFocus = props.onFocus,
      onBlur = props.onBlur;
  var handleClickClearButton = useCallback(function (event) {
    event.preventDefault();
    onChange('');
  }, [onChange]);
  var handleFocus = useCallback(function (event) {
    setShowClear(true);
    onFocus && onFocus(event);
  }, [onFocus]);
  var handleBlur = useCallback(function (event) {
    setShowClear(false);
    onBlur && onBlur(event);
  }, [onBlur]);

  var clearButton = classes.clearButton,
      clearIcon = classes.clearIcon,
      inputAdornedEnd = classes.inputAdornedEnd,
      selectAdornment = classes.selectAdornment,
      visibleClearButton = classes.visibleClearButton,
      visibleClearIcon = classes.visibleClearIcon,
      restClasses = __rest(classes, ["clearButton", "clearIcon", "inputAdornedEnd", "selectAdornment", "visibleClearButton", "visibleClearIcon"]);

  return React.createElement(MuiTextField, __assign({
    classes: restClasses,
    value: value,
    InputProps: __assign({
      classes: {
        adornedEnd: props.select ? inputAdornedEnd : null
      },
      endAdornment: resettable && value && React.createElement(InputAdornment, {
        position: "end",
        classes: {
          root: props.select ? selectAdornment : null
        }
      }, React.createElement(IconButton, {
        className: classNames(clearButton, (_b = {}, _b[visibleClearButton] = clearAlwaysVisible || showClear, _b)),
        "aria-label": translate('ra.action.clear_input_value'),
        title: translate('ra.action.clear_input_value'),
        disableRipple: true,
        onClick: handleClickClearButton,
        onMouseDown: handleMouseDownClearButton,
        disabled: disabled
      }, React.createElement(ClearIcon, {
        className: classNames(clearIcon, (_c = {}, _c[visibleClearIcon] = clearAlwaysVisible || showClear, _c))
      })))
    }, InputProps),
    disabled: disabled,
    variant: variant,
    margin: margin
  }, props, {
    onFocus: handleFocus,
    onBlur: handleBlur
  }));
}

ResettableTextField.propTypes = {
  classes: PropTypes.object,
  clearAlwaysVisible: PropTypes.bool,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  resettable: PropTypes.bool,
  value: PropTypes.any.isRequired
};
export default ResettableTextField;