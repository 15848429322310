import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGetOne } from 'react-admin';
import moment from 'moment';
import { get } from 'lodash';

import { USERS } from '@/vars/resources';
import { useAuth } from '@/utils/auth';
import ContentWrapper from '@/components/dashboard/ContentWrapper';
import ContentCard from '@/components/dashboard/ContentCard';
import QuickStats from './QuickStats';
import TodaysTransactionChart from './TodaysTransactionChart';
import TodaysSalesChart from './TodaysSalesChart';
import { useTodayTransactionsReport, useMachinesReport } from './hooks';

function Dashboard() {
  const classes = useStyles();
  const { permissions } = useAuth();
  const userId = permissions.user_id;

  const { data: profileData, loaded: profileLoaded } = useGetOne(USERS, userId);
  const {
    data: todayTransactionsReport,
    loading: transactionLoading,
    hasAccessSalesReport,
    hasAccessMROReport,
  } = useTodayTransactionsReport(permissions); 
  
  const {
    data: yesterdayTransactionsReport,
    loading: yesterdayTransactionLoading,
    hasAccessSalesReport: yesterdayHasAccessSalesReport,
    hasAccessMROReport: yesterdayHasAccessMROReport,
  } = useTodayTransactionsReport(permissions, true); 

  const { data: machinesReport, loading: machinesLoading, hasAccess: hasAccessMachine } = useMachinesReport(
    permissions
  );

  const name = get(profileData, 'firstName', '').trim();
  const email = get(profileData, 'email');
  const todayDate = moment().format('YYYY-MM-DD');
  const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

  const todaysTransactionChart = {
    date: todayDate,
    values: todayTransactionsReport.groupBy.machines.map(m => ({
      id: m.id,
      label: m.machineUUID,
      value: m.totalTransactionsCount,
      sales: m.totalCash,
    })),
  };

  const yesterdayTransactionChart = {
    date: yesterdayDate,
    values: yesterdayTransactionsReport.groupBy.machines.map(m => ({
      id: m.id,
      label: m.machineUUID,
      value: m.totalTransactionsCount,
      sales: m.totalCash,
    })),
  };

  const todaysSalesChart = {
    date: todayDate,
    values: todayTransactionsReport.groupBy.machines.map(m => ({
      id: m.id,
      label: m.machineUUID,
      cash: m.totalCash,
      credit: m.totalCredit,
    })),
    summary: {
      totalCash: todayTransactionsReport.summary.totalCash,
      totalCredit: todayTransactionsReport.summary.totalCredit,
    },
    access: {
      sales: hasAccessSalesReport,
      mro: hasAccessMROReport,
    },
  };

  const yesterdaySalesChart = {
    date: yesterdayDate,
    values: yesterdayTransactionsReport.groupBy.machines.map(m => ({
      id: m.id,
      label: m.machineUUID,
      cash: m.totalCash,
      credit: m.totalCredit,
    })),
    summary: {
      totalCash: yesterdayTransactionsReport.summary.totalCash,
      totalCredit: yesterdayTransactionsReport.summary.totalCredit,
    },
    access: {
      sales: yesterdayHasAccessSalesReport,
      mro: yesterdayHasAccessMROReport,
    },
  };

  return (
    <ContentWrapper>
      <Grid container spacing={3}>
        {/* Quick Stats */}
        <Grid item xs={12}>
          <QuickStats
            name={name}
            email={email}
            ydaTotalSalesAmount={yesterdayTransactionsReport.summary.totalCash}
            ydaTotalCreditAmount={yesterdayTransactionsReport.summary.totalCredit}
            totalSalesAmount={todayTransactionsReport.summary.totalCash}
            totalCreditAmount={todayTransactionsReport.summary.totalCredit}
            errorMachines={machinesReport.errors}
            outOfStockMachines={machinesReport.outOfStocks}
            onlineMachines={machinesReport.onlines}
            offlineMachines={machinesReport.offlines}
            machinesLoading={machinesLoading}
            profileLoading={!profileLoaded}
            ydaAmountLoading={yesterdayTransactionLoading}
            amountLoading={transactionLoading}
            hasAccessSalesReport={hasAccessSalesReport}
            hasAccessMROReport={hasAccessMROReport}
            hasAccessMachine={hasAccessMachine}
          />
        </Grid>

        {(hasAccessSalesReport || hasAccessMROReport) && (
          <>
            {/* Charts */}
            <Grid item xs={12} sm={6}>
              <ContentCard>
                <Typography variant="h4">Today's Transaction</Typography>
                <div className={classes.chartWrapper}>
                  <TodaysTransactionChart data={todaysTransactionChart} loading={transactionLoading} />
                </div>
              </ContentCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ContentCard>
                <Typography variant="h4">Today's Sales</Typography>
                <div className={classes.chartWrapper}>
                  <TodaysSalesChart data={todaysSalesChart} loading={transactionLoading} />
                </div>
              </ContentCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ContentCard>
                <Typography variant="h4">Yesterday's Transaction</Typography>
                <div className={classes.chartWrapper}>
                  <TodaysTransactionChart data={yesterdayTransactionChart} loading={yesterdayTransactionLoading} />
                </div>
              </ContentCard>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ContentCard>
                <Typography variant="h4">Yesterday's Sales</Typography>
                <div className={classes.chartWrapper}>
                  <TodaysSalesChart data={yesterdaySalesChart} loading={yesterdayTransactionLoading} />
                </div>
              </ContentCard>
            </Grid>
          </>
        )}
      </Grid>
    </ContentWrapper>
  );
}

const useStyles = makeStyles(() => ({
  chartWrapper: {
    height: 320,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

// Sample data
// const dummyTransactionData = {
//   date: '2019-09-05',
//   values: [
//     { id: 'VM0002', label: 'VM0002', value: 326, sales: 815 },
//     { id: 'VM0001', label: 'VM0001', value: 272, sales: 680 },
//     { id: 'VM0003', label: 'VM0003', value: 490, sales: 1225 },
//     { id: 'VM0004', label: 'VM0004', value: 0, sales: 0 },
//     { id: 'VM0005', label: 'VM0005', value: 87, sales: 217 },
//     { id: 'VM0007', label: 'VM0007', value: 413 },
//   ],
// };

// const dummySalesData = {
//   date: '2019-09-05',
//   unit: 'Credit',
//   values: [
//     { id: 'VM0001', label: 'VM0001', value: 680 },
//     { id: 'VM0002', label: 'VM0002', value: 815 },
//     { id: 'VM0003', label: 'VM0003', value: 1225 },
//     { id: 'VM0004', label: 'VM0004', value: 0 },
//     { id: 'VM0005', label: 'VM0005', value: 217 },
//     { id: 'VM0007', label: 'VM0007', value: 1032 },
//   ],
// };

export default Dashboard;