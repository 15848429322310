import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useDataProvider } from 'react-admin';

const useGetOneQuery = () => {
  const dataProvider = useDataProvider();
  const store = useStore();
  const callback = useCallback(async (resource, id, options) => {
    const state = store.getState();
    const data = state.admin.resources[resource] ? state.admin.resources[resource].data[id] : null;
    if (data != null) return data;
    return await dataProvider['getOne'](resource, { id }, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return callback;
};

export default useGetOneQuery;
