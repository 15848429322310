import { FirestoreClient } from 'ra-data-firestore';
import { groupBy, map, values, isEmpty, orderBy } from 'lodash';
import { TRANSACTIONS } from '@/vars/resources';
import { MACHINE } from '@/vars';

const firestoreClient = new FirestoreClient();
const roundDecimal = v => Math.round(v * 100) / 100;

export default {
  getList: async (resource, params) => {
    const { filter, sort, pagination } = params;
    const { data: transactions } = await firestoreClient.getList(TRANSACTIONS, { filter });

    let data = values(
      map(groupBy(transactions, 'employee.id'), (arr, id) => {
        let totalQty = 0;
        let totalCash = 0;
        let totalCredit = 0;
        const { employee, client } = arr[0];

        arr.forEach(arrItem => {
          const { quantity, amount, mode } = arrItem;
          totalQty += quantity || 0;
          if (mode === MACHINE.MODE.MRO) {
            totalCredit += amount || 0;
          } else {
            totalCash += amount || 0;
          }
        });

        return {
          id,
          client,
          employee,
          totalQty,
          totalTransactions: arr.length,
          totalCash: roundDecimal(totalCash),
          totalCredit: roundDecimal(totalCredit),
        };
      })
    );

    if (!isEmpty(sort)) {
      const { field, order = '' } = sort;
      data = orderBy(data, [field], [order.toLowerCase()]);
    }

    let dataPage = data;
    if (!isEmpty(pagination)) {
      const { page, perPage } = pagination;
      const pageStart = (page - 1) * perPage;
      const pageEnd = pageStart + perPage;
      dataPage = data.slice(pageStart, pageEnd);
    }
    const total = data.length;

    return { data: dataPage, total };
  },
};
