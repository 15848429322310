var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import React from 'react';
import { default as warning } from '../util/warning';
import useTranslate from './useTranslate';
import useLocale from './useLocale';
/**
 * Higher-Order Component for getting access to the `locale` and the `translate` function in props.
 *
 * Requires that the app is decorated by the <TranslationProvider> to inject
 * the translation dictionaries and function in the context.
 *
 * @example
 *     import React from 'react';
 *     import { translate } from 'react-admin';
 *
 *     const MyHelloButton = ({ translate }) => (
 *         <button>{translate('myroot.hello.world')}</button>
 *     );
 *
 *     export default translate(MyHelloButton);
 *
 * @param {*} BaseComponent The component to decorate
 */

var withTranslate = function withTranslate(BaseComponent) {
  warning(typeof BaseComponent === 'string', "The translate function is a Higher Order Component, and should not be called directly with a translation key. Use the translate function passed as prop to your component props instead:\n\nconst MyHelloButton = ({ translate }) => (\n    <button>{translate('myroot.hello.world')}</button>\n);");

  var TranslatedComponent = function TranslatedComponent(props) {
    var translate = useTranslate();
    var locale = useLocale();
    return React.createElement(BaseComponent, __assign({
      translate: translate,
      locale: locale
    }, props));
  };

  TranslatedComponent.defaultProps = BaseComponent.defaultProps;
  return TranslatedComponent;
};

export default withTranslate;