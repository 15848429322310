var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useInput, useReferenceInputController } from 'ra-core';
import LinearProgress from '../layout/LinearProgress';
import Labeled from './Labeled';
import ReferenceError from './ReferenceError';
/**
 * An Input component for choosing a reference record. Useful for foreign keys.
 *
 * This component fetches the possible values in the reference resource
 * (using `dataProvider.getMatching()`), then delegates rendering
 * to a subcomponent, to which it passes the possible choices
 * as the `choices` attribute.
 *
 * Use it with a selector component as child, like `<AutocompleteInput>`,
 * `<SelectInput>`, or `<RadioButtonGroupInput>`.
 *
 * @example
 * export const CommentEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <ReferenceInput label="Post" source="post_id" reference="posts">
 *                 <AutocompleteInput optionText="title" />
 *             </ReferenceInput>
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * @example
 * export const CommentEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <ReferenceInput label="Post" source="post_id" reference="posts">
 *                 <SelectInput optionText="title" />
 *             </ReferenceInput>
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * By default, restricts the possible values to 25. You can extend this limit
 * by setting the `perPage` prop.
 *
 * @example
 * <ReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      perPage={100}>
 *     <SelectInput optionText="title" />
 * </ReferenceInput>
 *
 * By default, orders the possible values by id desc. You can change this order
 * by setting the `sort` prop (an object with `field` and `order` properties).
 *
 * @example
 * <ReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      sort={{ field: 'title', order: 'ASC' }}>
 *     <SelectInput optionText="title" />
 * </ReferenceInput>
 *
 * Also, you can filter the query used to populate the possible values. Use the
 * `filter` prop for that.
 *
 * @example
 * <ReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      filter={{ is_published: true }}>
 *     <SelectInput optionText="title" />
 * </ReferenceInput>
 *
 * The enclosed component may filter results. ReferenceInput passes a `setFilter`
 * function as prop to its child component. It uses the value to create a filter
 * for the query - by default { q: [searchText] }. You can customize the mapping
 * searchText => searchQuery by setting a custom `filterToQuery` function prop:
 *
 * @example
 * <ReferenceInput
 *      source="post_id"
 *      reference="posts"
 *      filterToQuery={searchText => ({ title: searchText })}>
 *     <SelectInput optionText="title" />
 * </ReferenceInput>
 */

export var ReferenceInput = function ReferenceInput(_a) {
  var format = _a.format,
      onBlur = _a.onBlur,
      onChange = _a.onChange,
      onFocus = _a.onFocus,
      parse = _a.parse,
      validate = _a.validate,
      props = __rest(_a, ["format", "onBlur", "onChange", "onFocus", "parse", "validate"]);

  var inputProps = useInput(__assign({
    format: format,
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    parse: parse,
    validate: validate
  }, props));
  return React.createElement(ReferenceInputView, __assign({}, inputProps, props, useReferenceInputController(__assign(__assign({}, props), inputProps))));
};
ReferenceInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  filter: PropTypes.object,
  filterToQuery: PropTypes.func.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ASC', 'DESC'])
  }),
  source: PropTypes.string
};
ReferenceInput.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: function filterToQuery(searchText) {
    return {
      q: searchText
    };
  },
  perPage: 25,
  sort: {
    field: 'id',
    order: 'DESC'
  }
};

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var allowEmpty = _a.allowEmpty,
      basePath = _a.basePath,
      choices = _a.choices,
      className = _a.className,
      component = _a.component,
      crudGetMatching = _a.crudGetMatching,
      crudGetOne = _a.crudGetOne,
      defaultValue = _a.defaultValue,
      filter = _a.filter,
      filterToQuery = _a.filterToQuery,
      formClassName = _a.formClassName,
      initializeForm = _a.initializeForm,
      input = _a.input,
      isRequired = _a.isRequired,
      label = _a.label,
      locale = _a.locale,
      meta = _a.meta,
      onChange = _a.onChange,
      optionValue = _a.optionValue,
      optionText = _a.optionText,
      perPage = _a.perPage,
      record = _a.record,
      reference = _a.reference,
      referenceSource = _a.referenceSource,
      resource = _a.resource,
      setFilter = _a.setFilter,
      setPagination = _a.setPagination,
      setSort = _a.setSort,
      sort = _a.sort,
      source = _a.source,
      textAlign = _a.textAlign,
      translateChoice = _a.translateChoice,
      validation = _a.validation,
      rest = __rest(_a, ["allowEmpty", "basePath", "choices", "className", "component", "crudGetMatching", "crudGetOne", "defaultValue", "filter", "filterToQuery", "formClassName", "initializeForm", "input", "isRequired", "label", "locale", "meta", "onChange", "optionValue", "optionText", "perPage", "record", "reference", "referenceSource", "resource", "setFilter", "setPagination", "setSort", "sort", "source", "textAlign", "translateChoice", "validation"]);

  return rest;
};

export var ReferenceInputView = function ReferenceInputView(_a) {
  var allowEmpty = _a.allowEmpty,
      basePath = _a.basePath,
      children = _a.children,
      choices = _a.choices,
      classes = _a.classes,
      className = _a.className,
      error = _a.error,
      helperText = _a.helperText,
      id = _a.id,
      input = _a.input,
      isRequired = _a.isRequired,
      loading = _a.loading,
      label = _a.label,
      meta = _a.meta,
      resource = _a.resource,
      setFilter = _a.setFilter,
      setPagination = _a.setPagination,
      setSort = _a.setSort,
      source = _a.source,
      warning = _a.warning,
      rest = __rest(_a, ["allowEmpty", "basePath", "children", "choices", "classes", "className", "error", "helperText", "id", "input", "isRequired", "loading", "label", "meta", "resource", "setFilter", "setPagination", "setSort", "source", "warning"]);

  if (Children.count(children) !== 1) {
    throw new Error('<ReferenceInput> only accepts a single child');
  }

  if (loading) {
    return React.createElement(Labeled, {
      id: id,
      label: label,
      source: source,
      resource: resource,
      className: className,
      isRequired: isRequired,
      meta: meta,
      input: input
    }, React.createElement(LinearProgress, null));
  } // This is not a final-form error but an unrecoverable error from the
  // useReferenceInputController hook


  if (error) {
    return React.createElement(ReferenceError, {
      label: label,
      error: error
    });
  } // When the useReferenceInputController returns a warning, it means there it
  // had an issue trying to load the referenced record
  // We display it by overriding the final-form meta


  var finalMeta = warning ? __assign(__assign({}, meta), {
    error: warning
  }) : meta;
  return cloneElement(children, __assign({
    allowEmpty: allowEmpty,
    classes: classes,
    className: className,
    input: input,
    isRequired: isRequired,
    label: label,
    resource: resource,
    meta: finalMeta,
    source: source,
    choices: choices,
    basePath: basePath,
    setFilter: setFilter,
    setPagination: setPagination,
    setSort: setSort,
    translateChoice: false
  }, sanitizeRestProps(rest)));
};
export default ReferenceInput;