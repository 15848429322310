import React, { useState } from 'react';
import { ReferenceInput, required, SelectInput, minValue, BooleanInput } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';
import { constant, get } from 'lodash';

import { USER } from '@/vars';
import { useAuth } from '@/utils/auth';
import { Button } from '@/components';
import { TextInput } from '@/components/input';
import { NumberInput, ReferenceRecord } from '@/ra';
import firebase from 'firebase/app';

const EmployeeTypeForm = ({ save, saving, record, redirect, ...rest }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const initialValues = { isActive: true, ...record };

  const [paramsList, setParamsList] = useState(
    record.params ? record.params : [{ tag: '', value: '' }]
  );

  const handleAddInputField = () => {
    setParamsList([...paramsList, { tag: '', value: '' }]);
  };

  const handleRemoveInputField = index => {
    const list = [...paramsList];
    list.splice(index, 1);
    setParamsList(list);
  };

  const handleInputOnChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...paramsList];
    list[index][name] = value;
    setParamsList(list);
  };

  const onSubmit = async data => {
    const form_cardnum = document.forms['myForm']['cardNumber'].value;
    const form_employeenum = document.forms['myForm']['employeeNumber'].value;
    const form_client = document.forms['myForm']['client'].value;

    const employeedoc = await firebase
      .firestore()
      .collection('employees')
      .get();
    let dupScoreEm = 0;
    let dupScoreCar = 0;

    // prevent tag and value log to firestore
    delete data.tag;
    delete data.value;

    employeedoc.docs.forEach(child => {
      const item = child.data();
      if (item.client !== form_client) return;
      if (initialValues.cardNumber === item.cardNumber) return;
      if (form_cardnum === item.cardNumber) dupScoreCar++;
      if (form_employeenum == '' || form_employeenum == null || initialValues.employeeNumber === item.employeeNumber)
        return;
      else if (form_employeenum === item.employeeNumber) dupScoreEm++;
    });
    if (data.employeeNumber == null) data.employeeNumber = '';
    if (dupScoreEm != 0) alert('Employee Number is already taken');
    else if (dupScoreCar != 0) alert('Card Number is already taken');
    else save(data, redirect);
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit, values, modified, form, ...rest }) => {
        return (
          <form name="myForm" onSubmit={handleSubmit}>
            <CardHeader title="Employee details" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput label="Full name" name="fullName" validate={required('Please enter a full name.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Card number" name="cardNumber" validate={required('Please enter a card number.')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Employee number" name="employeeNumber" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Email" name="email" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput label="Mobile" name="mobile" />
                </Grid>
                {isAdmin ? (
                  <Grid item xs={12}>
                    <ReferenceInput
                      source="client"
                      reference="clients"
                      filterToQuery={() => {}}
                      onChange={() => {
                        // reset typeState after client value change
                        const typeState = form.getFieldState('type.id');
                        if (typeState) typeState.change('');
                      }}
                    >
                      <SelectInput
                        optionText="companyName"
                        variant="outlined"
                        fullWidth
                        margin="none"
                        perPage={99999}
                        validate={required('Please select a client.')}
                      />
                    </ReferenceInput>
                  </Grid>
                ) : (
                  <Field component="input" type="hidden" name="client" initialValue={permissions.client_id} />
                )}
                {values.client && (
                  <Grid item xs={12}>
                    <ReferenceInput
                      label="Type"
                      source="type.id"
                      reference="employeeTypes"
                      filterToQuery={() => {}}
                      filter={{ client: values.client }}
                    >
                      <SelectInput
                        optionText="name"
                        variant="outlined"
                        fullWidth
                        margin="none"
                        perPage={99999}
                        validate={required('Please select a type.')}
                      />
                    </ReferenceInput>
                  </Grid>
                )}
                {values.credit && (
                  <Grid item xs={12}>
                    <TextInput label="Cost Center" name="costCenter" />
                  </Grid>
                )}
                {paramsList.map((item, index) => (
                  <Grid container item xs={12} spacing={3} key={index}>
                    <Grid item xs={12} md={5}>
                      <TextInput
                        label="Tag"
                        name="tag"
                        value={item.tag}
                        onChange={e => handleInputOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextInput
                        label="Value"
                        name="value"
                        value={item.value}
                        onChange={e => handleInputOnChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        style={{ width: '100%', height: 56 }}
                        variant="contained"
                        onClick={() => handleRemoveInputField(index)}
                      >
                        {' '}
                        Remove{' '}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
                {get(values, 'type.id') && (
                  <ReferenceRecord id={get(values, 'type.id')} reference="employeeTypes">
                    {typeRecord => {
                      const initialCredit = get(modified, 'type.id')
                        ? get(typeRecord, 'credit')
                        : get(initialValues, 'credit');

                      return (
                        <Grid item xs={12}>
                          <NumberInput
                            label="Current Credit"
                            name="credit"
                            validate={[
                              required('Please enter a credit.'),
                              minValue(0, 'Credit cannot be lower than 0.'),
                            ]}
                            defaultValue={initialCredit}
                          />
                          <Field component="input" type="hidden" name="type.name" initialValue={typeRecord.name} />
                        </Grid>
                      );
                    }}
                  </ReferenceRecord>
                )}
                {values.credit && (
                  <Grid item xs={12} md={6}>
                    <SelectInput
                      variant="outlined"
                      fullWidth
                      margin="none"
                      source="recurringCreditType"
                      label="Recurring Credit Type"
                      reference="recurringCreditType"
                      choices={[
                        { id: 'none', name: 'None' },
                        { id: 'clearOff', name: 'Clear Off' },
                        { id: 'cumulativeCredit', name: 'Cumulative Credit' },
                      ]}
                      defaultValue='none'
                    />
                  </Grid>
                )}
                {values.recurringCreditType && values.recurringCreditType != "none" && (               
                  <Grid item xs={12} md={6}>
                    <TextInput label="Recurring Credit" name="recurringCredit" />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <BooleanInput source="isActive" label="Active" />
                </Grid>
                <Field component="input" type="hidden" name="params" initialValue={paramsList} />
                <Button variant="contained" color="primary" onClick={handleAddInputField} style={{ marginLeft: 12 }}>
                  Add Tag
                </Button>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="primary" loading={saving}>
                Save
              </Button>
            </CardActions>
          </form>
        );
      }}
    </Form>
  );
};

export default EmployeeTypeForm;