var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import IconButton from '@material-ui/core/IconButton';
import ActionHide from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useTranslate } from 'ra-core';
var emptyRecord = {};

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var alwaysOn = _a.alwaysOn,
      props = __rest(_a, ["alwaysOn"]);

  return props;
};

var useStyles = makeStyles(function (theme) {
  return {
    body: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    spacer: {
      width: theme.spacing(2)
    },
    hideButton: {}
  };
});

var FilterFormInput = function FilterFormInput(_a) {
  var filterElement = _a.filterElement,
      handleHide = _a.handleHide,
      classesOverride = _a.classes,
      resource = _a.resource,
      variant = _a.variant,
      margin = _a.margin;
  var translate = useTranslate();
  var classes = useStyles({
    classes: classesOverride
  });
  return React.createElement("div", {
    "data-source": filterElement.props.source,
    className: classnames('filter-field', classes.body)
  }, !filterElement.props.alwaysOn && React.createElement(IconButton, {
    className: classnames('hide-filter', classes.hideButton),
    onClick: handleHide,
    "data-key": filterElement.props.source,
    title: translate('ra.action.remove_filter')
  }, React.createElement(ActionHide, null)), React.createElement(Field, __assign({
    allowEmpty: true
  }, sanitizeRestProps(filterElement.props), {
    name: filterElement.props.source,
    component: filterElement.type,
    resource: resource,
    record: emptyRecord,
    variant: variant,
    margin: margin
  })), React.createElement("div", {
    className: classes.spacer
  }, "\xA0"));
};

FilterFormInput.propTypes = {
  filterElement: PropTypes.node,
  handleHide: PropTypes.func,
  classes: PropTypes.object,
  resource: PropTypes.string
};
export default FilterFormInput;