import React from 'react';
import { List, Datagrid, TextField, NumberField, DateField } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { TRANSACTION, MACHINE } from '@/vars';
import { TRANSACTIONS } from '@/vars/resources';

const EmployeeTransactionList = ({ staticContext, ...props }) => {
  const filter = {
    ...useDefaultFilter('client'),
    status: TRANSACTION.STATUS.SUCCESS,
    mode: MACHINE.MODE.MRO,
    'employee.id': props.id,
  };

  const listProps = {
    ...props,
    resource: TRANSACTIONS,
    hasCreate: false,
    hasEdit: false,
    hasShow: false,
    hasList: true,

    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
  };

  return (
    <List {...listProps}>
      <Datagrid>
        <DateField label="Date/Time" source="date" showTime />
        <TextField label="Machine UUID" source="machine.machineUUID" />
        <TextField label="Product name" source="product.name" />
        <TextField label="QTY" source="quantity" />
        <NumberField source="amount" label="Price (Credit)" />
      </Datagrid>
    </List>
  );
};

export default EmployeeTransactionList;
