import React from 'react';
import {
  TextInput as RaTextInput,
  SelectInput as RaSelectInput,
  NumberInput as RaNumberInput,
  AutocompleteInput as RaAutocompleteInput,
} from 'react-admin';
import CheckboxGroupInput from './CheckboxGroupInput';

const TextInput = props => <RaTextInput fullWidth margin="none" variant="outlined" {...props} />;
const SelectInput = props => <RaSelectInput fullWidth margin="none" variant="outlined" {...props} />;
const AutocompleteInput = props => (
  <RaAutocompleteInput options={{ fullWidth: true }} margin="none" variant="outlined" {...props} />
);
const NumberInput = props => <RaNumberInput fullWidth margin="none" variant="outlined" {...props} />;

export { TextInput, SelectInput, NumberInput, CheckboxGroupInput, AutocompleteInput };
