import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/ExpandMoreOutlined';

function ActionMenu({ basePath, record = {}, resource, children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button aria-haspopup="true" onClick={handleClick} size="small">
        More
        <MoreIcon className={classes.icon} />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {children.map((el, index) => (
          <MenuItem key={index} disableGutters className={classes.menuItem} onClick={handleClose}>
            {React.cloneElement(el, {
              basePath,
              record,
              resource,

              className: classes.actionButton,
              fullWidth: true,
              disableRipple: true,
              variant: 'text',
              icon: null,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    width: 20,
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  actionButton: {
    justifyContent: 'flex-start',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default ActionMenu;
