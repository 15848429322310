import React from 'react';
import { Form } from 'react-final-form';
import { Card, CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';
import { useNotify, required, minValue, ReferenceInput, useDataProvider } from 'react-admin';
import { useSelector } from 'react-redux';
import { useAuth, Can, ACCESS } from '@/utils/auth';
import { EMPLOYEES } from '@/vars/resources';
import { Button, Page } from '@/components';
import { AutocompleteInput, NumberInput } from '@/ra/input';

const Topup = props => {
  const { permissions } = useAuth();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const employeesList = useSelector(state =>
    state.admin.resources[EMPLOYEES] ? state.admin.resources[EMPLOYEES].data : {}
  );

  const onSubmit = async (data, form) => {
    const { employeeId, credit } = data;
    const employeeData = employeesList[employeeId];
    const currentCredit = employeeData.credit;

    try {
      await dataProvider.update(EMPLOYEES, {
        id: employeeId,
        data: { credit: currentCredit + credit },
      });
      notify('Successfully topup credit', 'info', { smart_count: 1 }, false);
    } catch (error) {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
    }
  };

  const employeeOptionRenderer = choice => `${choice.fullName} [${choice.cardNumber}]`;

  return (
    <Can module={ACCESS.MRO}>
      <Page sectionTitle="MRO" title="Topup">
        <Card>
          <Form onSubmit={onSubmit} validateOnBlur>
            {({ handleSubmit, submitting, form, ...rest }) => {
              return (
                <form
                  onSubmit={e => {
                    handleSubmit(e).then(() => {
                      form.reset();
                    });
                  }}
                  noValidate
                >
                  <CardHeader title="Search by employee to top-up their credit." />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <ReferenceInput
                          label="Employee"
                          source="employeeId"
                          reference={EMPLOYEES}
                          filterToQuery={() => {}}
                          filter={{ client: permissions.client_id || undefined }}
                          perPage={99999}
                          validate={required('Please select a employee.')}
                        >
                          <AutocompleteInput optionText={employeeOptionRenderer} />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <NumberInput
                          label="Credit"
                          name="credit"
                          validate={[required('Please enter a credit.'), minValue(0, 'Credit cannot be lower than 0.')]}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button type="submit" variant="contained" color="primary" loading={submitting}>
                      Top-up
                    </Button>
                  </CardActions>
                </form>
              );
            }}
          </Form>
        </Card>
      </Page>
    </Can>
  );
};

export default Topup;
