import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';

const CompanyField = props => {
  const { record, className } = props;
  if (!get(record, 'client.id')) {
    return (
      <Typography component="span" variant="body2" style={{ fontStyle: 'italic' }} className={className}>
        (unassigned)
      </Typography>
    );
  }

  return (
    <ReferenceField source="client.id" reference="clients" link={false} {...props}>
      <TextField source="companyName" className={className} />
    </ReferenceField>
  );
};

export default CompanyField;
