import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, DeleteButton, ReferenceField } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER } from '@/vars';
import { Filter, GroupField } from '@/ra';
import { Page } from '@/components';

const EmployeeTypeList = props => {
  const { permissions } = useAuth();
  const filter = useDefaultFilter('client');
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  const searchFilter = <Filter searchKeys={['name']} />;

  return (
    <Can module={ACCESS.MRO}>
      <Page sectionTitle="MRO" title="List types">
        <List {...props} filter={filter} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
          <Datagrid>
            <TextField source="name" label="Employee type" />
            <TextField source="id" label="Type id" />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <NumberField source="credit" label="Credit" />
            <GroupField textAlign="right">
              <EditButton icon={null} />
              <DeleteButton icon={null} />
            </GroupField>
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default EmployeeTypeList;
