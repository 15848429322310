import parseDate from 'date-fns/parse';
export var isNumeric = function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
};
export var valuesAreNumeric = function valuesAreNumeric(values) {
  return values.every(isNumeric);
};
export var isInteger = function isInteger(value) {
  return Number.isInteger(value);
};
export var valuesAreInteger = function valuesAreInteger(values) {
  return values.every(isInteger);
};
export var isBoolean = function isBoolean(value) {
  return typeof value === 'boolean';
};
export var valuesAreBoolean = function valuesAreBoolean(values) {
  return values.every(isBoolean);
};
export var isString = function isString(value) {
  return typeof value === 'string';
};
export var valuesAreString = function valuesAreString(values) {
  return values.every(isString);
};
var HtmlRegexp = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/i;
export var isHtml = function isHtml(value) {
  return HtmlRegexp.test(value);
};
export var valuesAreHtml = function valuesAreHtml(values) {
  return values.every(isHtml);
};
export var isArray = function isArray(value) {
  return Array.isArray(value);
};
export var valuesAreArray = function valuesAreArray(values) {
  return values.every(isArray);
};
export var isDate = function isDate(value) {
  return value instanceof Date;
};
export var valuesAreDate = function valuesAreDate(values) {
  return values.every(isDate);
};
export var isDateString = function isDateString(value) {
  return typeof value === 'string' && !isNaN(parseDate(value).getDate());
};
export var valuesAreDateString = function valuesAreDateString(values) {
  return values.every(isDateString);
};
export var isObject = function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
};
export var valuesAreObject = function valuesAreObject(values) {
  return values.every(isObject);
};