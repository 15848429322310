var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import React from 'react';
import { render } from '@testing-library/react';
import TestContext from './TestContext';
/**
 * render with react-testing library adding redux context for unit test.
 * @example
 * const { dispatch, reduxStore, ...otherReactTestingLibraryHelper } = renderWithRedux(
 *     <TestedComponent />,
 *     initialState
 * );
 *
 * @param {ReactNode} component: The component you want to test in jsx
 * @param {Object} initialState: Optional initial state of the redux store
 * @param {Object} options: Render options, e.g. to use a custom container element
 * @return {{ dispatch, reduxStore, ...rest }} helper function to test rendered component.
 * Same as @testing-library/react render method with added dispatch and reduxStore helper
 * dispatch: spy on the redux stroe dispatch method
 * reduxStore: the redux store used by the tested component
 */

export default (function (component, initialState, options) {
  if (initialState === void 0) {
    initialState = {};
  }

  if (options === void 0) {
    options = {};
  }

  var dispatch;
  var reduxStore;
  var renderResult = render(React.createElement(TestContext, {
    initialState: initialState,
    enableReducers: true
  }, function (_a) {
    var store = _a.store;
    dispatch = jest.spyOn(store, 'dispatch');
    reduxStore = store;
    return component;
  }), options);
  return __assign(__assign({}, renderResult), {
    rerender: function rerender(newComponent) {
      return renderResult.rerender(React.createElement(TestContext, {
        initialState: initialState,
        enableReducers: true
      }, function (_a) {
        var store = _a.store;
        dispatch = jest.spyOn(store, 'dispatch');
        reduxStore = store;
        return newComponent;
      }));
    },
    dispatch: dispatch,
    reduxStore: reduxStore
  });
});