import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox, Typography } from '@material-ui/core';

// Not implement nor tested validation on checkbox group yet.
const CheckboxGroupInput = ({ options = [], name, label, helperText, disabled, ...rest }) => {
  return (
    <FormControl component="fieldset">
      {label && <Typography gutterBottom variant="h6" children={label} />}
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Field type="checkbox" name={name} {...option}>
                {({ input }) => <Checkbox {...input} {...option} disabled={disabled || option.disabled} />}
              </Field>
            }
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxGroupInput;
