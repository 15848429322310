import React, { useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  ExportButton,
  downloadCSV,
} from 'react-admin';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { db } from '@/vars/firebase';
import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER, TRANSACTION, MACHINE } from '@/vars';
import { Page } from '@/components';
import TransactionFilter from './TransactionFilter';
import CustomizedDialogs from './TransactionDetails';
import Toolbar from '@material-ui/core/Toolbar';
import jsonExport from 'jsonexport/dist';
import {
  renderDate,
  renderTime,
  renderProductSlot,
  renderGateWayField,
  renderTransactionStatus,
  statusForExcelReport,
  getProductName,
  getPrice,
  getTotalCashReceived,
  getTotalCashReturned,
  getTransId,
  getRefId,
} from '@/utils/validateCSVFunctions';

const TransactionList = props => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const classes = useStyles();
  const [status, setStatus] = useState(false);
  var filter = { ...useDefaultFilter('client'), mode: MACHINE.MODE.COMMERCIAL };
  if (!isAdmin) filter.status = TRANSACTION.STATUS.SUCCESS;

  /** Flag: showFailTransaction will be deprecated. */
  useEffect(() => {
    (async function() {
      try {
        if (!isAdmin) {
          const clientSnapshot = await db
            .collection('clients')
            .doc(permissions.client_id)
            .get();

          const showFailTransaction = clientSnapshot.get('showFailTransaction');
          setStatus(showFailTransaction);
        } else {
          setStatus(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const listProps = {
    ...props,
    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
    filters: <TransactionFilter />,
  };

  const renderAdminMoreButtonField = record => {
    return (
      <Button
        size="medium"
        onClick={() => {
          console.log(isAdmin);
          setModalOpen(true);
          setModalContent(JSON.stringify(record, undefined, 3));
        }}
      >
        &gt;
      </Button>
    );
  };

  const renderCustomerMoreButtonField = record => {
    return <CustomizedDialogs {...record} className={classes} />;
  };

  const exporter = posts => {
    const datas = posts.map(post => {
      const date = renderDate(post);
      const time = renderTime(post);
      const slot = renderProductSlot(post);
      const gateway = renderGateWayField(post);
      const status = statusForExcelReport(post.status);
      const totalCashReceived = getTotalCashReceived(post);
      const totalCashReturned = getTotalCashReturned(post);
      const transId = getTransId(post);
      const refNo = getRefId(post);
      const balance = totalCashReceived - totalCashReturned ? totalCashReceived - totalCashReturned : '';
      const productName = getProductName(post);
      const price = getPrice(post);

      const data = {
        Id: post.id,
        MachineUUID: post.machine.machineUUID,
        Date: date,
        Time: time,
        Product: productName,
        Slot: slot,
        'Price (RM)': price,
        Gateway: gateway,
        Status: status,
        'Total Cash Received': totalCashReceived,
        'Total Cash Returned': totalCashReturned,
        Balance: balance,
        'Transaction Id': transId,
        'Reference No': refNo,
      };
      return data;
    });
    jsonExport(datas, (err, csv) => {
      downloadCSV(csv, 'Transactions');
    });
  };

  const PostActions = ({
    basePath,
    currentSort,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    total,
  }) => {

    /** 
      undefined to not set the sensor
      if filterValues.status is 2: admin = undefined; client = undefined;
      if filterValues.status is undefined: admin = undefined; client = 1;
      if filterValues.status is others: admin = others; client = others;
    */
    if (!filterValues.status) 
    {
      if (!isAdmin) filter.status = TRANSACTION.STATUS.SUCCESS
      else filter.status =  filterValues.status;
    } else if (filterValues.status === 2) {
      filter.status = undefined;
    } else {
      filter.status =  filterValues.status;
    }
   
    return (
      <Toolbar>
        {filter &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <ExportButton
          disabled={total === 0}
          maxResults={total}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...filter }}
          exporter={exporter}
        />
      </Toolbar>
    );
  };

  return (
    <Can module={ACCESS.REPORT}>
      <Page sectionTitle="Sales Report" title="List transactions">
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            overflowY: 'hidden',
            overflowX: 'scroll',
          }}
        >
          <List {...listProps} actions={<PostActions />}>
            <Datagrid>
              <TextField label="Machine UUID" source="machine.machineUUID" />
              <FunctionField label="Date" render={renderDate} />
              <FunctionField label="Time" render={renderTime} />
              {isAdmin && (
                <ReferenceField label="Company" source="client" reference="clients" link={false}>
                  <TextField source="companyName" />
                </ReferenceField>
              )}

              <TextField label="Product Name" source="product.name" />
              <FunctionField label="Product Slot" render={renderProductSlot} />
              <NumberField
                source="amount"
                label="Product Price (RM)"
                locales="en-MY"
                options={{ style: 'currency', currency: 'MYR' }}
                textAlign="left"
              />
              <FunctionField label="Gateway" render={renderGateWayField} />
              <FunctionField label="Status" render={renderTransactionStatus} />
              {isAdmin ? (
                <FunctionField label="" render={renderAdminMoreButtonField} />
              ) : (
                <FunctionField label="" render={renderCustomerMoreButtonField} />
              )}
            </Datagrid>
          </List>
        </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className={classes.paper}>
            <pre>{modalContent}</pre>
          </div>
        </Modal>
      </Page>
    </Can>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    maxHeight: '90%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default TransactionList;
