import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@/components';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900],
    },
    marginLeft: 'auto',
  },
}));

const DeleteButton = props => {
  const classes = useStyles();
  return (
    <Button {...props} className={clsx(classes.deleteButton, props.className)}>
      Delete
    </Button>
  );
};

export default DeleteButton;
