import React, { useState, createContext, useContext, useEffect } from 'react';
import { TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';

import AuthLayout from '@/layouts/AuthLayout';
import routes from '@/vars/routes';
import Button from '@/components/Button';
import { functions } from '@/vars/firebase';
import PasswordChecklist from 'react-password-checklist';

function ResetPassword({ location }) {
  const params = new URLSearchParams(location.search);
  // Parameters are from Firebase https://firebase.google.com/docs/auth/custom-email-handler
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');

  const [state, setState] = useState({
    actionCode: null,
    email: null,
    isSubmit: false,
    isValidLink: false,
    initialized: false,
  });

  function handleRequestComplete() {
    setState({ ...state, isSubmit: true });
  }

  useEffect(() => {
    if (mode !== 'resetPassword') {
      setState(s => ({ ...s, initialized: true, isValidLink: false }));
      return;
    }

    (async function() {
      try {
        const email = await firebase.auth().verifyPasswordResetCode(actionCode);
        setState(s => ({ ...s, actionCode, initialized: true, isValidLink: true, email }));
      } catch (e) {
        setState(s => ({ ...s, initialized: true, isValidLink: false }));
      }
    })();
  }, [actionCode, mode]);

  if (!state.initialized) {
    return <AuthLayout heading="Reset your password" subHeading="Loading..." />;
  }

  return (
    <ResetPasswordContext.Provider value={{ ...state, handleRequestComplete }}>
      {state.isValidLink ? state.isSubmit ? <Success /> : <ResetPasswordForm /> : <InvalidLink />}
    </ResetPasswordContext.Provider>
  );
}

function ResetPasswordForm() {
  const classes = useStyles();
  const { handleSubmit, register, errors, setError } = useForm();
  const { email, actionCode, handleRequestComplete } = useContext(ResetPasswordContext);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handlePasswordConfirmChange = e => {
    const newPasswordAgain = e.target.value;
    setConfirmPassword(newPasswordAgain);
  };

  const onSubmit = async values => {
    try {
      const { password, confirmPassword } = values;
      if (confirmPassword !== password) {
        return setError('confirmPassword', null, 'Confirm password must match with the new password.');
      }

      setLoading(true);
      // await firebase.auth().confirmPasswordReset(actionCode, password);
      await functions.httpsCallable('auth-handleResetPassword')({ oobCode: actionCode, password: password });
      handleRequestComplete();
    } catch (error) {
      setLoading(false);
      const { code, message } = error;
      switch (code) {
        case 'auth/weak-password':
          return setError('password', code, message);
        default:
          return setError('password', code, 'Something went wrong while re-setting your password. Please try again.');
      }
    }
  };

  const message = (
    <span>
      Please enter the new password for your account <i>{email}.</i>
    </span>
  );

  return (
    <AuthLayout heading="Reset your password" subHeading={message}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate method="POST">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="New Password"
          id="password"
          name="password"
          type="password"
          autoComplete="on"
          autoFocus
          onChange={e => handlePasswordChange(e)}
          inputRef={register({
            required: 'Please re-enter your new password for confirmation.',
            pattern: {
              value: /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[0-9]).*$/,
              message: 'Password must contain at least one special character, one uppercase letter, and one number',
            },
            minLength: { value: 8, message: 'Password must contain at least 8 characters.' },
          })}
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          placeholder="Confirm password"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          autoComplete="off"
          onChange={e => handlePasswordConfirmChange(e)}
          inputRef={register({
            required: 'Please re-enter your new password for confirmation.',
            pattern: {
              value: /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[0-9]).*$/,
              message: 'Password must contain at least one special character, one uppercase letter, and one number',
            },
            minLength: { value: 8, message: 'Password must contain at least 8 characters.' },
          })}          
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword && errors.confirmPassword.message}
        />
        <PasswordChecklist
          rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
          minLength={8}
          value={password}
          valueAgain={confirmPassword}
          onChange={isValid => {}}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loading={loading}
        >
          Submit
        </Button>
      </form>
    </AuthLayout>
  );
}

function Success() {
  const { email } = useContext(ResetPasswordContext);
  const classes = useStyles();

  return (
    <AuthLayout heading="Password changed successfully" subHeading="Please login again.">
      <Button
        variant="contained"
        component={Link}
        to={`${routes.signIn}?src=resetPassword&e=${email}`}
        className={classes.buttonLink}
        color="primary"
      >
        Login Now
      </Button>
    </AuthLayout>
  );
}

function InvalidLink() {
  const classes = useStyles();
  return (
    <AuthLayout
      heading="Invalid password reset link"
      subHeading="Looks like you entered an invalid link or the link has expired. Please try requesting to reset your password again."
    >
      <Box display="flex" flexDirection="row" className={classes.actionWrapper}>
        <Button
          component={Link}
          to={routes.dashboard}
          className={`${classes.buttonLink} ${classes.subButtonLink}`}
          color="primary"
        >
          Go back to home
        </Button>
        <Button
          variant="contained"
          component={Link}
          to={routes.forgotPassword}
          className={classes.buttonLink}
          color="primary"
        >
          Request Reset Password
        </Button>
      </Box>
    </AuthLayout>
  );
}

const ResetPasswordContext = createContext();
export default ResetPassword;

/* Styling */
const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  actionWrapper: {
    marginTop: theme.spacing(3),
    width: '100%',
    justifyContent: 'center',
  },

  buttonLink: {
    textDecoration: 'none !important',
  },
  subButtonLink: {
    marginRight: theme.spacing(2),
  },
  goBackLinkIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5),
  },
}));
