import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, DeleteButton, ReferenceField } from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth, useClientHas } from '@/utils/auth';
import { USER } from '@/vars';
import { ActionMenu, Filter } from '@/ra';
import { Page } from '@/components';
import ImageField from './ImageField';

const AdminList = props => (
  <Datagrid {...props}>
    <ImageField source="image.src" label="Image" />
    <TextField source="name" label="Name" />
    <ReferenceField label="Company" source="client" reference="clients" link={false}>
      <TextField source="companyName" />
    </ReferenceField>
    <TextField source="description" label="Description" />
    <TextField source="sku" label="SKU" />
    <NumberField
      source="priceCash"
      label="Price (RM)"
      locales="en-MY"
      options={{ style: 'currency', currency: 'MYR' }}
    />
    <NumberField source="priceCredit" label="Price (Credit)" />
    <ActionMenu label="Actions">
      <EditButton icon={null} />
      <DeleteButton icon={null} />
    </ActionMenu>
  </Datagrid>
);

const ClientList = ({ clientId, ...props }) => {
  const hasMRO = useClientHas(clientId, ACCESS.MRO);

  return (
    <Datagrid {...props}>
      <ImageField source="image.src" label="Image" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
      {hasMRO && <TextField source="sku" label="SKU" />}
      <NumberField
        source="priceCash"
        label="Price (RM)"
        locales="en-MY"
        options={{ style: 'currency', currency: 'MYR' }}
      />
      {hasMRO && <NumberField source="priceCredit" label="Price (Credit)" />}
      <ActionMenu label="Actions">
        <EditButton icon={null} />
        <DeleteButton icon={null} />
      </ActionMenu>
    </Datagrid>
  );
};

const ProductList = props => {
  const { permissions } = useAuth();
  const filter = useDefaultFilter('client');
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const clientId = permissions.client_id;

  const searchFilter = <Filter searchKeys={['name']} />;

  return (
    <Can module={ACCESS.INVENTORY}>
      <Page sectionTitle="inventory" title="List product">
        <List {...props} filter={filter} sort={{ field: 'createdAt' }} filters={searchFilter} perPage={25}>
          {isAdmin ? <AdminList /> : <ClientList clientId={clientId} />}
        </List>
      </Page>
    </Can>
  );
};

export default ProductList;
