var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import React, { useCallback, useEffect, useRef, useMemo, isValidElement } from 'react';
import Downshift from 'downshift';
import classNames from 'classnames';
import get from 'lodash/get';
import { makeStyles, TextField, Chip } from '@material-ui/core';
import { useInput, FieldTitle, useSuggestions, warning } from 'ra-core';
import InputHelperText from './InputHelperText';
import AutocompleteSuggestionList from './AutocompleteSuggestionList';
import AutocompleteSuggestionItem from './AutocompleteSuggestionItem';
/**
 * An Input component for an autocomplete field, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <AutocompleteArrayInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <AutocompleteArrayInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <AutocompleteArrayInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * Note that you must also specify the `matchSuggestion` prop
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const matchSuggestion = (filterValue, choice) => choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />} matchSuggestion={matchSuggestion} />
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <AutocompleteArrayInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <AutoComplete> component
 *
 * @example
 * <AutocompleteArrayInput source="author_id" options={{ fullWidthInput: true }} />
 */

var AutocompleteArrayInput = function AutocompleteArrayInput(_a) {
  var allowEmpty = _a.allowEmpty,
      classesOverride = _a.classes,
      _b = _a.choices,
      choices = _b === void 0 ? [] : _b,
      emptyText = _a.emptyText,
      emptyValue = _a.emptyValue,
      format = _a.format,
      helperText = _a.helperText,
      idOverride = _a.id,
      inputOverride = _a.input,
      isRequiredOverride = _a.isRequired,
      label = _a.label,
      limitChoicesToValue = _a.limitChoicesToValue,
      margin = _a.margin,
      matchSuggestion = _a.matchSuggestion,
      metaOverride = _a.meta,
      onBlur = _a.onBlur,
      onChange = _a.onChange,
      onFocus = _a.onFocus,
      _c = _a.options,
      _d = _c === void 0 ? {} : _c,
      suggestionsContainerProps = _d.suggestionsContainerProps,
      labelProps = _d.labelProps,
      InputProps = _d.InputProps,
      options = __rest(_d, ["suggestionsContainerProps", "labelProps", "InputProps"]),
      _e = _a.optionText,
      optionText = _e === void 0 ? 'name' : _e,
      _f = _a.optionValue,
      optionValue = _f === void 0 ? 'id' : _f,
      parse = _a.parse,
      resource = _a.resource,
      setFilter = _a.setFilter,
      shouldRenderSuggestionsOverride = _a.shouldRenderSuggestions,
      source = _a.source,
      suggestionLimit = _a.suggestionLimit,
      _g = _a.translateChoice,
      translateChoice = _g === void 0 ? true : _g,
      validate = _a.validate,
      _h = _a.variant,
      variant = _h === void 0 ? 'filled' : _h,
      rest = __rest(_a, ["allowEmpty", "classes", "choices", "emptyText", "emptyValue", "format", "helperText", "id", "input", "isRequired", "label", "limitChoicesToValue", "margin", "matchSuggestion", "meta", "onBlur", "onChange", "onFocus", "options", "optionText", "optionValue", "parse", "resource", "setFilter", "shouldRenderSuggestions", "source", "suggestionLimit", "translateChoice", "validate", "variant"]);

  warning(isValidElement(optionText) && !matchSuggestion, "If the optionText prop is a React element, you must also specify the matchSuggestion prop:\n<AutocompleteInput\n    matchSuggestion={(filterValue, suggestion) => true}\n/>\n        ");
  var classes = useStyles({
    classes: classesOverride
  });
  var inputEl = useRef();
  var anchorEl = useRef();

  var _j = useInput(__assign({
    format: format,
    id: idOverride,
    input: inputOverride,
    isRequired: isRequiredOverride,
    meta: metaOverride,
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    parse: parse,
    resource: resource,
    source: source,
    validate: validate
  }, rest)),
      id = _j.id,
      input = _j.input,
      isRequired = _j.isRequired,
      _k = _j.meta,
      touched = _k.touched,
      error = _k.error;

  var _l = React.useState(''),
      filterValue = _l[0],
      setFilterValue = _l[1];

  var getSuggestionFromValue = useCallback(function (value) {
    return choices.find(function (choice) {
      return get(choice, optionValue) === value;
    });
  }, [choices, optionValue]);
  var selectedItems = useMemo(function () {
    return (input.value || []).map(getSuggestionFromValue);
  }, [input.value, getSuggestionFromValue]);

  var _m = useSuggestions({
    allowEmpty: allowEmpty,
    choices: choices,
    emptyText: emptyText,
    emptyValue: emptyValue,
    limitChoicesToValue: limitChoicesToValue,
    matchSuggestion: matchSuggestion,
    optionText: optionText,
    optionValue: optionValue,
    selectedItem: selectedItems,
    suggestionLimit: suggestionLimit,
    translateChoice: translateChoice
  }),
      getChoiceText = _m.getChoiceText,
      getChoiceValue = _m.getChoiceValue,
      getSuggestions = _m.getSuggestions;

  var handleFilterChange = useCallback(function (eventOrValue) {
    var event = eventOrValue;
    var value = event.target ? event.target.value : eventOrValue;
    setFilterValue(value);

    if (setFilter) {
      setFilter(value);
    }
  }, [setFilter, setFilterValue]); // We must reset the filter every time the value changes to ensure we
  // display at least some choices even if the input has a value.
  // Otherwise, it would only display the currently selected one and the user
  // would have to first clear the input before seeing any other choices

  useEffect(function () {
    handleFilterChange('');
  }, [input.value, handleFilterChange]);
  var handleKeyDown = useCallback(function (event) {
    // Remove latest item from array when user hits backspace with no text
    if (selectedItems.length && !filterValue.length && event.key === 'Backspace') {
      var newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
      input.onChange(newSelectedItems.map(getChoiceValue));
    }
  }, [filterValue.length, getChoiceValue, input, selectedItems]);
  var handleChange = useCallback(function (item) {
    var newSelectedItems = selectedItems.includes(item) ? __spreadArrays(selectedItems) : __spreadArrays(selectedItems, [item]);
    setFilterValue('');
    input.onChange(newSelectedItems.map(getChoiceValue));
  }, [getChoiceValue, input, selectedItems, setFilterValue]);
  var handleDelete = useCallback(function (item) {
    return function () {
      var newSelectedItems = __spreadArrays(selectedItems);

      newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
      input.onChange(newSelectedItems.map(getChoiceValue));
    };
  }, [input, selectedItems, getChoiceValue]); // This function ensures that the suggestion list stay aligned to the
  // input element even if it moves (because user scrolled for example)

  var updateAnchorEl = function updateAnchorEl() {
    if (!inputEl.current) {
      return;
    }

    var inputPosition = inputEl.current.getBoundingClientRect(); // It works by implementing a mock element providing the only method used
    // by the PopOver component, getBoundingClientRect, which will return a
    // position based on the input position

    if (!anchorEl.current) {
      anchorEl.current = {
        getBoundingClientRect: function getBoundingClientRect() {
          return inputPosition;
        }
      };
    } else {
      var anchorPosition = anchorEl.current.getBoundingClientRect();

      if (anchorPosition.x !== inputPosition.x || anchorPosition.y !== inputPosition.y) {
        anchorEl.current = {
          getBoundingClientRect: function getBoundingClientRect() {
            return inputPosition;
          }
        };
      }
    }
  };

  var storeInputRef = function storeInputRef(input) {
    inputEl.current = input;
    updateAnchorEl();
  };

  var handleBlur = useCallback(function (event) {
    setFilterValue('');
    handleFilterChange('');
    input.onBlur(event);
  }, [handleFilterChange, input, setFilterValue]);
  var handleFocus = useCallback(function (openMenu) {
    return function (event) {
      openMenu(event);
      input.onFocus(event);
    };
  }, [input]);
  var handleClick = useCallback(function (openMenu) {
    return function (event) {
      if (event.target === inputEl.current) {
        openMenu(event);
      }
    };
  }, []);

  var shouldRenderSuggestions = function shouldRenderSuggestions(val) {
    if (shouldRenderSuggestionsOverride !== undefined && typeof shouldRenderSuggestionsOverride === 'function') {
      return shouldRenderSuggestionsOverride(val);
    }

    return true;
  };

  return React.createElement(Downshift, __assign({
    inputValue: filterValue,
    onChange: handleChange,
    selectedItem: selectedItems,
    itemToString: function itemToString(item) {
      return getChoiceValue(item);
    }
  }, rest), function (_a) {
    var _b, _c;

    var getInputProps = _a.getInputProps,
        getItemProps = _a.getItemProps,
        getLabelProps = _a.getLabelProps,
        getMenuProps = _a.getMenuProps,
        isOpen = _a.isOpen,
        suggestionFilter = _a.inputValue,
        highlightedIndex = _a.highlightedIndex,
        openMenu = _a.openMenu;
    var isMenuOpen = isOpen && shouldRenderSuggestions(suggestionFilter);

    var _d = getInputProps({
      onBlur: handleBlur,
      onFocus: handleFocus(openMenu),
      onClick: handleClick(openMenu),
      onKeyDown: handleKeyDown
    }),
        idFromDownshift = _d.id,
        onBlur = _d.onBlur,
        _onChange = _d.onChange,
        onFocus = _d.onFocus,
        ref = _d.ref,
        inputProps = __rest(_d, ["id", "onBlur", "onChange", "onFocus", "ref"]);

    return React.createElement("div", {
      className: classes.container
    }, React.createElement(TextField, __assign({
      id: id,
      fullWidth: true,
      InputProps: {
        inputRef: storeInputRef,
        classes: {
          root: classNames(classes.inputRoot, (_b = {}, _b[classes.inputRootFilled] = variant === 'filled', _b)),
          input: classes.inputInput
        },
        startAdornment: React.createElement("div", {
          className: classNames((_c = {}, _c[classes.chipContainerFilled] = variant === 'filled', _c))
        }, selectedItems.map(function (item, index) {
          return React.createElement(Chip, {
            key: index,
            tabIndex: -1,
            label: getChoiceText(item),
            className: classes.chip,
            onDelete: handleDelete(item)
          });
        })),
        onBlur: onBlur,
        onChange: function onChange(event) {
          handleFilterChange(event);

          _onChange(event);
        },
        onFocus: onFocus
      },
      error: !!(touched && error),
      label: React.createElement(FieldTitle, __assign({
        label: label
      }, labelProps, {
        source: source,
        resource: resource,
        isRequired: isRequired
      })),
      InputLabelProps: getLabelProps({
        htmlFor: id
      }),
      helperText: touched && error || helperText ? React.createElement(InputHelperText, {
        touched: touched,
        error: error,
        helperText: helperText
      }) : null,
      variant: variant,
      margin: margin
    }, inputProps, options)), React.createElement(AutocompleteSuggestionList, {
      isOpen: isMenuOpen,
      menuProps: getMenuProps({}, // https://github.com/downshift-js/downshift/issues/235
      {
        suppressRefError: true
      }),
      inputEl: inputEl.current,
      suggestionsContainerProps: suggestionsContainerProps
    }, getSuggestions(suggestionFilter).map(function (suggestion, index) {
      return React.createElement(AutocompleteSuggestionItem, __assign({
        key: getChoiceValue(suggestion),
        suggestion: suggestion,
        index: index,
        highlightedIndex: highlightedIndex,
        isSelected: selectedItems.map(getChoiceValue).includes(getChoiceValue(suggestion)),
        filterValue: filterValue,
        getSuggestionText: getChoiceText
      }, getItemProps({
        item: suggestion
      })));
    })));
  });
};

var useStyles = makeStyles(function (theme) {
  var chipBackgroundColor = theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.09)';
  return {
    root: {
      flexGrow: 1,
      height: 250
    },
    container: {
      flexGrow: 1,
      position: 'relative'
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0
    },
    chip: {
      margin: theme.spacing(0.5, 0.5, 0.5, 0)
    },
    chipContainerFilled: {
      margin: '27px 12px 10px 0'
    },
    inputRoot: {
      flexWrap: 'wrap'
    },
    inputRootFilled: {
      flexWrap: 'wrap',
      '& $chip': {
        backgroundColor: chipBackgroundColor
      }
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1
    },
    divider: {
      height: theme.spacing(2)
    }
  };
});
export default AutocompleteArrayInput;