import { createContext } from 'react';
var defaultProvider = {
  login: function login() {
    return Promise.resolve();
  },
  logout: function logout() {
    return Promise.resolve();
  },
  checkAuth: function checkAuth() {
    return Promise.resolve();
  },
  checkError: function checkError() {
    return Promise.resolve();
  },
  getPermissions: function getPermissions() {
    return Promise.resolve();
  }
};
var AuthContext = createContext(defaultProvider);
AuthContext.displayName = 'AuthContext';
export default AuthContext;