import React from 'react';
import { Box, colors } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { MACHINE } from '@/vars';
import { Label } from '@/components';

export const StatusField = ({ record, variant = 'contained' }) => {
  if (isEmpty(record.status)) return null;

  const status = Object.keys(record.status) || [];
  const statusColor = {
    [MACHINE.STATUS.ONLINE]: colors.green[500],
    [MACHINE.STATUS.OFFLINE]: colors.grey[500],
    [MACHINE.STATUS.ERROR]: colors.red[500],
    [MACHINE.STATUS.BILL_ERROR]: colors.red[500],
    [MACHINE.STATUS.COIN_ERROR]: colors.red[500],
    [MACHINE.STATUS.OUT_OF_STOCK]: colors.orange[500],
  };
  //remove terminal error status
  const status_no_error = status.filter(s => !s.includes('terminal_error'));
  return (
    <>
      {status_no_error.map((item, index) => (
        <Box display="inline-block" mx={0.5} key={index}>
          <Label color={statusColor[item]} variant={variant}>
            {item}
          </Label>
        </Box>
      ))}
    </>
  );
};

export const StatusFieldInMachineInfo = ({ record, variant = 'contained' }) => {
  if (isEmpty(record.status)) return null;

  const status = Object.keys(record.status) || [];
  const statusColor = {
    [MACHINE.STATUS.ONLINE]: colors.green[500],
    [MACHINE.STATUS.OFFLINE]: colors.grey[500],
    [MACHINE.STATUS.ERROR]: colors.red[500],
    [MACHINE.STATUS.BILL_ERROR]: colors.red[500],
    [MACHINE.STATUS.COIN_ERROR]: colors.red[500],
    [MACHINE.STATUS.OUT_OF_STOCK]: colors.orange[500],
  };
  
  var isOnline = false;
  const upperline = [];
  if (status.indexOf('online') !== -1) {
    isOnline = true;
    upperline.push(status.splice(status.indexOf('online'), 1));
  }

  if (status.indexOf('offline') !== -1) {
    isOnline = false;
    upperline.push(status.splice(status.indexOf('offline'), 1));
  }

  if (status.indexOf(MACHINE.STATUS.ERROR) !== -1)
    upperline.push(status.splice(status.indexOf(MACHINE.STATUS.ERROR), 1));
  if (status.indexOf(MACHINE.STATUS.OUT_OF_STOCK) !== -1)
    upperline.push(status.splice(status.indexOf(MACHINE.STATUS.OUT_OF_STOCK), 1));

  return (
    <>
      <Box display="block" mb={0.5}>
        {upperline.map((item, index) => (
          <Box display="inline" mx={0.5} key={index}>
            <Label color={statusColor[item]} variant={variant}>
              {item}
            </Label>
          </Box>
        ))}
      </Box>
      {status.map((item, index) => (
        <Box display="inline-block" mx={0.5} key={index}>
          <Label color={statusColor[item]} variant={variant}>
            {item}
          </Label>
        </Box>
      ))}
    </>
  );
};
