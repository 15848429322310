import {
  HomeOutlined as DashboardIcon,
  PeopleOutlineOutlined as UsersIcon,
  SettingsOutlined as SettingsIcon,
  GridOnOutlined as InventoryManagementIcon,
  ChromeReaderModeOutlined as VendingMachineIcon,
  TimelineOutlined as SalesReportIcon,
  // SettingsRemoteOutlined as RemoteControlIcon,
  BuildOutlined as MroIcon,
  // SettingsInputComponentOutlined as DigitalSignageIcon,
  BarChart as MROReportIcon,
  // InsertChartOutlinedOutlined as MroReportIcon,
  // MonetizationOnOutlined as PaymentGatewayIcon,
  // ExpandLess as ExpandLessIcon,
  // ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import routes from '@/vars/routes';
import { checkAccess, ACCESS } from '@/utils/auth';

export default function menuConfig(permissions) {
  const allow = (access, menu) => {
    if (checkAccess(permissions, access)) return menu;
    return null;
  };

  return [
    {
      pages: [
        {
          title: 'Dashboard',
          icon: DashboardIcon,
          href: routes.dashboard,
        },
        allow(ACCESS.REPORT, {
          title: 'Sales Report',
          icon: SalesReportIcon,
          href: routes.transactions,
        }),
        allow(ACCESS.MRO_REPORT, {
          title: 'MRO Report',
          icon: MROReportIcon,
          // href: routes.mroTransactions,
          children: [
            { title: 'By Transaction', href: routes.mroTransactions },
            { title: 'By Product', href: routes.mroTransactionsByProduct },
            { title: 'By Employee', href: routes.mroTransactionsByEmployee },
          ],
        }),
        allow(ACCESS.INVENTORY, {
          title: 'Inventory',
          icon: InventoryManagementIcon,
          // href: routes.products,
          children: [{ title: 'Product', href: routes.products }, { title: 'Category', href: routes.categories }],
        }),
        allow(ACCESS.VENDING_MACHINE, {
          title: 'Vending Machine',
          icon: VendingMachineIcon,
          // href: routes.machines,
          children: [
            { title: 'Status', href: routes.machines },
            // { title: 'Stock Replenishment', href: routes.categories },
          ],
        }),
        allow(ACCESS.USER, {
          title: 'Users',
          icon: UsersIcon,
          href: routes.users,
        }),
        allow(ACCESS.MRO, {
          title: 'MRO',
          icon: MroIcon,
          // href: routes.employees,
          children: [
            { title: 'Employee', href: routes.employees },
            { title: 'Type', href: routes.employeeTypes },
            { title: 'Topup', href: routes.mroTopup },
          ],
        }),
        {
          title: 'Settings',
          icon: SettingsIcon,
          // href: routes.profile,
          children: [{ title: 'Profile', href: routes.profile }],
        },
      ],
    },
    // {
    //   title: 'Coming soon',
    //   pages: [
    //     {
    //       title: 'Remote control',
    //       icon: RemoteControlIcon,
    //       href: routes.remoteControl,
    //     },
    //     {
    //       title: 'Digital Signage',
    //       icon: DigitalSignageIcon,
    //       href: routes.digitalSignage,
    //     },
    //   ],
    // },
  ];
}
