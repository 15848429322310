import React from 'react';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import '@/assets/scss/index.scss';
import theme from '@/theme';
import {
  USERS,
  PRODUCTS,
  CATEGORIES,
  LIMITATIONRULES,
  CLIENTS,
  MACHINES,
  TRANSACTIONS,
  EMPLOYEES,
  EMPLOYEE_TYPES,
  MRO_REPORT_PRODUCTS,
  MRO_REPORT_EMPLOYEES,
} from '@/vars/resources';
import customRoutes from '@/customRoutes';
import { authProvider, dataProvider, i18nProvider } from '@/providers';
import customReducers from '@/redux/reducers';

import AppLayout from '@/layouts/AppLayout';
import { UserList, UserCreate, UserEdit } from '@/pages/users';
import { ProductList, ProductCreate, ProductEdit } from '@/pages/products';
import { CategoryList, CategoryCreate, CategoryEdit } from '@/pages/categories';
import { MachineList, MachineEdit } from '@/pages/machines';
import { TransactionList } from '@/pages/transactions';
import { SignIn } from '@/pages/auth';
import Dashboard from '@/pages/dashboard';
import { EmployeeList, EmployeeEdit, EmployeeCreate } from './pages/employees';
import { EmployeeTypeList, EmployeeTypeEdit, EmployeeTypeCreate } from './pages/employeeTypes';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Admin
        theme={theme}
        history={createBrowserHistory()}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={AppLayout}
        dashboard={Dashboard}
        loginPage={SignIn}
        customRoutes={customRoutes}
        customReducers={customReducers}
      >
        <Resource name={USERS} list={UserList} create={UserCreate} edit={UserEdit} />
        <Resource name={PRODUCTS} list={ProductList} create={ProductCreate} edit={ProductEdit} />
        <Resource name={CATEGORIES} list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
        <Resource name={MACHINES} list={MachineList} edit={MachineEdit} />
        <Resource name={TRANSACTIONS} list={TransactionList} />
        <Resource name={EMPLOYEES} list={EmployeeList} create={EmployeeCreate} edit={EmployeeEdit} />
        <Resource name={EMPLOYEE_TYPES} list={EmployeeTypeList} create={EmployeeTypeCreate} edit={EmployeeTypeEdit} />
        <Resource name={LIMITATIONRULES} />
        <Resource name={CLIENTS} />
        <Resource name={MRO_REPORT_PRODUCTS} />
        <Resource name={MRO_REPORT_EMPLOYEES} />
      </Admin>
    </ThemeProvider>
  );
};

export default App;
