import React, { useEffect, useRef, useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Doughnut } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

import { mainDataColors } from '@/vars/__theme';
import { customTooltip, customTooltipLabelContent, LegendItem } from '@/utils/charts';

// defaults.global = Object.assign({}, defaults.global, {});
const NUM_TOP_X_ITEMS = 3;
const chartColors = mainDataColors.slice(0, NUM_TOP_X_ITEMS);
chartColors.push('#CCCCCC');

function legendCallback(chart) {
  const { datasets, labels } = chart.data;
  const selectedDataset = datasets[0]; // Handle 1st level only
  const { data, backgroundColor } = selectedDataset;

  return (
    <>
      {data.map((d, index) => (
        <LegendItem key={`ttc-${index}`} color={backgroundColor[index]} label={labels[index]} value={d} />
      ))}
    </>
  );
}

function TodaysTransactionChart({ data, loading }) {
  const classes = useStyles();
  const chartRef = useRef(null);

  const [chartOptions, setChartOptions] = useState({
    type: 'doughnut',
    options: {
      cutoutPercentage: 85,
      legend: {
        display: false,
        position: 'right',
      },
      legendCallback: legendCallback.bind(this),
      tooltips: {
        enabled: false,
        custom: customTooltip,
        callbacks: {
          label: customTooltipLabelContent.bind(this, undefined),
        },
      },
    },
    data: {
      labels: [],
      datasets: [{ data: [], backgroundColor: chartColors }],
    },
  });

  // const [chartLegends, setChartLegends] = useState(null);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    if (loading) return;
    const chartData = [];
    const chartLabels = [];

    // Translate server's data into chartjs's options
    if (!isEmpty(data) && !isEmpty(data.values)) {
      const { values } = data;
      const sortedValues = values.sort(({ value: value1 }, { value: value2 }) => value2 - value1);

      const topXItems = sortedValues.slice(0, NUM_TOP_X_ITEMS);
      topXItems.forEach(({ label, value }) => {
        chartData.push(value);
        chartLabels.push(label);
      });

      let otherSumValue = 0;
      if (values.length > NUM_TOP_X_ITEMS) {
        otherSumValue = sortedValues.slice(NUM_TOP_X_ITEMS).reduce(sumReducer, 0);
        chartData.push(otherSumValue);
        chartLabels.push('Others');
      }

      const sumValue = topXItems.reduce(sumReducer, otherSumValue);

      setTotalValue(sumValue);
      setChartOptions(prevChartOptions => ({
        ...prevChartOptions,
        data: {
          labels: chartLabels,
          datasets: [{ data: chartData, backgroundColor: chartColors }],
        },
      }));
      // chartRef.current.chartInstance.update();
      // setChartLegends(chartRef.current.chartInstance.generateLegend());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), loading]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.visWrapper} lg={8} md={12} xs={12}>
        <div className={classes.visTotal}>
          Total transactions: <b>{totalValue}</b>
        </div>
        <Doughnut {...chartOptions} ref={chartRef} />
      </Grid>
      <Grid item className={classes.legends} lg={4} md={12} xs={12}>
        {chartRef.current && chartRef.current.chartInstance.generateLegend()}
      </Grid>
    </Grid>
  );
}

const sumReducer = (total, { value }) => total + value;

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
  },
  visWrapper: {
    position: 'relative',
  },
  visTotal: {
    position: 'absolute',
    textAlign: 'center',
    width: 128,
    height: 40,
    fontSize: '.8rem',
    left: '50%',
    top: '50%',
    marginLeft: -(128 / 2),
    marginTop: -20,
    '& b': {
      display: 'block',
      fontSize: '1rem',
    },
  },
  legends: {
    padding: theme.spacing(1.5),
    paddingLeft: 0,
  },

  [theme.breakpoints.down('md')]: {
    root: {
      flexDirection: 'column',
    },
    legends: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      marginTop: theme.spacing(3),
    },
  },
}));

export default TodaysTransactionChart;
