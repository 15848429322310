import { createContext } from 'react';
var TranslationContext = createContext({
  locale: 'en',
  setLocale: function setLocale() {
    return Promise.resolve();
  },
  i18nProvider: {
    translate: function translate(x) {
      return x;
    },
    changeLocale: function changeLocale() {
      return Promise.resolve();
    },
    getLocale: function getLocale() {
      return 'en';
    }
  }
});
TranslationContext.displayName = 'TranslationContext';
export { TranslationContext };