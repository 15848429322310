import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Copyright from '@/components/Copyright';
import logo from '@/assets/img/logo_vecloud.png';

function AuthLayout(props) {
  const classes = useStyles();
  const { heading, subHeading, children } = props;

  return (
    <Container component="main" maxWidth="sm" className={classes.root}>
      <div className={classes.paper}>
        <img src={logo} alt="VE Cloud" className={classes.logo} draggable={false} />
        {heading && (
          <Typography variant="h3" gutterBottom>
            {heading}
          </Typography>
        )}
        {subHeading && <Typography variant="subtitle1">{subHeading}</Typography>}
        {children}
      </div>
      <Box mt="auto">
        <Copyright />
      </Box>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    textAlign: 'center',
  },
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 280,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 160,
    },
  },
}));

export default AuthLayout;
