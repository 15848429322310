import ReactDOM from 'react-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Insert custom tooltip
export function customTooltip(tooltipModel) {
  var tooltipEl = document.getElementById('chartjs-tooltip');

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    return (tooltipEl.style.opacity = 0);
  }

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    // tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // TODO: Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';

  setTimeout(() => {
    // Wait for tooltipEl to be inserted to DOM
    ReactDOM.render(<TooltipBody model={tooltipModel} />, tooltipEl);
  }, 1);
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '#chartjs-tooltip': {
      opacity: 1,
      position: 'absolute',
      background: 'rgba(0, 0, 0, .7)',
      color: 'white',
      borderRadius: 3,
      transition: 'all .1s ease',
      pointerEvents: 'none',
      transform: 'translate(-50%, 0)',
      padding: theme.spacing(0.5),
    },
  },
  title: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: theme.spacing(0.5),
  },
}));

// Tooltip's content
const TooltipBody = ({ model }) => {
  const classes = useStyles();
  const { labelColors, body: modelBody } = model;
  const bodyLines = modelBody.map(({ lines }) => lines);
  const [value, title] = bodyLines[0][0].split('____');
  // ____ is what we return specifically in charjs's options tooltips.callback.label (customTooltipContent)
  // Might need to handle multiple fields, not needed now
  var color = labelColors[0].backgroundColor;

  return (
    <div>
      <div className={classes.title}>{title}</div>
      <div className={classes.valueWrapper}>
        <div className={classes.dot} style={{ backgroundColor: color }} />
        {value}
      </div>
    </div>
  );
};

// Also take care of responsiveness, as useStyles
const useLegendStyles = makeStyles(theme => ({
  legend: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(1),
    fontSize: '.8rem',
  },

  [theme.breakpoints.down('md')]: {
    legend: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },

  legendPoint: {
    width: 8,
    height: 8,
    borderRadius: 10,
    backgroundColor: '#ddd',
    display: 'inline-block',
  },
  legendLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 56,
  },
  legendBorder: {
    height: 10,
    width: 1,
    background: '#bbb',
  },
  legendValue: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },

  legendSummary: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '.8rem',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  legendSummaryLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  legendSummaryValue: {
    color: '#666',
  },
}));

export function LegendItem({ label, value, color }) {
  const classes = useLegendStyles();
  return (
    <div className={classes.legend}>
      <div className={classes.legendPoint} style={{ backgroundColor: color }} />
      <div className={classes.legendLabel}>{label}</div>
      <div className={classes.legendBorder} />
      <div className={classes.legendValue}>{value}</div>
    </div>
  );
}

export function LegendSummary({ color, label, value }) {
  const classes = useLegendStyles();
  return (
    <div className={classes.legendSummary}>
      <div className={classes.legendPoint} style={{ backgroundColor: color }} />
      <div className={classes.legendSummaryLabel}>{label}:</div>
      <div className={classes.legendSummaryValue}>{value}</div>
    </div>
  );
}

export function customTooltipLabelContent(postfix = '', tooltipItem, data) {
  const di = tooltipItem.datasetIndex;
  const i = tooltipItem.index;
  const label = (data.labels && data.labels[i]) || data.datasets[di].label;
  return `${data.datasets[di].data[i]}${postfix}${`${label}` ? `____${label}` : ''}`;
}
