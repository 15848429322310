var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import { downloadCSV, useDataProvider, useNotify } from 'ra-core';
import jsonExport from 'jsonexport/dist';
import Button from './Button';

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var basePath = _a.basePath,
      rest = __rest(_a, ["basePath"]);

  return rest;
};
/**
 * Extracts, aggregates and deduplicates the ids of related records
 *
 * @example
 *     const books = [
 *         { id: 1, author_id: 123, title: 'Pride and Prejudice' },
 *         { id: 2, author_id: 123, title: 'Sense and Sensibility' },
 *         { id: 3, author_id: 456, title: 'War and Peace' },
 *     ];
 *     getRelatedIds(books, 'author_id'); => [123, 456]
 *
 * @example
 *     const books = [
 *         { id: 1, tag_ids: [1, 2], title: 'Pride and Prejudice' },
 *         { id: 2, tag_ids: [2, 3], title: 'Sense and Sensibility' },
 *         { id: 3, tag_ids: [4], title: 'War and Peace' },
 *     ];
 *     getRelatedIds(records, 'tag_ids'); => [1, 2, 3, 4]
 *
 * @param {Object[]} records An array of records
 * @param {string} field the identifier of the record field to use
 */


export var getRelatedIds = function getRelatedIds(records, field) {
  return Array.from(new Set(records.filter(function (record) {
    return record[field] != null;
  }).map(function (record) {
    return record[field];
  }).reduce(function (ids, value) {
    return ids.concat(value);
  }, [])));
};
/**
 * Helper function for calling the data provider with GET_MANY
 * via redux and saga, and getting a Promise in return
 *
 * @example
 *     fetchRelatedRecords(records, 'post_id', 'posts').then(posts =>
 *          posts.map(record => ({
 *              ...record,
 *              post_title: posts[record.post_id].title,
 *          }));
 */

var fetchRelatedRecords = function fetchRelatedRecords(dataProvider) {
  return function (data, field, resource) {
    return dataProvider.getMany(resource, {
      ids: getRelatedIds(data, field)
    }).then(function (_a) {
      var data = _a.data;
      return data.reduce(function (acc, post) {
        acc[post.id] = post;
        return acc;
      }, {});
    });
  };
};

var DefaultIcon = React.createElement(DownloadIcon, null);
var defaultFilter = {};

var ExportButton = function ExportButton(_a) {
  var exporter = _a.exporter,
      sort = _a.sort,
      _b = _a.filter,
      filter = _b === void 0 ? defaultFilter : _b,
      _c = _a.maxResults,
      maxResults = _c === void 0 ? 1000 : _c,
      resource = _a.resource,
      onClick = _a.onClick,
      _d = _a.label,
      label = _d === void 0 ? 'ra.action.export' : _d,
      _e = _a.icon,
      icon = _e === void 0 ? DefaultIcon : _e,
      rest = __rest(_a, ["exporter", "sort", "filter", "maxResults", "resource", "onClick", "label", "icon"]);

  var dataProvider = useDataProvider();
  var notify = useNotify();
  var handleClick = useCallback(function (event) {
    dataProvider.getList(resource, {
      sort: sort,
      filter: filter,
      pagination: {
        page: 1,
        perPage: maxResults
      }
    }).then(function (_a) {
      var data = _a.data;
      return exporter ? exporter(data, fetchRelatedRecords(dataProvider), dataProvider) : jsonExport(data, function (err, csv) {
        return downloadCSV(csv, resource);
      });
    }).catch(function (error) {
      console.error(error);
      notify('ra.notification.http_error', 'warning');
    });

    if (typeof onClick === 'function') {
      onClick(event);
    }
  }, [dataProvider, exporter, filter, maxResults, notify, onClick, resource, sort]);
  return React.createElement(Button, __assign({
    onClick: handleClick,
    label: label
  }, sanitizeRestProps(rest)), icon);
};

ExportButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filter: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  icon: PropTypes.element
};
export default ExportButton;