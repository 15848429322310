import React from 'react';
import { Create, Edit } from 'react-admin';
import { Can, ACCESS } from '@/utils/auth';
import { Page } from '@/components';
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';

const CategoryCreate = props => (
  <Can module={ACCESS.INVENTORY}>
    <Page sectionTitle="Inventory" title="Create category">
      <Create {...props}>
        <CategoryForm />
      </Create>
    </Page>
  </Can>
);

const CategoryEdit = props => (
  <Can module={ACCESS.INVENTORY}>
    <Page sectionTitle="Inventory" title="Edit category">
      <Edit {...props}>
        <CategoryForm />
      </Edit>
    </Page>
  </Can>
);

export { CategoryList, CategoryCreate, CategoryEdit };
