import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ExportButton,
  ReferenceField,
  FunctionField,
} from 'react-admin';

import useDefaultFilter from '@/utils/useDefaultFilter';
import { Can, ACCESS, useAuth } from '@/utils/auth';
import { USER, TRANSACTION, MACHINE } from '@/vars';
import { TRANSACTIONS } from '@/vars/resources';
import routes from '@/vars/routes';
import { Page } from '@/components';
import TransactionFilter from '../TransactionFilter';
import Toolbar from '@material-ui/core/Toolbar';
import {
  productType,
  employeeType,
  productDetails,
  exportCSV,
  renderTransactionStatus,
  getPriceInTransaction,
} from '@/utils/validateCSVFunctions';

const MROTransactionList = ({ staticContext, ...props }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;

  var filter = {
    ...useDefaultFilter('client'),
    mode: MACHINE.MODE.MRO,
  };
  if (!isAdmin) filter.status = TRANSACTION.STATUS.SUCCESS;

  const listProps = {
    ...props,
    basePath: routes.mroTransactions,
    resource: TRANSACTIONS,
    hasCreate: false,
    hasEdit: false,
    hasShow: false,
    hasList: true,

    filter,
    sort: { field: 'date', order: 'DESC' },
    bulkActionButtons: false,
    perPage: 25,
    filters: <TransactionFilter />,
  };

  const PostActions = ({ currentSort, displayedFilters, filters, filterValues, resource, showFilter, total }) => {
    filter.status = filterValues.status;
    return (
      <Toolbar>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <ExportButton
          disabled={total === 0}
          maxResults={5000}
          resource={TRANSACTIONS}
          sort={currentSort}
          filter={{ ...filterValues, ...filter }}
          exporter={exporter}
        />
      </Toolbar>
    );
  };

  const exporter = async posts => {
    let datas = await Promise.all(
      //Promise.all resolve when all promises are resolved
      posts.map(async post => {
        // without Promise.all, console.log(datas)= [ Promise { "pending" }, Promise { "pending" }...]
        await employeeType(post);
        await productType(post);
        await productDetails(post);

        // delete unwanted field
        delete post.machine.id;
        delete post.product.categoryId;
        delete post.product.id;
        delete post.status;
        delete post.employee.id;
        const dateAndTime = post.date; //before split "Tue Sep 29 2020 12:50:36 GMT+0800 (Malaysia Time)"
        const date = dateAndTime.toString().split(' '); //after split "Tue","Sep","29","2020","12:50:36"...each one is an array
        post.date = date[0].concat(', ', date[1], ' ', date[2], ' ', date[3]); //add date into post=combine the array
        post.time = date[4];
        const { client, id, mode, payment, error, ...data } = post;
        return data;
      })
    );

    exportCSV(datas);
  };

  return (
    <Can module={ACCESS.MRO_REPORT}>
      <Page sectionTitle="MRO Report" title="List transactions">
        <List {...listProps} actions={<PostActions />}>
          <Datagrid>
            <DateField label="Date/Time" source="date" showTime />
            {isAdmin && (
              <ReferenceField label="Company" source="client" reference="clients" link={false}>
                <TextField source="companyName" />
              </ReferenceField>
            )}
            <TextField label="Machine UUID" source="machine.machineUUID" />
            <TextField label="Employee Name" source="employee.fullName" />
            <ReferenceField label="Department" source="employee.id" reference="employees" link={false}>
              <TextField source="type.name"></TextField>
            </ReferenceField>
            <TextField label="Product name" source="product.name" />
            <TextField label="QTY" source="quantity" />
            <NumberField source="amount" label="Price(Cr)" />
            <FunctionField label="Price(RM)" render={getPriceInTransaction} />
            <FunctionField label="Status" render={renderTransactionStatus} />
          </Datagrid>
        </List>
      </Page>
    </Can>
  );
};

export default MROTransactionList;
