var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import WithPermissions from '../auth/WithPermissions';
import { registerResource, unregisterResource } from '../actions';
var defaultOptions = {};

var ResourceRegister = function ResourceRegister(_a) {
  var name = _a.name,
      list = _a.list,
      create = _a.create,
      edit = _a.edit,
      show = _a.show,
      icon = _a.icon,
      _b = _a.options,
      options = _b === void 0 ? defaultOptions : _b;
  var dispatch = useDispatch();
  useEffect(function () {
    dispatch(registerResource({
      name: name,
      options: options,
      hasList: !!list,
      hasEdit: !!edit,
      hasShow: !!show,
      hasCreate: !!create,
      icon: icon
    }));
    return function () {
      return dispatch(unregisterResource(name));
    };
  }, [dispatch, name, create, edit, icon, list, show, options]);
  return null;
};

var ResourceRoutes = function ResourceRoutes(_a) {
  var name = _a.name,
      match = _a.match,
      list = _a.list,
      create = _a.create,
      edit = _a.edit,
      show = _a.show,
      _b = _a.options,
      options = _b === void 0 ? defaultOptions : _b;
  var isRegistered = useSelector(function (state) {
    return state.admin.resources[name] ? true : false;
  });
  var basePath = match ? match.path : ''; // match tends to change even on the same route ; using memo to avoid an extra render

  return useMemo(function () {
    // if the registration hasn't finished, no need to render
    if (!isRegistered) {
      return null;
    }

    var props = {
      resource: name,
      options: options,
      hasList: !!list,
      hasEdit: !!edit,
      hasShow: !!show,
      hasCreate: !!create
    };
    return React.createElement(Switch, null, create && React.createElement(Route, {
      path: basePath + "/create",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          component: create,
          basePath: basePath
        }, routeProps, props));
      }
    }), show && React.createElement(Route, {
      path: basePath + "/:id/show",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          component: show,
          basePath: basePath,
          id: decodeURIComponent(routeProps.match.params.id)
        }, routeProps, props));
      }
    }), edit && React.createElement(Route, {
      path: basePath + "/:id",
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          component: edit,
          basePath: basePath,
          id: decodeURIComponent(routeProps.match.params.id)
        }, routeProps, props));
      }
    }), list && React.createElement(Route, {
      path: "" + basePath,
      render: function render(routeProps) {
        return React.createElement(WithPermissions, __assign({
          component: list,
          basePath: basePath
        }, routeProps, props));
      }
    }));
  }, [basePath, name, create, edit, list, show, options, isRegistered]); // eslint-disable-line react-hooks/exhaustive-deps
};

var Resource = function Resource(_a) {
  var _b = _a.intent,
      intent = _b === void 0 ? 'route' : _b,
      props = __rest(_a, ["intent"]);

  return intent === 'registration' ? React.createElement(ResourceRegister, __assign({}, props)) : React.createElement(ResourceRoutes, __assign({}, props));
};

export default Resource;