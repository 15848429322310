export const USER = {
  ROLE: {
    ADMIN: 'admin',
    CLIENT: 'client',
  },
  ACCESS: {
    VENDING_MACHINE: 'vending_machine',
    INVENTORY: 'inventory',
    REPORT: 'report',
    MRO_REPORT: 'mro_report',
    MRO: 'mro',
    USER: 'user',
  },
  STATUS: {
    ACTIVE: 'active',
    DISABLED: 'disabled',
  },
};

export const MACHINE = {
  STATUS: {
    ONLINE: 'online',
    OUT_OF_STOCK: 'out_of_stock',
    ERROR: 'error',
    OFFLINE: 'offline',
    BILL_ERROR: 'bill_error',
    COIN_ERROR: 'coin_error',
  },
  MODE: {
    COMMERCIAL: 'commercial',
    MRO: 'mro',
  },
  PAYMENT_METHOD: {
    IPAY88: 'iPay88',
    GHL: 'ghl',
    CASH_COIN: 'cashCoin',
    TERMINAL: 'terminal',
  },
};

export const IPAY88_DEFAULT = {
  merchantCode: 'M27182_S0004',
  merchantKey: 'TAJuYMbqNm',
  phoneNumber: '0333100103',
  email: 'support@vechnology.com.my',
  username: 'vechnology',
  isActive: true,
};

export const VENDING_MACHINE_MODELS = [
  'VE-E200C',
  'VE-E200N',
  'VE-E300C',
  'VE-E300N',
  'VE-E400C',
  'VE-E400N',
  'VE-E1208C',
  'VE-E1208N',
  'VE-CMC0500',
  'VE-IND0500',
  'VE-IND0400-GBS18*01',
  'E500C',
  'E500N',
  'E600'
];

export const TRANSACTION = {
  STATUS: {
    SUCCESS: 1,
    FAIL: 0,
  },
  GATEWAY: {
    '234': 'Alipay Scan',
    '305': 'WeChatPay CN Scan',
    '320': 'Boost Scan',
    '329': 'MCashMS',
    '336': 'TNG Scan',
    '338': 'UnionPay Scan',
    '347': 'GrabPay QR',
    '354': 'MaybankQR Scan',
    '379': 'GrabPay Scan',
    '164': 'PrestoPay Scan',
    '19': 'ShopeePay Scan',
  },
};

export const SLOT = {
  GRID_COL_NUM: 12,
  GRID_ROW_NUM: 6,
};

export const LockerStatus = {
  Available: 1,
  PendingDeposit: 2,
  PendingCollection: 3,
  Collecting: 4,
  AdminCheck: 5,
  Reserved: 6,
  Completed: 100,
  ExpiredBeforeDeposit: 102,
  ExpiredBeforeCollection: 103,
};

export const MachineDispenseMode = {
  vechnology: 1,
  doc2us: 2,
};
