import React from 'react';
import { Box, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { useHistory, generatePath } from 'react-router';
import routes from '@/vars/routes';

const SLOT_STATUS = {
  DISABLE: 0,
  DEFAULT: 1,
  ERROR: 2,
  REFILL: 3,
  OCCUPIED: 4,
};

const useStyles = makeStyles(theme => ({
  root: props => {
    const { status } = props;
    let backgroundColor;
    let cursor = 'pointer';
    if (status === SLOT_STATUS.DISABLE) {
      backgroundColor = 'white';
      cursor = '';
    }
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[200];
    if (status === SLOT_STATUS.OCCUPIED) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.DEFAULT) backgroundColor = colors.grey[100];

    return {
      position: 'relative',
      minWidth: '130px',
      minHeight: '130px',
      maxWidth: '130px',
      maxHeight: '130px',
      border: `1px solid #808080`,
      marginRight: -1,
      padding: 10,
      fontSize: 12,
      textAlign: 'center',
      cursor,
      backgroundColor,
    };
  },
  text: {},
  textBold: {
    fontWeight: 'bold',
    height: '70px',
    maxHeight: '70px'
  },
  quantity: {},
  statusIndicator: props => {
    const { status } = props;
    let backgroundColor = colors.green[500];
    if (status === SLOT_STATUS.DISABLE) backgroundColor = colors.grey[50];
    if (status === SLOT_STATUS.DEFAULT) backgroundColor = colors.grey[500];
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[500];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[500];

    return {
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: 10,
      top: 5,
      right: 5,
      display: 'block',
      backgroundColor,
    };
  },
}));

const SlotItemBoard = ({ slotNumber, slotData, slotInformation, machineId, boardID, disable, is12slots }) => {
  const slotID = slotInformation.id || '';
  const slotName = slotInformation.data.name || '';
  const { maxCapacity, totalQuantity, error, product } = slotData;
  const getStatus = () => {
    if (disable) return SLOT_STATUS.DISABLE;
    if (isEmpty(product) || isEmpty(slotData)) return SLOT_STATUS.DEFAULT;
    if (error) return SLOT_STATUS.ERROR;
    if (totalQuantity !== undefined && totalQuantity <= 0) return SLOT_STATUS.REFILL;
    return SLOT_STATUS.OCCUPIED;
  };
  const classes = useStyles({ status: getStatus(), is12slot: is12slots });
  const history = useHistory();

  const renderProduct = () => {
    if (isEmpty(product)) return 'Add product';
    return product.name || '';
  };

  const renderQuantity = () => {
    if (totalQuantity === undefined || isEmpty(product)) return '-';
    return maxCapacity === undefined ? totalQuantity : totalQuantity + '/' + maxCapacity;
  };

  const truncate = input => {
    if (input.length > 75) {
      return input.substring(0, 75) + '...';
    }
    return input;
  };

  if (disable) {
    return <div className={classes.root}></div>;
  } else {
    return (
      <div
        className={classes.root}
        onClick={() => {
          const path = generatePath(routes.machineSlotBoard, { machineId, boardID, slotID });
          history.push(path);
        }}
      >
        <Box>
          <Box className={classes.text}>{slotName}</Box>
          <Box className={classes.textBold} mt={1}>
            {truncate(renderProduct())}
          </Box>
          <Box className={classes.quantity} mt={1}>
            {renderQuantity()}
          </Box>
        </Box>
      </div>
    );
  }
};

export default SlotItemBoard;
