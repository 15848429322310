import PropTypes from 'prop-types';
/**
 * Common PropTypes for all react-admin inputs
 */

var InputPropTypes = {
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string
};
export default InputPropTypes;