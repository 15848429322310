import React from 'react';
import { Button, minLength } from 'react-admin';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FilterList as FilterListIcon, Close as CloseIcon } from '@material-ui/icons';
import { get } from 'lodash';

import { TextInput } from '@/ra';
import { FilterDrawer } from '@/components';

const useStyles = makeStyles(theme => ({
  form: {
    alignSelf: 'flex-end',
    paddingBottom: 8,
  },
  formContent: {
    padding: theme.spacing(3),
  },
  formContentButtons: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  closeButton: {
    marginTop: 5,
    marginLeft: 5,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'baseline',
  },
  searchInput: {
    padding: 15,
  },
  searchButton: {
    marginLeft: 5,
  },
}));

const FilterButton = props => {
  return (
    <Button label="Show filters" {...props}>
      <FilterListIcon />
    </Button>
  );
};

const FilterForm = props => {
  const {
    open,
    onClose,
    setFilters,
    filterValues,
    searchKeys,
    children,
    parseFiltersToFormData,
    parseFormDataToFilters,
  } = props;
  const classes = useStyles();

  const onSubmit = (data, form, callback) => {
    const { q, ...dataRest } = data;
    let filters = parseFormDataToFilters(dataRest);
    if (searchKeys && q && q.length >= 3) {
      filters.q = {
        value: q,
        keys: searchKeys,
      };
    }
    setFilters(filters);
    callback();
  };

  const clearFilters = () => setFilters({});

  const { q, ...filterValuesRest } = filterValues;
  const initialValues = parseFiltersToFormData(filterValuesRest);
  if (get(q, 'value')) {
    initialValues.q = q.value;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validateOnBlur>
      {({ handleSubmit }) => {
        return (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            {searchKeys && (
              <div className={classes.searchBox}>
                <TextInput
                  InputProps={{ classes: { input: classes.searchInput } }}
                  label=""
                  source="q"
                  placeholder="Search"
                  validate={minLength(3, 'Min 3 characters')}
                />
                <Button className={classes.searchButton} type="submit" onClick={handleSubmit} label="Search" />
              </div>
            )}
            {children && (
              <FilterDrawer open={open} onClose={onClose}>
                <div>
                  <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className={classes.formContent}>
                  <Typography variant="h6" gutterBottom>
                    Filter by:
                  </Typography>
                  {children}
                  <div className={classes.formContentButtons}>
                    <Button type="submit" onClick={handleSubmit} label="Apply filters" />
                    <Button label="Clear" onClick={clearFilters} />
                  </div>
                </div>
              </FilterDrawer>
            )}
          </form>
        );
      }}
    </Form>
  );
};

const Filter = props => {
  const dispatch = useDispatch();
  const openFilter = useSelector(state => state.app.openFilterDrawer);
  const toggleOpenFilter = () => dispatch({ type: 'app/TOGGLE_OPEN_FILTER_DRAWER' });

  if (props.context === 'button') {
    return props.children ? <FilterButton onClick={toggleOpenFilter} /> : null;
  } else {
    return <FilterForm {...props} open={openFilter} onClose={toggleOpenFilter} />;
  }
};

Filter.defaultProps = {
  searchKeys: [],
  parseFormDataToFilters: data => data,
  parseFiltersToFormData: filters => filters,
};

export default Filter;
