import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper, Hidden } from '@material-ui/core';

import { useAuth } from '@/utils/auth';
import Navigation from '@/components/Navigation';
import menuConfig from './menuConfig';

const Sidebar = props => {
  const { sideOpen, toggleSide, className, ...rest } = props;

  const classes = useStyles();
  const { pathname } = useLocation();
  const { permissions } = useAuth();
  const menus = menuConfig(permissions);

  useEffect(() => {
    if (sideOpen) {
      toggleSide && toggleSide();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const sidebarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {menus.map((list, index) => (
          <Navigation component="div" key={list.title || index} pages={list.pages} title={list.title} />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      {/* Mobile */}
      <Hidden lgUp>
        <Drawer anchor="left" onClose={toggleSide} open={sideOpen} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {sidebarContent}
          </div>
        </Drawer>
      </Hidden>

      {/* Desktop */}
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {sidebarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}));

export default Sidebar;
