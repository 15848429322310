import React from 'react';
import { Box } from '@material-ui/core';

const GroupField = ({ children, basePath, record = {}, resource, ...rest }) => {
  return (
    <>
      {children.map((el, index) => (
        <Box display="inline-block" mx={1} key={index}>
          {React.cloneElement(el, { basePath, record, resource })}
        </Box>
      ))}
    </>
  );
};

export default GroupField;
