import React from 'react';
import { Box, colors, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import { useHistory, generatePath } from 'react-router';
import routes from '@/vars/routes';

const SLOT_STATUS = {
  DISABLE: 0,
  NOTINITIALIZED: 1,
  ERROR: 2,
  REFILL: 3,
  OCCUPIED: 4,
  EMPTY: 5,
};

const useStyles = makeStyles(theme => ({
  root: props => {
    const { status } = props;
    let backgroundColor;
    let cursor = 'pointer';
    if (status === SLOT_STATUS.DISABLE) {
      backgroundColor = 'white';
      cursor = '';
    }
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[200];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[200];
    if (status === SLOT_STATUS.OCCUPIED) backgroundColor = colors.green['A100'];
    if (status === SLOT_STATUS.NOTINITIALIZED) backgroundColor = colors.grey[100];
    if (status === SLOT_STATUS.EMPTY) backgroundColor = colors.blue[200];

    return {
      position: 'relative',
      minWidth: '110px',
      minHeight: '110px',
      border: `1px solid #808080`,
      marginRight: -1,
      padding: 10,
      fontSize: 12,
      textAlign: 'center',
      cursor,
      backgroundColor,
    };
  },
  text: {},
  textBold: {
    fontWeight: 'bold',
  },
  quantity: {},
  statusIndicator: props => {
    const { status } = props;
    let backgroundColor = colors.green[500];
    if (status === SLOT_STATUS.DISABLE) backgroundColor = colors.grey[50];
    if (status === SLOT_STATUS.NOTINITIALIZED) backgroundColor = colors.grey[500];
    if (status === SLOT_STATUS.ERROR) backgroundColor = colors.red[500];
    if (status === SLOT_STATUS.REFILL) backgroundColor = colors.orange[500];

    return {
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: 10,
      top: 5,
      right: 5,
      display: 'block',
      backgroundColor,
    };
  },
}));

const LockerItem = ({ slotData, slotInformation, machineId, boardID, disable, is12slots }) => {
  const slotID = slotInformation.id || '';
  const slotName = slotInformation.data.name || '';
  const { maxCapacity, totalQuantity, error, product } = slotData;
  const getStatus = () => {
    if (disable) return SLOT_STATUS.DISABLE;
    if (isEmpty(product)) return SLOT_STATUS.NOTINITIALIZED;
    if (error) return SLOT_STATUS.ERROR;
    if (totalQuantity !== undefined && totalQuantity <= 0) return SLOT_STATUS.REFILL;
    return SLOT_STATUS.OCCUPIED;
  };

  const classes = useStyles({ status: getStatus(), is12slot: is12slots });
  const history = useHistory();

  const renderProduct = () => {
    if (isEmpty(product)) return 'Add product';
    return product.name || '';
  };

  const truncate = input => {
    if (input.length > 13) {
      return input.substring(0, 13) + '...';
    }
    return input;
  };

  if (disable) {
    return <div className={classes.root}></div>;
  }

  return (
    <div
      className={classes.root}
      onClick={() => {
        const path = generatePath(routes.lockerSlot, { machineId, boardID, slotID });
        history.push(path);
      }}
    >
      <Box>
        <Box className={classes.text}>{slotName}</Box>
        <Box className={classes.textBold} mt={1}>
          {truncate(renderProduct())}
        </Box>
      </Box>
    </div>
  );
};

export default LockerItem;
