import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { Button } from '@/components';
import { makeStyles } from '@material-ui/styles';
import { useSetAdminCodeOnMultipleLockerDoc2Us } from '@/pages/machines/hooks';

const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: 450,
    },
  },
  unlockButton: {
    minWidth: 150,
    minHeight: 45,
    display: 'inline-block',
    border: '1px solid',
    borderRadius: 4,
    marginRight: 7,
    marginBottom: 2,
    padding: 6,
    textAlign: 'center',
    fontSize: 14,
    backgroundColor: 'white',
  },
  errorMessage: {
    color: 'red',
  },
  hideErrorMessage: {
    display: 'none',
  },
}));

const AdminCodeDialog = ({ machineID, listOfExpiredItems }) => {
  const classes = useStyles();
  const { save } = useSetAdminCodeOnMultipleLockerDoc2Us();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkAdminCodeLength = adminCode => {
    if (adminCode.length < 6) {
      document.getElementById('lessThan6LengthMsg').className = classes.errorMessage;
      return false;
    }

    document.getElementById('lessThan6LengthMsg').className = classes.hideErrorMessage;

    return true;
  };

  const setAdminCode = async () => {
    const adminCodeObject = { adminCode: document.getElementById('adminCode').value };
    if (checkAdminCodeLength(document.getElementById('adminCode').value)) {
      await save(machineID, listOfExpiredItems.expiredIDArray, adminCodeObject);
      handleClose();
    }
  };

  return (
    <div>
      <Button className={classes.unlockButton} variant="outlined" onClick={handleClickOpen}>
        Set Admin Code
      </Button>
      <Dialog className={classes.container} open={open} onClose={handleClose}>
        <DialogTitle>Set Admin Code</DialogTitle>
        <DialogContent>
          <DialogContentText>Set an admin code to all expired lockers.</DialogContentText>
          <DialogContentText>
            There are currently {listOfExpiredItems.expiredIDArray.length} expired locker(s).
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="adminCode"
            label="Admin Code"
            type="text"
            fullWidth
            variant="standard"
          />
          <DialogContentText id="lessThan6LengthMsg" className={classes.hideErrorMessage}>
            *Admin code must be of length 6 or more.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={setAdminCode}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminCodeDialog;
