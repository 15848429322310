import React from 'react';
import { ReferenceInput, required, SelectInput } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { CardActions, CardContent, CardHeader, Grid, Divider } from '@material-ui/core';

import { USER } from '@/vars';
import { useAuth } from '@/utils/auth';
import { Button } from '@/components';
import { TextInput } from '@/components/input';

const CategoryForm = ({ save, saving, record, redirect, ...rest }) => {
  const { permissions } = useAuth();
  const isAdmin = permissions.role === USER.ROLE.ADMIN;
  const onSubmit = data => save(data, redirect);

  return (
    <Form onSubmit={onSubmit} initialValues={record} keepDirtyOnReinitialize validateOnBlur>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CardHeader title="Category details" />
          <CardContent style={{ maxWidth: 500 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput label="Name" name="name" validate={required('Please enter a name.')} />
              </Grid>
              {isAdmin ? (
                <Grid item xs={12}>
                  <ReferenceInput source="client" reference="clients" filterToQuery={() => {}}>
                    <SelectInput
                      optionText="companyName"
                      variant="outlined"
                      fullWidth
                      margin="none"
                      perPage={99999}
                      validate={required('Please select a client.')}
                    />
                  </ReferenceInput>
                </Grid>
              ) : (
                <Field component="input" type="hidden" name="client" initialValue={permissions.client_id} />
              )}
            </Grid>
          </CardContent>

          <Divider />
          <CardActions>
            <Button type="submit" variant="contained" color="primary" loading={saving}>
              Save
            </Button>
          </CardActions>
        </form>
      )}
    </Form>
  );
};

export default CategoryForm;
