import React from 'react';
import { useTranslate, ValidationError } from 'ra-core';

var InputHelperText = function InputHelperText(_a) {
  var helperText = _a.helperText,
      touched = _a.touched,
      error = _a.error;
  var translate = useTranslate();
  return touched && error ? React.createElement(ValidationError, {
    error: error
  }) : helperText ? React.createElement(React.Fragment, null, translate(helperText, {
    _: helperText
  })) : null;
};

export default InputHelperText;