import React from 'react';
import { Box, colors } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { Label } from '@/components';

const SlotStatusField = ({ record, variant = 'contained' }) => {
  if (isEmpty(record)) return null;

  const statusColor = {
    ERROR: colors.red[500],
    REFILL: colors.orange[500],
  };

  const { totalQuantity, error } = record;
  const status = [];
  if (totalQuantity !== undefined && totalQuantity <= 0) {
    status.push('REFILL');
  }
  if (error) {
    status.push('ERROR');
  }

  return (
    <>
      {status.map((item, index) => (
        <Box display="inline-block" mx={0.5} key={index}>
          <Label color={statusColor[item]} variant={variant}>
            {item}
          </Label>
        </Box>
      ))}
    </>
  );
};

export default SlotStatusField;
