import { useGetSlotsRealtime } from '@/pages/machines/hooks';
import SlotGridBoard from '@/pages/machines/MachineEdit/SlotGridBoard';
import LockerGrid from '@/pages/machines/MachineEdit/SlotGridLocker';
import { colors, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { LoadingIndicator } from 'react-admin';
import { MachineDispenseMode } from '@/vars';
import Doc2UsLockerGrid from '@/pages/machines/LockerDoc2Us/doc2usLockerGrid';

const useStyles = makeStyles(theme => ({
  boardTabs: {
    marginTop: theme.spacing(3),

    '& .Mui-selected': {
      backgroundColor: 'white',
    },

    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
    border: '1px solid',
    borderRadius: 30,
  },
  spacingTop: {
    marginTop: theme.spacing(1),
    backgroundColor: colors.grey[300],
  },
}));

const BoardTabs = ({ machineId, state, machineModel, boardData, clientType }) => {
  const [tab, setTab] = React.useState(null);
  const classes = useStyles();
  const getSlotsState = useGetSlotsRealtime(machineId);
  const handleTabsChange = (event, value) => setTab(value);
  const boardButtons = [];

  useEffect(() => {
    if (boardData.loaded === true) {
      if (boardData.boards.length > 0) {
        setTab(boardData.boards[0].type === 'spiral' ? 'slot1' : 'locker1');
      }
    }
  }, [boardData]);

  const InitializeTabContainer = tab => {
    if (tab === null) {
      return <LoadingIndicator colors="#00000" />;
    }

    return (
      <Tabs
        value={tab}
        onChange={handleTabsChange}
        indicatorColor="secondary"
        className={classes.boardTabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {InitializeTabs(boardData.boards)}
      </Tabs>
    );
  };

  const InitializeTabs = boardIDList => {
    let lockerIndex = 0;
    let slotIndex = 0;

    if (boardIDList === null) {
      return null;
    }

    boardIDList.map(board => {
      if (board.type === 'spiral') {
        slotIndex++;
        boardButtons.push({
          value: `slot${slotIndex}`,
          label: board.name,
          type: board.type,
          boardID: board.id,
          boardData: board,
        });
      } else {
        lockerIndex++;
        boardButtons.push({
          value: `locker${lockerIndex}`,
          label: board.name,
          type: board.type,
          boardID: board.id,
          boardData: board,
        });
      }
    });

    return boardButtons.map(board => {
      return <Tab key={board.value} label={board.label} value={board.value} className={classes.buttons} />;
    });
  };

  const InitializeContents = masterSchemaData => {
    if (masterSchemaData === null) {
      return null;
    }

    return boardButtons.map(board => {
      if (board.boardData.type === 'spiral') {
        return (
          tab === board.value && (
            <SlotGridBoard
              key={board.value}
              machineId={machineId}
              machineModel={machineModel}
              boardData={board.boardData}
            />
          )
        );
      } else {
        if (MachineDispenseMode.doc2us === clientType) {
          return (
            tab === board.value && (
              <Doc2UsLockerGrid
                key={board.value}
                machineId={machineId}
                state={getSlotsState}
                machineModel={machineModel}
                boardData={board.boardData}
              />
            )
          );
        }
        return (
          tab === board.value && (
            <LockerGrid
              key={board.value}
              machineId={machineId}
              state={getSlotsState}
              machineModel={machineModel}
              boardData={board.boardData}
            />
          )
        );
      }
    });
  };

  return (
    <div>
      {InitializeTabContainer(tab)}
      <div className={classes.content}>{InitializeContents(boardData.boards)}</div>
    </div>
  );
};

export default BoardTabs;
