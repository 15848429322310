import _defineProperty from "/Users/admin/Documents/GitHub/ve-cloud/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import firebase from 'firebase/app';
import FirestoreClient from './FirestoreClient';
var defaultConfig = {
  timestampFieldNames: {
    createdAt: 'createdAt',
    updatedAt: 'updatedAt'
  }
};
export default (function () {
  var firebaseConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var client = new FirestoreClient(_objectSpread({}, defaultConfig, {}, options));

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  return {
    getList: client.getList,
    getOne: client.getOne,
    getMany: client.getMany,
    getManyReference: client.getManyReference,
    update: client.update,
    updateMany: client.updateMany,
    create: client.create,
    delete: client.delete,
    deleteMany: client.deleteMany
  };
});