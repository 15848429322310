import { convertLegacyDataProvider } from 'react-admin';
import { FirestoreDataProvider } from 'ra-data-firestore';
import { firebaseConfig, db } from '@/vars/firebase';
import { USERS, EMPLOYEE_TYPES, PRODUCTS, MRO_REPORT_PRODUCTS, MRO_REPORT_EMPLOYEES } from '@/vars/resources';
import users from './Users';
import mroReportProducts from './MROReportProducts';
import mroReportEmployees from './MROReportEmployees';

const debug = true;
const log = (...args) => {
  if (process.env.NODE_ENV === 'development' && debug) console.log(...args);
};

const firestoreDataProvider = FirestoreDataProvider(firebaseConfig, {
  redundantUpdate: {
    [PRODUCTS]: {
      query: id => db.collectionGroup('slots').where('product.id', '==', id),
      source: ['name', 'description', 'priceCash', 'image.src'],
      target: 'product',
    },
    [EMPLOYEE_TYPES]: {
      query: id => db.collection('employees').where('type.id', '==', id),
      source: ['name'],
      target: 'type',
    },
  },
});

const _users = convertLegacyDataProvider(users);

const dataProvider = new Proxy(firestoreDataProvider, {
  get(target, name) {
    return (resource, params) => {
      log(name + ': ', { resource, params });
      if (resource === USERS && ['getOne', 'create', 'update', 'delete', 'deleteMany'].includes(name)) {
        return _users[name](resource, params);
      }

      if (resource === MRO_REPORT_PRODUCTS) {
        return mroReportProducts[name](resource, params);
      }

      if (resource === MRO_REPORT_EMPLOYEES) {
        return mroReportEmployees[name](resource, params);
      }

      return target[name](resource, params);
    };
  },
});

export default dataProvider;
