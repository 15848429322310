import React from 'react';
import { Create, Edit } from 'react-admin';
import { Can, ACCESS } from '@/utils/auth';
import { Page } from '@/components';
import EmployeeTypeList from './EmployeeTypeList';
import EmployeeTypeForm from './EmployeeTypeForm';

const EmployeeTypeCreate = props => (
  <Can module={ACCESS.MRO}>
    <Page sectionTitle="MRO" title="Create type">
      <Create {...props} redirect="list">
        <EmployeeTypeForm />
      </Create>
    </Page>
  </Can>
);

const EmployeeTypeEdit = props => (
  <Can module={ACCESS.MRO}>
    <Page sectionTitle="MRO" title="Edit type">
      <Edit {...props} redirect="list">
        <EmployeeTypeForm />
      </Edit>
    </Page>
  </Can>
);

export { EmployeeTypeList, EmployeeTypeCreate, EmployeeTypeEdit };
