import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

function ContentWrapper({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export default ContentWrapper;
