var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { FieldTitle, useInput } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
import InputHelperText from './InputHelperText';
import InputPropTypes from './InputPropTypes';

var BooleanInput = function BooleanInput(_a) {
  var format = _a.format,
      label = _a.label,
      fullWidth = _a.fullWidth,
      helperText = _a.helperText,
      onBlur = _a.onBlur,
      onChange = _a.onChange,
      onFocus = _a.onFocus,
      options = _a.options,
      parse = _a.parse,
      resource = _a.resource,
      source = _a.source,
      validate = _a.validate,
      rest = __rest(_a, ["format", "label", "fullWidth", "helperText", "onBlur", "onChange", "onFocus", "options", "parse", "resource", "source", "validate"]);

  var _b = useInput(__assign({
    format: format,
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    parse: parse,
    resource: resource,
    source: source,
    type: 'checkbox',
    validate: validate
  }, rest)),
      id = _b.id,
      _c = _b.input,
      finalFormOnChange = _c.onChange,
      type = _c.type,
      value = _c.value,
      inputProps = __rest(_c, ["onChange", "type", "value"]),
      isRequired = _b.isRequired,
      _d = _b.meta,
      error = _d.error,
      touched = _d.touched;

  var handleChange = useCallback(function (event, value) {
    finalFormOnChange(value);
  }, [finalFormOnChange]);
  return React.createElement(FormGroup, __assign({}, sanitizeRestProps(rest)), React.createElement(FormControlLabel, {
    control: React.createElement(Switch, __assign({
      id: id,
      color: "primary",
      checked: !!value,
      onChange: handleChange
    }, inputProps, options)),
    label: React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    })
  }), touched && error || helperText ? React.createElement(FormHelperText, {
    error: !!error
  }, React.createElement(InputHelperText, {
    touched: touched,
    error: error,
    helperText: helperText
  })) : null);
};

BooleanInput.propTypes = __assign(__assign({}, InputPropTypes), {
  options: PropTypes.shape(Switch.propTypes)
});
BooleanInput.defaultProps = {
  options: {}
};
export default BooleanInput;