import { combineReducers } from 'redux';
import resources, { getResources as resourceGetResources, getReferenceResource as resourceGetReferenceResource } from './resource';
import loading from './loading';
import notifications from './notifications';
import references, { getPossibleReferenceValues as referencesGetPossibleReferenceValues } from './references';
import ui from './ui';
import customQueries from './customQueries';
export default combineReducers({
  resources: resources,
  customQueries: customQueries,
  loading: loading,
  notifications: notifications,
  references: references,
  ui: ui
});
export var getPossibleReferenceValues = function getPossibleReferenceValues(state, props) {
  return referencesGetPossibleReferenceValues(state.references, props);
};
export var getResources = function getResources(state) {
  return resourceGetResources(state.resources);
};
export var getReferenceResource = function getReferenceResource(state, props) {
  return resourceGetReferenceResource(state.resources, props);
};
export { getPossibleReferences } from './references';