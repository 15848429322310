import React from 'react';
import { CircularProgress, Button as MuiButton } from '@material-ui/core';

const Button = ({ loading, children, label, disabled, ...props }) => (
  <MuiButton {...props} disabled={loading || disabled}>
    {label || children}
    {loading && <CircularProgress style={{ marginLeft: '1em' }} size={20} />}
  </MuiButton>
);

export default Button;
