import React from 'react';
import get from 'lodash/get';
import { Label } from '@/components';
import { field as sanitizeRestProps } from './sanitizeRestProps';

const LabelField = ({ className, source, record = {}, color, ...rest }) => {
  const value = get(record, source);
  if (typeof color === 'function') {
    color = color(record);
  }

  return (
    <Label className={className} color={color} {...sanitizeRestProps(rest)}>
      {value && typeof value !== 'string' ? JSON.stringify(value) : value}
    </Label>
  );
};

export default LabelField;
